import React from "react";
import Accordion from "react-bootstrap/Accordion";

/*eslint-disable */

declare const window: any;
const AccordionComponent: React.FC = () => {
  return (
    <div className="accordion-politicas-component">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>1. Propiedad intelectual</Accordion.Header>
          <Accordion.Body>
            El sitio pertenece y es operado por Hyundai Colombia SAS (en
            adelante la "Sociedad"), perteneciente a la representación en
            Colombia de la marca Hyundai. La disposición y cada uno de los
            componentes, incluyendo marcas registradas, logotipos y nombres de
            dominio, que aparecen en el sitio de la Sociedad
            www.hyundaicolombia.co (el "Sitio"), están protegidos por la
            legislación vigente en materia de propiedad intelectual, y
            pertenecen a la casa matriz HYUNDAI o sus subsidiarias, o su uso ha
            sido objeto de una autorización por parte de sus titulares. Ningún
            componente del Sitio puede ser copiado, reproducido, modificado,
            editado, descargado, desnaturalizados, transmitido o distribuido en
            forma alguna, por cualquier medio, en todo o en parte, sin el
            consentimiento previo por escrito de la Sociedad. Sólo copiar para
            uso privado está autorizado siempre que su finalidad no sea
            comercial y se realice en su computador personal. La siguiente
            declaración debe aparecer en cualquier copia autorizada de la
            totalidad o parte de los contenidos del Sitio: "COPYRIGHT 2016 -
            Hyundai - Hyundai Colombia SAS - TODOS LOS DERECHOS RESERVADOS. "
            Cualquier uso autorizado de los elementos que componen o se muestran
            en el Sitio no debe ser desnaturalizado, cambiado o alterado de
            ninguna manera. HYUNDAI o sus filiales se reservan el derecho de
            emprender acciones legales contra cualquier violación de sus
            derechos de propiedad intelectual.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>2. Naturaleza de la información</Accordion.Header>
          <Accordion.Body>
            El Sitio puede ofrecer opiniones de los expertos consultados en un
            campo en particular en relación con el contenido del Sitio o de
            extractos de artículos de prensa o científicos. Dicha información
            únicamente representa la opinión de sus autores y de ellas son
            únicamente responsables los mismos, y no corresponde necesariamente
            a la opinión de la Sociedad. Tales expertos no son empleados de la
            Sociedad y en principio no reciben ninguna retribución por parte de
            la Sociedad para utilizar sus opiniones. La Sociedad no es
            responsable de la exactitud o integridad de dicha información y
            opiniones. Las opiniones de los expertos reflejan sus propias
            opiniones personales y no deben ser interpretadas como reflejo de la
            opinión de la Sociedad por lo que estas últimas no asumen ningún
            tipo de responsabilidad frente a las mismas.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>3. Enlaces a otros sitios</Accordion.Header>
          <Accordion.Body>
            La responsabilidad de la Sociedad no se originará por un sitio web
            de terceros al que se pueda acceder a través del Sitio. No tenemos
            ninguna manera de controlar el contenido de dichos sitios de
            terceros que siguen siendo totalmente independientes de la Sociedad.
            Por otra parte, la existencia de un vínculo entre el Sitio y el
            sitio de un tercero no significa en ningún caso que la Sociedad
            aprueba el contenido de este sitio de ninguna manera y en particular
            el uso que pueda hacerse del mismo. Además, usted es responsable de
            tomar las precauciones necesarias para evitar cualquier virus desde
            el Sitio, en particular por uno o más virus informáticos, troyanos o
            cualquier otro "virus". Los sitios externos pueden incluir enlaces
            de hipertexto hacia el Sitio. Tales enlaces no deben ser creados sin
            el consentimiento previo y expreso de la Sociedad. En todo caso, la
            Sociedad no se hace en ningún caso responsable de la falta de
            disponibilidad de dichos sitios y la Sociedad no investiga, chequea
            o aprueba ni es responsable por el contenido, publicidad, productos
            u otros componentes disponibles en o a través de dichos sitios.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            4. Autorización de Tratamiento de Datos Personales
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Declaro que he sido informado por Hyundai Colombia SAS actuando en
              representación de las fábricas, filiales y casa matriz
              relacionadas con sus marcas y productos (en adelante las
              Compañías) que será el Responsable del Tratamiento de mis Datos
              Personales, por lo cual autorizo de manera previa, libre,
              voluntaria, informada inequívoca y expresa a Hyundai Colombia SAS
              o quien represente sus derechos, también a los concesionarios de
              su red en Colombia a tratar, recolectar, almacenar, usar,
              circular, suprimir, procesar, transferir y/o trasmitir mis datos
              personales conforme a la política de tratamiento de datos
              Personales descritas en el siguiente link:{" "}
              <a
                href="https://www.hyundaicolombia.co/legales/politicas-privacidad-de-datos.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.hyundaicolombia.co/legales/politicas-privacidad-de-datos.pdf
              </a>
            </p>
            <p className="legal-texto mt-3 ">
              Con esta aceptación, autorizo contactarme por cualquier medio para
              el envío de publicidad y de información relacionada con cualquiera
              de las marcas del sector automotriz, invitación a eventos, envío
              de información de productos, servicios, postventa, descuentos,
              relacionados con vehículos automotores y en general con el sector
              automotriz, así como para todo lo relacionado con comunidades
              creadas alrededor de las marcas , para todo lo anterior podrán
              contactarme por cualquier medio (entre otros SMS, WhatsApp, mail).
              Mis datos personales podrán ser transferidos o transmitidos a
              terceros tanto a nivel nacional como internacional.
            </p>
            <p className="legal-texto mt-3 ">
              Igualmente, queda autorizada la grabación de imágenes o cualquier
              otro registro que sirvan de soporte y evidencia de los eventos
              realizados.
            </p>
            <p className="legal-texto mt-3 ">
              Como titular de la información soy consciente que tengo el derecho
              a conocer, actualizar y rectificar mis datos personales, ser
              informado sobre el uso que se ha dado a los mismos, presentar
              quejas ante la SIC por infracción a la ley, solicitar prueba de la
              autorización otorgada, revocar la autorización y/o solicitar la
              supresión de mis datos cuando sea procedente y acceder en forma
              gratuita a los mismos por medio de una solicitud dirigida a:{" "}
              <a
                href="mailto:habeasdata.co@astara.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                habeasdata.co@astara.com
              </a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            5. Limitaciones de responsabilidad
          </Accordion.Header>
          <Accordion.Body>
            La Sociedad se esfuerza al máximo de su capacidad para garantizar
            que la información publicada en el Sitio es correcta y está
            actualizada. Se reserva el derecho de corregir el contenido en
            cualquier momento, sin previo aviso, al igual que estos Términos y
            Condiciones. Sin embargo, la Sociedad no puede garantizar que la
            información disponible en el Sitio es exacta, correcta, actualizada
            o completa. En consecuencia, y con excepción de daños a la propiedad
            como resultado de negligencia grave o de una conducta
            malintencionada (dolo) por parte de la Sociedad, ésta última no será
            responsable por: 1 cualquier inexactitud, error u omisión en cuanto
            a la información disponible en el Sitio; 2 cualquier daño resultado
            de una intrusión fraudulenta de un tercero que resulta en una
            alteración de la información o de los elementos proporcionados en el
            Sitio; 3 de modo más general, por cualquier daño, incluso si la
            Sociedad había sido advertida de la posibilidad de tal daño o
            pérdida, si ello resultó de (i) acceso o imposibilidad de acceder al
            Sitio, (ii) el uso del Sitio, incluyendo cualquier daño o virus que
            pueda infectar su computador o cualquier otro equipo, y/o, (iii) la
            credibilidad dada a cualquier información procedente directa o
            indirectamente del Sitio. Los componentes del Sitio o de cualquier
            otro sitio se proporcionan "tal cual" sin ninguna garantía de ningún
            tipo, ya sea implícita o explícita. La Sociedad no ofrece ninguna
            garantía, implícita o explícita, en relación, entre otros, con su
            valor de mercado o idoneidad para un fin determinado. La Sociedad
            únicamente será responsable en los términos establecidos por la
            ley.∫
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>6. Disponibilidad del sitio web</Accordion.Header>
          <Accordion.Body>
            Usted reconoce que (i) es técnicamente imposible proporcionar el
            Sitio libre de cualquier defecto y que la Sociedad no puede
            comprometerse a hacerlo, (ii) que hay fallas que pueden conducir al
            Sitio a estar temporalmente fuera de servicio, y que (iii) el
            funcionamiento del Sitio puede verse afectado por acontecimientos
            y/o asuntos que la Sociedad no controla, como por ejemplo,
            transmisión y enlaces de comunicación entre usted y la Sociedad y de
            la Sociedad con otras redes. La Sociedad y/o sus proveedores pueden,
            en cualquier momento, modificar o interrumpir, temporal o
            permanentemente, la totalidad o parte del Sitio para someterlo a
            mantenimiento y/o mejoras y/o cambios en el Sitio. La Sociedad no se
            hace responsable de cualquier modificación, suspensión o
            interrupción del Sitio.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            7. Información sobre productos y servicios
          </Accordion.Header>
          <Accordion.Body>
            La información incluida y publicado en el Sitio puede incluir
            referencias directas o indirectas a productos, programas y servicios
            de la Sociedad que no están anunciados o disponibles en algunos
            países o algunas regiones o que se pueden suministrar con un nombre
            diferente y puede estar sujeto a las regulaciones y condiciones de
            uso que se diferencian en función del país. Dichas referencias no
            implican que la Sociedad tiene la intención de vender esos
            productos, programas o servicios en Colombia. Consulte a la Sociedad
            sobre cualquier información sobre los productos, programas y
            servicios disponibles para usted.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>8. Provisiones Legales</Accordion.Header>
          <Accordion.Body>
            El Sitio y su contenido se rigen por las leyes de la República de
            Colombia, y cualquier disputa relacionada está sujeta a la
            jurisdicción de los tribunales de la República de Colombia.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>9. Créditos de Fotos/Videos</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Banco de imágenes compradas o gratuitas a cargo de Hyundai
              Colombia S.A.S.
            </p>
            <p className="legal-texto">
              Las imágenes son ilustrativas, algunas de las características de
              los vehículos de la imagen pueden variar con respecto a las
              versiones comercializadas en Colombia y pueden generar un costo
              adicional.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>10. Iconos de seguridad vial</Accordion.Header>
          <Accordion.Body>
            <div>
              <img src="../assets/politicas/legal-accent.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-ioniq.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-creta.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-tucson.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-tucson-coreana.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-santafe.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-veloster.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-taxis.webp" alt="" />
            </div>
            <div>
              <img src="../assets/politicas/legal-camiones.webp" alt="" />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>11. Plan Siempre Nuevo</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Las imágenes son de referencia, los equipamientos, accesorios y/o
              colores hacen parte de la ambientación fotográfica o pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. *Cuotas más bajas: El plan de financiación puede ser
              otorgado por un valor de hasta el 30% del valor total del vehículo
              con una cuota inicial desde el 20% del valor total del vehículo y
              una cuota residual de capital hasta por el 50% del valor total del
              vehículo. Plazo máximo de financiación: hasta 24 meses.
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto a
              cumplimiento de las políticas de crédito y cumplimiento de las
              entidades financieras aliadas de Santander Financing S.A.S., y el
              perfil crediticio del solicitante. El Cliente pagará 24 cuotas
              mensuales compuestas por capital, intereses, seguro de vida,
              seguro todo riesgo u otros cargos siempre que los seguros sean
              adquiridos y financiados a través de Santander Financing S.A.S., o
              con alguna de las entidades financieras aliadas. Al final del
              periodo se causará una cuota residual hasta por el 50% del valor
              total del vehículo. Previo al desembolso, los vehículos deberán
              contar con seguro de auto todo riesgo y el deudor deberá tener
              contratado el amparo de seguro vida hasta el valor del desembolso,
              los cuales deberán permanecer vigentes durante la vigencia del
              crédito.
            </p>
            <p className="legal-texto">
              Válido desde el 1 de Abril de 2023, hasta el 30 de abril de 2023,
              obteniendo financiación para compra de vehículo en cualquier
              concesionario de la red autorizada Hyundai Colombia S.A.S a nivel
              nacional. Santander Consumer es la marca usada por el Grupo
              Santander para los productos de retail. En virtud de ello,
              Santander Financing S.A.S., como entidad del Grupo Santander está
              autorizada para hacer uso de la misma. Ni Hyundai Colombia ni sus
              afiliadas en Colombia son compañías autorizadas para realizar
              actividades de financiación, por lo que estas actividades no serán
              responsabilidad de Hyundai .**Estrenando cada dos años: si al
              terminar el período de financiación de 24 meses, el cliente opta
              por comprar un vehículo nuevo de la marca Hyundai, mediante un
              proceso de financiación nuevo; el valor de la retoma del vehículo
              antiguo será tenido en cuenta como valor de cuota inicial para el
              vehículo nuevo. Consultar condiciones directamente con Santander
              Financing S.A.S., *** La expresión garantía incluida durante todo
              el tiempo de uso hace referencia a que la garantía será válida
              siempre y cuando el cliente renueve el vehículo cada dos años. La
              enajenación o entrega de los vehículos por parte de los
              concesionarios a los clientes estará cubierta por las garantías
              que resulten aplicables, de conformidad con la legislación
              colombiana. No acumulable con otros descuentos o promociones
              vigentes.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11">
          <Accordion.Header>12. Vehículos Eléctricos</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              La información proporcionada sobre la autonomía, las emisiones de
              CO2 y el consumo de combustible de los vehículos enchufables se
              basa en estimaciones y cálculos realizados por una entidad
              independiente, siguiendo los procedimientos del WLTP*, en
              condiciones de prueba, que no se replican siempre en la conducción
              real.
            </p>
            <p className="legal-texto">
              La autonomía y el rendimiento alcanzados en condiciones reales
              varían en función de diversos factores, como los siguientes:
            </p>
            <p className="legal-texto">
              <ul>
                <li>
                  Comportamiento al volante (incluyendo velocidad de
                  desplazamiento, ajustes del climatizador, tipo y presión de
                  las llantas, y preacondicionamiento del vehículo),
                </li>
                <li>
                  Factores externos (incluyendo la topografía del terreno, la
                  situación de tráfico, la temperatura y el tiempo atmosférico,
                  entre otros).
                </li>
              </ul>
            </p>
            <p className="legal-texto">
              Las variaciones en la autonomía de los vehículos enchufables
              dependen de los factores anunciados y no se asocian ni deben ser
              interpretadas como una señal de inadecuado funcionamiento del
              vehículo. Las estimaciones sobre consumo de energía y autonomía
              eléctrica se incluyen a manera de referencia. La batería tendrá un
              desgaste natural, por lo que la autonomía irá variando
              (disminuyendo) con el transcurso de los años.
            </p>
            <p className="legal-texto">
              Los tiempos de carga también pueden variar según factores como la
              temperatura exterior, la temperatura de la batería, el equipo de
              carga, el estado de la batería, el estado del coche, entre otros.
            </p>
            <p className="legal-texto">
              *WLTP: Worldwide Harmonized Light Vehicles Test Procedure o
              Procedimiento Mundial Armonizado para Ensayos de Vehículos
              Ligeros.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="12">
          <Accordion.Header>13. Campañas Lifemiles</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Términos y Condiciones de acumulación Millas Hyundai
              </span>
            </p>
            <p className="legal-texto">
              <ul>
                <li>
                  Periodo de promoción: del 1 al 30 de septiembre del 2023.
                </li>
                <li>Promoción aplica únicamente para Colombia.</li>
                <li>Comercio Participante: Hyundai Colombia S.A.S</li>
                <li>
                  Sucursales participantes: Red de concesionarios Hyundai a
                  Nivel Nacional detalladas a continuación:
                </li>
              </ul>
            </p>
            <div>
              <img src="../assets/politicas/agencias.webp" alt="" />
            </div>
            <p className="legal-texto">
              <ul>
                <li>
                  Hyundai Colombia S.A.S se reserva el derecho a modificar esta
                  promoción en cualquier momento sin previo aviso.
                </li>
                <li>
                  Por compras de una Hyundai Kona Híbrida, Hyundai Kona
                  Eléctrica y Hyundai Tucson realizada en las Sucursales
                  Participantes durante el período de la promoción, los socios
                  podrán recibir un bono de 5000 millas LifeMiles.
                </li>
                <li>
                  Aplica para todas las referencias de Hyundai KONA híbrida,
                  Hyundai Kona Híbrida, Hyundai Kona Eléctrica y Hyundai Tucson.
                </li>
                <li>
                  Los Bonos de Millas se acreditarán en un máximo de 30 días
                  después de realizada la compra del vehículo.
                </li>
                <li>
                  Esta promoción es desarrollada por Hyundai Colombia S.A.S.,
                  quién es el único responsable de acreditar las Millas
                  LifeMiles en las cuentas de los usuarios. LifeMiles no se hace
                  responsable por Millas no acreditadas por parte de Hyundai
                  Colombia S.A.S.. En caso de reclamo o controversia en relación
                  con la presente promoción, el usuario deberá comunicarse
                  directamente con Hyundai Colombia S.A.S.
                </li>
                <li>
                  Una vez aplicadas las millas LifeMiles a la cuenta de los
                  Socios, las mismas estarán sujetas a todos los términos y
                  condiciones del Programa LifeMiles, incluyendo la política de
                  expiración de las mismas, los cuales pueden ser consultados en
                  el siguiente enlace:{" "}
                  <a href="https://www.lifemiles.com/">www.lifemiles.com.</a>
                </li>
                <li>LifeMiles es una marca registrada de LifeMiles Ltd.</li>
              </ul>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Terms and conditions of Acruel Hyundai Miles
              </span>
            </p>
            <p className="legal-texto">
              <ul>
                <li>
                  Periodo de promoción: del 1 al 30 de septiembre del 2023.
                </li>
                <li>Promotion period: from September 1st to 30th, 2023.</li>
                <li>Promotion applies only to Colombia.</li>
                <li>Commercial Partner: Hyundai Colombia S.A.S</li>
                <li>
                  Participating Locations:: Hyundai Dealership Network
                  Nationwide detailed below:
                </li>
              </ul>
            </p>
            <div>
              <img src="../assets/politicas/agencias.webp" alt="" />
            </div>
            <p className="legal-texto">
              <ul>
                <li>
                  Hyundai Colombia S.A.S. reserves the right to modify this
                  promotion at any time without notice.
                </li>
                <li>
                  For purchases of a Hyundai Kona Hybrid, Hyundai Kona Electric,
                  and Hyundai Tucson made at any of the Participating Locations
                  during the promotion period, members may receive 5,000
                  LifeMiles.
                </li>
                <li>
                  Promotion applies to purchases of any Hyundai Kona Hybrid
                  model, Hyundai Kona Electric and Hyundai Tucson.
                </li>
                <li>
                  Mile bonuses will be credited a maximum of thirty days after
                  the vehicle purchase.
                </li>
                <li>
                  This promotion is developed and executed by Hyundai Colombia
                  S.A.S who is solely responsible for crediting LifeMiles Miles
                  in user accounts. LifeMiles is not responsible for Miles not
                  credited by Hyundai Colombia S.A.S. In the event of a claim or
                  controversy in relation to this promotion, the user must
                  contact Hyundai Colombia S.A.S.
                </li>
                <li>
                  Once the miles have been credited in the User's LifeMiles
                  account, they will be subject to all the terms and conditions
                  of the LifeMiles Program, including their expiration policy,
                  which can be consulted at the following link:{" "}
                  <a href="https://www.lifemiles.com/">www.lifemiles.com.</a>
                </li>
                <li>LifeMiles is a registered trademark of LifeMiles Ltd.</li>
              </ul>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="13">
          <Accordion.Header>
            14. Acciones Comerciales Noviembre
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia: Del 1 al 30 de Noviembre
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Bonos de descuento de hasta 30 Millones y Tasa de .1.28 NMV
              </span>
            </p>
            <p className="legal-texto">*Bonos de descuento</p>
            <p className="legal-texto">
              El bono de descuento de hasta $30.000.000 aplica para los
              vehículos cero kilómetros de las referencias Hyundai KONA
              Eléctrica (EV). Bono sujeto al cumplimiento de los requisitos de
              Hyundai Colombia SAS (en adelante Hyundai Colombia) que podrán ser
              consultados directamente con el asesor en el concesionario. Los
              bonos aplicables a otras referencias pueden ser consultados
              directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              Los bonos no son redimibles en dinero ni acumulables con otros
              descuentos o promociones.
            </p>
            <p className="legal-texto">**Tasa de descuento desde 1.28 NMV</p>
            <p className="legal-texto">
              Aplica para los planes financieros: Plan 25-25-25-25
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Plan Residual 25-25-25-25</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para vehículos
              nuevos de la marca Hyundai. Los equipamientos, accesorios, y
              colores de todas las piezas publicitarias hacen parte de la
              ambientación fotográfica. Por esta razón pueden no corresponder
              con las referencias y modelos comercializados en Colombia. El plan
              de financiación podrá ser otorgado únicamente por entidades
              financieras aliadas a Santander Financing S.A.S. y estará sujeto a
              las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 75% del valor total del vehículo: se
                  cancelarán tres pagos correspondientes al 25% del valor
                  comercial del vehículo en la cuota 12,24 y 36, durante el
                  plazo de financiación se establecen cuotas mensuales (Lo
                  anterior puede variar en caso de presentarse incumplimientos
                  al cronograma de pagos).
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 25% del valor total
                  del vehículo.
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante.
                </li>
                <li>El plazo máximo de financiación será de hasta 36 meses.</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 01 de noviembre al 30 de noviembre 2023 que se
              desembolsen antes del 15 de diciembre de 2023 , y no será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              Ni Hyundai Colombia ni sus afiliadas en Colombia son compañías
              autorizadas para la contratación, recomendación o expedición de
              seguros en Colombia o para realizar actividades de financiación,
              por lo que ni la contratación del SOAT ni las actividades propias
              de financiación de los vehículos serán responsabilidad de Hyundai
              Colombia. Promoción válida del 1 al 30 de Noviembre.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">LEGALES Hyundai CRETA</span>
            </p>
            <p className="legal-texto">*Bonos de descuento</p>
            <p className="legal-texto">
              El bono de descuento de hasta $3.000.000 aplica para los vehículos
              cero kilómetros de las referencias Hyundai CRETA año modelo 2024.
              Bono sujeto al cumplimiento de los requisitos de Hyundai Colombia
              SAS (en adelante Hyundai Colombia) que podrán ser consultados
              directamente con el asesor en el concesionario. Los bonos
              aplicables a otras referencias pueden ser consultados directamente
              con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              Los bonos no son redimibles en dinero ni acumulables con otros
              descuentos o promociones.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">LEGALES Hyundai TUCSON</span>
            </p>
            <p className="legal-texto">*Bonos de descuento</p>
            <p className="legal-texto">
              El bono de descuento de hasta $23.000.000 aplica para los
              vehículos cero kilómetros de las referencias Hyundai TUCSON
              LIMITED 4X4 año modelo 2024. Bono sujeto al cumplimiento de los
              requisitos de Hyundai Colombia SAS (en adelante Hyundai Colombia)
              que podrán ser consultados directamente con el asesor en el
              concesionario. Los bonos aplicables a otras referencias pueden ser
              consultados directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              Los bonos no son redimibles en dinero ni acumulables con otros
              descuentos o promociones.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                LEGALES Hyundai KONA Híbrida
              </span>
            </p>
            <p className="legal-texto">*Bonos de descuento</p>
            <p className="legal-texto">
              El bono de descuento de hasta $22.000.000 aplica para los
              vehículos cero kilómetros de las referencias Hyundai Kona Híbrida
              limited año modelo 2024. Bono sujeto al cumplimiento de los
              requisitos de Hyundai Colombia SAS (en adelante Hyundai Colombia)
              que podrán ser consultados directamente con el asesor en el
              concesionario. Los bonos aplicables a otras referencias pueden ser
              consultados directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              Los bonos no son redimibles en dinero ni acumulables con otros
              descuentos o promociones.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                LEGALES Hyundai KONA Eléctrica
              </span>
            </p>
            <p className="legal-texto">*Bonos de descuento</p>
            <p className="legal-texto">
              El bono de descuento de hasta $30.000.000 aplica para los
              vehículos cero kilómetros de las referencias Hyundai KONA
              Eléctrica (EV). Bono sujeto al cumplimiento de los requisitos de
              Hyundai Colombia SAS (en adelante Hyundai Colombia) que podrán ser
              consultados directamente con el asesor en el concesionario. Los
              bonos aplicables a otras referencias pueden ser consultados
              directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              Los bonos no son redimibles en dinero ni acumulables con otros
              descuentos o promociones.
            </p>
            <p className="legal-texto">**Tasa de descuento desde 1.28 NMV</p>
            <p className="legal-texto">
              Aplica para los planes financieros: Plan 25-25-25-25
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Plan Residual 25-25-25-25</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para vehículos
              nuevos de la marca Hyundai. Los equipamientos, accesorios, y
              colores de todas las piezas publicitarias hacen parte de la
              ambientación fotográfica. Por esta razón pueden no corresponder
              con las referencias y modelos comercializados en Colombia. El plan
              de financiación podrá ser otorgado únicamente por entidades
              financieras aliadas a Santander Financing S.A.S. y estará sujeto a
              las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 75% del valor total del vehículo: se
                  cancelarán tres pagos correspondientes al 25% del valor
                  comercial del vehículo en la cuota 12,24 y 36, durante el
                  plazo de financiación se establecen cuotas mensuales (Lo
                  anterior puede variar en caso de presentarse incumplimientos
                  al cronograma de pagos).
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 25% del valor total
                  del vehículo.
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante.
                </li>
                <li>El plazo máximo de financiación será de hasta 36 meses.</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 01 de noviembre al 30 de noviembre 2023 que se
              desembolsen antes del 15 de diciembre de 2023 , y no será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              Ni Hyundai Colombia ni sus afiliadas en Colombia son compañías
              autorizadas para la contratación, recomendación o expedición de
              seguros en Colombia o para realizar actividades de financiación,
              por lo que ni la contratación del SOAT ni las actividades propias
              de financiación de los vehículos serán responsabilidad de Hyundai
              Colombia. Promoción válida del 1 al 30 de Noviembre.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">LEGALES Hyundai HB20</span>
            </p>
            <p className="legal-texto">*Bonos de descuento</p>
            <p className="legal-texto">
              El bono de descuento de hasta $13.000.000 aplica para los
              vehículos cero kilómetros de las referencias Hyundai HB20
              Hatchback año modelo 2024. Bono sujeto al cumplimiento de los
              requisitos de Hyundai Colombia SAS (en adelante Hyundai Colombia)
              que podrán ser consultados directamente con el asesor en el
              concesionario. Los bonos aplicables a otras referencias pueden ser
              consultados directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              Los bonos no son redimibles en dinero ni acumulables con otros
              descuentos o promociones.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">LEGALES Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">*Precio $99.990.000</span>
            </p>
            <p className="legal-texto">
              El precio de venta publicado $99.990.000 incluye IVA y sólo solo
              aplica para los vehículos cero kilómetros de Hyundai VENUE 2024.
              Los precios en otras referencias podrán ser consultados
              directamente con el asesor en el concesionario. Para mayor
              información consulte condiciones en www.hyundaicolombia.co
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="14">
          <Accordion.Header>15. Blue Friday Hyundai</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 23 al 28 de Noviembre
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai HB20</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 69.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $69.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai HB20
              Hatchback versión Advance Mecánico año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai HB20S</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 70.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $70.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai HB20S
              Sedan versión Advance Mecánico año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 99.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $99.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Venue
              versión Limited Automática año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai Creta</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 116.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $116.990.990 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Creta
              versión Premium Automática año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai Creta</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 123.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $123.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Tucson
              versión Attraction Mecánica año modelo 2024.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="15">
          <Accordion.Header>
            16. Acciones Comerciales Diciembre
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 1 al 20 de Diciembre
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai HB20</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 69.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $69.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai HB20
              Hatchback versión Advance Mecánico año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai HB20S</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 70.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $70.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai HB20S
              Sedan versión Advance Mecánico año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 99.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $99.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Venue
              versión Limited Automática año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai Creta</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 116.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $116.990.990 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Creta
              versión Premium Automática año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Vehículos Hyundai Tucson</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 123.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $123.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Tucson
              versión Attraction Mecánica año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vehículos Hyundai Kona Híbrida y Eléctrica
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 140.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $140.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Kona
              Híbrida versión premium año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Precio especial $ 199.990.000
              </span>
            </p>
            <p className="legal-texto">
              El precio especial publicado de $199.990.000 incluye el valor del
              IVA y aplica únicamente para los vehículos nuevos Hyundai Kona
              eléctrica año modelo 2024.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="16">
          <Accordion.Header>17. Alianza Hyundai - Colsubsidio</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                VIGENCIA: Hasta el 15 de diciembre del 2023
              </span>
            </p>
            <p className="legal-texto">
              Los precios y descuentos ofrecidos aplican únicamente para
              empleados directos de La caja de compensación Colsubsidio y/o para
              sus afiliados. Solamente se venderá una unidad por comprador, por
              año. Las Imágenes publicadas en las piezas de comunicación son de
              referencia. Los Diseños, rines y acabados o accesorios pueden
              variar y/o tener un costo adicional. Los descuentos especiales
              ofrecidos a Colsubsidio corresponden a las referencias de
              vehículos nuevos de Hyundai referencias HB20, HB20S, Kona híbrida,
              Kona eléctrica y Tucson NX4 MT y AT.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai HB20 Hatchback precio desde $67.400.000
              </span>
            </p>
            <p className="legal-texto">
              Los precios y descuentos ofrecidos aplican únicamente para
              empleados directos de La caja de compensación Colsubsidio y/o para
              sus afiliados. El precio publicado corresponde al precio para
              Hyundai HB20 Hatchback advance MT. Otros descuentos especiales
              ofrecidos a Colsubsidio corresponden a las referencias de
              vehículos nuevos marca Hyundai de las referencias HB20, HB20S año
              modelo 2024 y podrán ser consultados directamente en el
              concesionario. El plan de financiación podrá ser otorgado
              únicamente por entidades financieras aliadas a Santander Financing
              S.A.S. estará sujeto a las condiciones del banco y podrán ser
              consultadas directamente en el concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson precio desde $117.990.000
              </span>
            </p>
            <p className="legal-texto">
              Los precios y descuentos ofrecidos aplican únicamente para
              empleados directos de La caja de compensación Colsubsidio y/o para
              sus afiliados. El precio publicado corresponde al precio para
              Hyundai Tucson Attraction MT. Otros descuentos especiales
              ofrecidos a Colsubsidio corresponden a las referencias de
              vehículos nuevos marca Hyundai de las referencias Tucson año
              modelo 2024 y podrán ser consultados directamente en el
              concesionario. El plan de financiación podrá ser otorgado
              únicamente por entidades financieras aliadas a Santander Financing
              S.A.S. estará sujeto a las condiciones del banco y podrán ser
              consultadas directamente en el concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Kona Híbrida precio desde $130.000.000
              </span>
            </p>
            <p className="legal-texto">
              Los precios y descuentos ofrecidos aplican únicamente para
              empleados directos de La caja de compensación Colsubsidio y/o para
              sus afiliados. El precio publicado corresponde al precio para
              Hyundai Kona Híbrida Premium AT. Otros descuentos especiales
              ofrecidos a Colsubsidio corresponden a las referencias de
              vehículos nuevos marca Hyundai de las referencias Kona híbrida
              (unidades limitadas, hasta 30 unidades disponibles en Colombia)
              año modelo 2024 y podrán ser consultados directamente en el
              concesionario. El plan de financiación podrá ser otorgado
              únicamente por entidades financieras aliadas a Santander Financing
              S.A.S. estará sujeto a las condiciones del banco y podrán ser
              consultadas directamente en el concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Kona Eléctrica precio desde $188.000.000
              </span>
            </p>
            <p className="legal-texto">
              Los precios y descuentos ofrecidos aplican únicamente para
              empleados directos de La caja de compensación Colsubsidio y/o para
              sus afiliados. El precio publicado corresponde al precio para
              Hyundai Kona Híbrida Premium AT. Otros descuentos especiales
              ofrecidos a Colsubsidio corresponden a las referencias de
              vehículos nuevos marca Hyundai de las referencias Kona Eléctrica
              año modelo 2024 y podrán ser consultados directamente en el
              concesionario. El plan de financiación podrá ser otorgado
              únicamente por entidades financieras aliadas a Santander Financing
              S.A.S. estará sujeto a las condiciones del banco y podrán ser
              consultadas directamente en el concesionario.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="17">
          <Accordion.Header>
            18. Plan siempre nuevo banco Santander
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 21 de Febrero al 31 de Marzo de 2024
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                ** Plan siempre nuevo banco Santander
              </span>
            </p>
            <p className="legal-texto">
              <ul>
                <li>
                  Cuotas más bajas: El plan de financiación puede ser otorgado
                  por un valor de hasta el 70% del valor total del vehículo con
                  una cuota inicial desde el 30% del valor total del vehículo y
                  una cuota residual de capital hasta por el 45% del valor total
                  del vehículo. Plazo máximo de financiación: hasta 48 meses. El
                  otorgamiento del plan de financiación está sujeto a
                  cumplimiento de las políticas de crédito y cumplimiento de las
                  entidades financieras aliadas de Santander Financing S.A.S., y
                  el perfil crediticio del solicitante. El Cliente pagará 48
                  cuotas mensuales compuestas por capital, intereses, seguro de
                  vida, seguro todo riesgo u otros cargos siempre que los
                  seguros sean adquiridos y financiados a través de Santander
                  Financing S.A.S., o con alguna de las entidades financieras
                  aliadas. Al final del periodo se causará una cuota residual
                  hasta por el 45% del valor total del vehículo. Previo al
                  desembolso, los vehículos deberán contar con seguro de auto
                  todo riesgo y el deudor deberá tener contratado el amparo de
                  seguro vida hasta el valor del desembolso, los cuales deberán
                  permanecer vigentes durante la vigencia del crédito. Válido
                  desde el 21 de febrero de 2024, hasta el 31 de marzo de 2024,
                  obteniendo financiación para compra de vehículo en cualquier
                  concesionario de la red autorizada Hyundai Colombia S.A.S a
                  nivel nacional. Santander Consumer es la marca usada por el
                  Grupo Santander para los productos de retail. En virtud de
                  ello, Santander Financing S.A.S., como entidad del Grupo
                  Santander está autorizada para hacer uso de la misma. Ni
                  Hyundai Colombia ni sus afiliadas en Colombia son compañías
                  autorizadas para realizar actividades de financiación, por lo
                  que estas actividades no serán responsabilidad de Hyundai.
                </li>
                <li>
                  Estrenando cada cuatro años: si al terminar el período de
                  financiación de 48 meses, el cliente opta por comprar un
                  vehículo nuevo de la marca Hyundai, mediante un proceso de
                  financiación nuevo; el valor de la retoma del vehículo antiguo
                  será tenido en cuenta como valor de cuota inicial para el
                  vehículo nuevo. Consultar condiciones directamente con
                  Santander Financing S.A.S.
                </li>
                <li>
                  La expresión garantía incluida durante todo el tiempo de uso
                  hace referencia a que la garantía será válida siempre y cuando
                  el cliente renueve el vehículo cada cuatro años. La
                  enajenación o entrega de los vehículos por parte de los
                  concesionarios a los clientes estará cubierta por las
                  garantías que resulten aplicables, de conformidad con la
                  legislación colombiana. No acumulable con otros descuentos o
                  promociones vigentes.
                </li>
              </ul>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="18">
          <Accordion.Header>19. Nueva Kona Híbrida</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 7 al 31 de Marzo de 2024
              </span>
              <span className="legal-bold mb-1">*Garantía Hyundai</span>
            </p>
            <p className="legal-texto">
              Para conocer las condiciones ofrecidas en la garantía de Hyundai,
              por favor visita el enlace a continuación:{" "}
              <a href="/posventa/">https://www.hyundaicolombia.co/posventa</a>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">** Precio $ 139.990.000</span>
            </p>
            <p className="legal-texto">
              El precio publicado de $139.990.000 incluye el valor del IVA y
              aplica únicamente para los vehículos nuevos de Nueva Hyundai Kona
              versión Premium año modelo 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">*** Millas Lifemiles</span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Términos y Condiciones de acumulación Millas Hyundai
              </span>
            </p>
            <p className="legal-texto">
              <ul>
                <li>Periodo de promoción: del 7 al 31 de Marzo del 2023.</li>
                <li>Promoción aplica únicamente para Colombia.</li>
                <li>Comercio Participante: Hyundai Colombia S.A.S.</li>
                <li>
                  Sucursales participantes: Red de concesionarios Hyundai a
                  Nivel Nacional detalladas a continuación:
                </li>
              </ul>
            </p>
            <div>
              <img src="" alt="../assets/politicas/agencias.webp" />
            </div>
            <p className="legal-texto">
              <ul>
                <li>
                  Hyundai Colombia S.A.S se reserva el derecho a modificar esta
                  promoción en cualquier momento sin previo aviso.
                </li>
                <li>
                  Por compras de una Nueva Hyundai Kona Híbrida, versión limited
                  ó premium realizada en las Sucursales Participantes durante el
                  período de la promoción, los socios podrán recibir un bono de
                  2000 millas LifeMiles.
                </li>
                <li>
                  Aplica para todas las referencias de Nueva Hyundai KONA
                  híbrida año modelo 2024 versiones Premium y Limited.
                </li>
                <li>
                  Los Bonos de Millas se acreditarán en un máximo de 30 días
                  después de realizada la compra del vehículo.
                </li>
                <li>
                  Esta promoción es desarrollada por Hyundai Colombia S.A.S.,
                  quién es el único responsable de acreditar las Millas
                  LifeMiles en las cuentas de los usuarios. LifeMiles no se hace
                  responsable por Millas no acreditadas por parte de Hyundai
                  Colombia S.A.S.. En caso de reclamo o controversia en relación
                  con la presente promoción, el usuario deberá comunicarse
                  directamente con Hyundai Colombia S.A.S.
                </li>
                <li>
                  Una vez aplicadas las millas LifeMiles a la cuenta de los
                  Socios, las mismas estarán sujetas a todos los términos y
                  condiciones del Programa LifeMiles, incluyendo la política de
                  expiración de las mismas, los cuales pueden ser consultados en
                  el siguiente enlace: www.lifemiles.com:{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.lifemiles.com"
                  >
                    www.lifemiles.com.
                  </a>
                </li>
                <li>LifeMiles es una marca registrada de LifeMiles Ltd.</li>
              </ul>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Terms and conditions of Acruel Hyundai Miles
              </span>
            </p>
            <p className="legal-texto">
              <ul>
                <li>Promotion period: from March 7th to 31th 2024</li>
                <li>Promotion applies only to Colombia..</li>
                <li>Commercial Partner: Hyundai Colombia S.A.S.</li>
                <li>Commercial Partner: Hyundai Colombia S.A.S.</li>
                <li>
                  Participating Locations: Hyundai Dealership Network Nationwide
                  detailed below:
                </li>
              </ul>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="19">
          <Accordion.Header>20. Acciones Comerciales Junio</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 1 al 30 de junio de 2024
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Kona Gasolina</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 1 al
              30 de junio de 2024 *El precio de venta publicado $115.990.000
              incluye IVA y sólo aplica para los vehículos nuevos Hyundai KONA
              motor a gasolina versión Premium año modelo 2025. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai HB20 cuota desde $885.000
              </span>
            </p>
            <p className="legal-texto">
              *Estrena con cuota desde $885.000 corresponde al plan financiero
              ofrecido por el banco Santander Plan 14 cuotas a 72 meses:
            </p>
            <p className="legal-texto">
              El plan de financiación anunciado aplica únicamente para vehículos
              nuevos de HYUNDAI.
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo en
                  cuotas mensuales y 2 cuotas extras en junio y diciembre; o
                  Julio y Enero durante toda su vigencia;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 30% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses.</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 1 de junio de 2024 al 30 de junio de 2024, que se
              desembolsen antes del 15 de Julio de 2024. No será acumulable con
              otros descuentos o promociones vigentes. Ni Santander Financing
              S.A.S., ni sus entidades financieras aliadas, adquieren algún tipo
              de compromiso de recompra respecto del vehículo automotor a ser
              adquirido por el solicitante. "Santander Consumer es la marca
              usada por el Grupo Santander para los productos de retail. En
              virtud de ello, Santander Financing S.A.S., como entidad del Grupo
              Santander está autorizada para hacer uso de la misma".
            </p>
            <p className="legal-texto">
              ** Precio desde: El precio de venta publicado $67.990.000 incluye
              IVA y sólo solo aplica para los vehículos nuevos Hyundai HB20
              Hacthback y HB20S Sedán versión mecánicos año modelo 2024 que sean
              financiados con Banco Santander. Los precios en otras referencias
              podrán ser consultados directamente con el asesor en el
              concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson Select desde $12.000.000 millones Cuota inicial,
                exención de pico y placa, Matrícula Completa y Bono de Retoma
              </span>
            </p>
            <p className="legal-texto">
              <strong>*Estrena con cuota inicial desde $12.000.000</strong>{" "}
              corresponde al plan financiero ofrecido por el banco Santander y
              aplica únicamente para vehículos nuevos de Hyundai Tucson Select
              Mecanica año modelo 2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Periodo de Gracia 12 meses
              </span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en la referencia
              Tucson Select MT año modelo 2025 Los accesorios, y colores de
              todas las piezas publicitarias hacen parte de la ambientación
              fotográfica. Por esta razón pueden no corresponder con las
              referencias y modelos comercializados en Colombia. El plan de
              financiación podrá ser otorgado únicamente por entidades
              financieras aliadas a Santander Financing S.A.S. y estará sujeto a
              las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 90% del valor total del vehículo en
                  cuotas mensuales;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 10% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>
                  El plazo máximo de financiación será de hasta 72 meses,
                  incluyendo 12 meses iniciales de periodo de gracias a capital,
                  en los cuales el solicitante solo pagará intereses;
                </li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 1 de junio de 2024 al 30 de junio de 2024, que se
              desembolsen antes del 15 de Julio de 2024. No será acumulable con
              otros descuentos o promociones vigentes. Ni Santander Financing
              S.A.S., ni sus entidades financieras aliadas, adquieren algún tipo
              de compromiso de recompra respecto del vehículo automotor a ser
              adquirido por el solicitante. "Santander Consumer es la marca
              usada por el Grupo Santander para los productos de retail. En
              virtud de ello, Santander Financing S.A.S., como entidad del Grupo
              Santander está autorizada para hacer uso de la misma".
            </p>
            <p className="legal-texto">
              ** Matrícula Completa: el de la matrícula completa incluye Soat,
              impuestos, semaforización y derechos de tránsito para vehículos
              nuevos de Hyundai Tucson Select Mecánica año modelo 2025 que sean
              adquiridos en la red de concesionarios Hyundai a nivel nacional.
              La matrícula completa será otorgada por el concesionario Hyundai
              autorizado en el que realice la compra del vehículo. La matrícula
              no incluye los tramites de prendas ni gestor.
            </p>
            <p className="legal-texto">
              **Exención de Pico y Placa: Aplica únicamente para vehículos
              nuevos Hyundai Tucson Select Mecánica año modelo 2025 que sean
              requieran la exención de pico y placa para movilidad en las
              ciudades de Cali y Bogotá.
            </p>
            <p className="legal-texto">
              **Bono de retoma: Se otorgará a discreción de la inspección
              realizada al vehículo usado directamente por el asesor del
              concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson Select tasa de 0,99%, exención de pico y placa,
                Matrícula Completa y Bono de Retoma
              </span>
            </p>
            <p className="legal-texto">
              <strong>*Tasa de 0,99%</strong> corresponde al plan financiero
              ofrecido por el banco Santander y aplica únicamente para vehículos
              nuevos de Hyundai Tucson Select Mecanica año modelo 2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">PLAN: TASA 0,99% 12 MESES</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI Tucson Select
              Mecánica año modelo 2025
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo;
                  donde pagará capital, intereses y seguro. Cuota inicial desde
                  el 30% sobre el valor total del vehículo, amortización por
                  tramos. El primer tramo podrá tener una duración máxima de 12
                  meses, y el segundo tramo podrá tener una duración máxima de
                  60 meses, para un total de hasta 72 meses.
                </li>
                <li>
                  La tasa inicial, para los primeros 12 meses del crédito será
                  del 0.99% mensual de acuerdo con perfil crediticio. Para los
                  meses restantes del crédito aplicará la tasa determinada de
                  acuerdo con el perfil crediticio y a las políticas de crédito.
                  En ambos casos la tasa de interés será fija una vez asignada a
                  cada solicitante, y la tasa aplicable a cada solicitante podrá
                  variar de acuerdo con el perfil crediticio del solicitante y
                  las políticas de crédito de Santander Financing S.A.S.
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses;</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 1 de junio de 2024 al 30 de junio de 2024, que se
              desembolsen antes del 15 de Julio de 2024. No será acumulable con
              otros descuentos o promociones vigentes. Ni Santander Financing
              S.A.S., ni sus entidades financieras aliadas, adquieren algún tipo
              de compromiso de recompra respecto del vehículo automotor a ser
              adquirido por el solicitante. "Santander Consumer es la marca
              usada por el Grupo Santander para los productos de retail. En
              virtud de ello, Santander Financing S.A.S., como entidad del Grupo
              Santander está autorizada para hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <strong>** Matrícula Completa:</strong> el de la matrícula
              completa incluye Soat, impuestos, semaforización y derechos de
              tránsito para vehículos nuevos de Hyundai Tucson Select Mecánica
              año modelo 2025 que sean adquiridos en la red de concesionarios
              Hyundai a nivel nacional. La matrícula completa será otorgada por
              el concesionario Hyundai autorizado en el que realice la compra
              del vehículo. La matrícula no incluye los tramites de prendas ni
              gestor.
            </p>
            <p className="legal-texto">
              <strong>**Exención de Pico y Placa:</strong> Aplica únicamente
              para vehículos nuevos Hyundai Tucson Select Mecánica año modelo
              2025 que sean requieran la exención de pico y placa para movilidad
              en las ciudades de Cali y Bogotá.
            </p>
            <p className="legal-texto">
              <strong>**Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="20">
          <Accordion.Header>21. Hyundai + Service</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Imágenes de referencia. Algunos accesorios, colores, diseños y/o
              acabados pueden variar de la versión comercializada en Colombia y
              tener un costo adicional. Oferta válida desde Mayo 2024 y hasta
              nueva información. Aplica para las referencias Tucson NX4 , Creta
              y Venue vendidas desde Mayo de 2024 en cualquiera de los
              concesionarios de red autorizados por Hyundai Colombia S.A.S. . El
              cambio de aceite de motor y el filtro de aceite operará de la
              siguiente forma: para el mantenimiento de los 10. 000 Km o el
              primer año y el mantenimiento de los 20.000 Km o el segundo año;
              lo que primero ocurra en cada caso. No se incluyen componentes
              adicionales o materiales y suministros que se requieren para
              mantenimiento del vehículo como filtro de aire, filtro de
              combustible, filtro de aire acondicionado, y otros elementos de
              desgaste que se puedan evidenciar en la inspección.
            </p>
            <p className="legal-texto">T&amp;C:</p>
            <p className="legal-texto">
              <ul>
                <li>
                  <u>Determinación del objeto:</u> El presente documento
                  establece los términos y condiciones aplicables a la
                  publicidad del programa "+Servicio" (en adelante, el "
                  <strong>Programa</strong>"), ofrecido por Hyundai Colombia
                  S.A.S ("<strong>HC</strong>").
                </li>
                <li>
                  <u>Beneficios:</u> El Programa consiste en la inclusión de los
                  servicios de cambio de aceite y filtro de aceite de manera
                  gratuita durante los primeros dos años o 20.000 Km (lo que
                  primero ocurra) para aquellos vehículos seleccionados de HC y
                  que sean adquiridos por clientes finales a través de la red de
                  talleres autorizados de HC dentro de Colombia. El cambio de
                  aceite de motor y el filtro de aceite de motor operará de la
                  siguiente forma: para el mantenimiento de los 10. 000 Km o el
                  primer año y el mantenimiento de los 20.000 Km o el segundo
                  año; lo que primero ocurra en cada caso.
                </li>
                <li>
                  Los clientes finales deberán programar las citas para el
                  cambio de aceite y filtro de aceite con la debida anticipación
                  en el taller de servicio autorizado de su preferencia (que
                  estará disponible para consulta en los canales oficiales de
                  HC). HC no se hace responsable por la disponibilidad de citas
                  en los talleres de servicio autorizados.
                </li>
                <li>
                  Los beneficios serán susceptibles de trasladarse a las
                  personas que adquieran los vehículos objeto de cobertura de
                  los clientes finales que estén dentro de los tres años o
                  30.000km
                </li>
                <li>
                  <u>Vigencia del Programa:</u> El Programa estará vigente desde
                  el mes de mayo de 2024 hasta confirmar cambios, o hasta que se
                  venzan los términos de Km 20000 o tiempo 2 años.
                </li>
                <li>
                  <u>Vehículos cubiertos:</u> El Programa aplica exclusivamente
                  a los siguientes modelos de vehículos: Tucson NX4, Venue y
                  Creta HC se reserva el derecho de ampliar o modificar la lista
                  de modelos de vehículos aplicables al Programa, comunicándolo
                  debidamente a través de sus canales oficiales.
                </li>
                <li>
                  <u>Exclusiones:</u> Estos beneficios solo serán aplicables en
                  los casos expresamente previstos en estos términos y
                  condiciones, y se excluirá su aplicación en los escenarios no
                  previstos, como los siguientes:
                  <ul>
                    <li>
                      Solicitudes de cambios de aceite y filtro de aceite
                      realizados en los talleres de servicio no autorizados por
                      HC.
                    </li>
                    <li>
                      Solicitudes efectuadas fuera del territorio nacional.
                    </li>
                    <li>Repuestos o servicios no incluidos en el Programa.</li>
                    <li>
                      Mano de obra por reparaciones o servicios adicionales no
                      contemplados en el Programa.
                    </li>
                    <li>
                      Gastos de transporte o viáticos asociados al traslado del
                      vehículo al taller de servicio autorizado.
                    </li>
                  </ul>
                </li>
                <li>
                  <u>Derechos de HC:</u> HC se reserva el derecho de modificar,
                  suspender o terminar definitivamente el Programa en cualquier
                  momento, comunicándolo debidamente a través de sus canales
                  oficiales. HC no se hace responsable por los inconvenientes
                  que dicha modificación o suspensión pudiera ocasionar.
                </li>
                <li>
                  <u>Aceptación:</u> La adquisición de un vehículo al que sea
                  aplicable el Programa durante el Periodo de Vigencia implica
                  la aceptación plena y sin reservas de los presentes Términos y
                  Condiciones por parte del cliente final. La aceptación de los
                  términos y condiciones implica la aceptación de la política de
                  tratamiento de datos personales de HC, disponible para
                  consulta en{" "}
                  <a href="https://www.hyundaicolombia.co/politica-proteccion-datos/">
                    https://www.hyundaicolombia.co/politica-proteccion-datos/
                  </a>
                </li>
              </ul>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="21">
          <Accordion.Header>22. Campaña Hyundai Kona</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                CONDICIONES CAMPAÑA DE LANZAMIENTO HYUNDAI KONA JUNIO 2024
              </span>
            </p>
            <p className="legal-texto">
              Las Imágenes son de referencia. Diseños, rines y acabados o
              accesorios pueden variar y/o tener un costo adicional.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Querida SUV, si tu garantía es solo 5 años y no de 7:
              </span>
            </p>
            <p className="legal-texto">
              El enunciado hace referencia a la garantía ofrecida en Hyundai
              para los vehículos híbridos y de motor a combustión
              correspondiente a 7 años o 140.000 Kms y que pueden ser
              consultados siguiendo el enlace a continuación
              <a href="/posventa/">https://www.hyundaicolombia.co/posventa/</a>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *¿Eres una de las SUV que no tiene 2 pantallas panorámicas de
                12,3”:
              </span>
            </p>
            <p className="legal-texto">
              El enunciado hace referencia a las dos pantallas panorámicas de
              12.3”: una ubicada en el tablero de instrumentos con Pantalla
              Supervisión 12,3” LCD TFT+ Reóstato y la segunda compuesta por
              Radio con pantalla táctil de 12,3 que ofrece en su consola su
              nueva Hyundai KONA año modelo 2024 en sus versiones Híbrida, motor
              a combustión y N Line.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *Querida SUV, si dices que eres moderna, pero no cambias tu
                diseño hace años:
              </span>
            </p>
            <p className="legal-texto">
              El enunciado hace referencia a la reciente renovación de diseño
              efectuado en la Nueva Hyundai KONA año modelo 2025 y que fue
              lanzado en Colombia en marzo de 2024.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                *A las SUV que prometen ahorro, pero ofrecen menos de
                90Km/Galón:
              </span>
            </p>
            <p className="legal-texto">
              El enunciado hace referencia a la medición en el ciclo combinado
              con respecto a la metodología EPA
              <strong>
                (Agencia de Protección Ambiental de Estados Unidos) y aplica
                únicamente para Hyundai KONA HÍBRIDA año modelo 2025.
              </strong>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="22">
          <Accordion.Header>
            23. Acciones comerciales Junio - Julio
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 26 de Junio al 14 de Julio de 2024
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Kona Gasolina</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 26
              de junio al 14 de julio de 2024 *El precio de venta publicado
              $113.990.000 incluye IVA y sólo aplica para los vehículos nuevos
              Hyundai KONA motor a gasolina versión Premium año modelo 2025. Los
              precios en otras referencias podrán ser consultados directamente
              con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai HB20 cuota desde $885.000
              </span>
            </p>
            <p className="legal-texto">
              *Estrena con cuota desde $885.000 corresponde al plan financiero
              ofrecido por el banco Santander Plan 14 cuotas a 72 meses:
            </p>
            <p className="legal-texto">
              El plan de financiación anunciado aplica únicamente para vehículos
              nuevos de HYUNDAI.
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo en
                  cuotas mensuales y 2 cuotas extras en junio y diciembre; o
                  Julio y Enero durante toda su vigencia;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 30% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses.</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 26 de junio de 2024 al 14 de Julio de 2024, que
              se desembolsen antes del 30 de Julio de 2024. No será acumulable
              con otros descuentos o promociones vigentes. Ni Santander
              Financing S.A.S., ni sus entidades financieras aliadas, adquieren
              algún tipo de compromiso de recompra respecto del vehículo
              automotor a ser adquirido por el solicitante. "Santander Consumer
              es la marca usada por el Grupo Santander para los productos de
              retail. En virtud de ello, Santander Financing S.A.S., como
              entidad del Grupo Santander está autorizada para hacer uso de la
              misma".
            </p>
            <p className="legal-texto">
              ** Precio desde: El precio de venta publicado $67.990.000 incluye
              IVA y sólo solo aplica para los vehículos nuevos Hyundai HB20
              Hacthback y HB20S Sedán versión mecánicos año modelo 2024 que sean
              financiados con Banco Santander. Los precios en otras referencias
              podrán ser consultados directamente con el asesor en el
              concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson Select desde $12.000.000 millones Cuota inicial,
                exención de pico y placa, Matrícula Completa y Bono de Retoma
              </span>
            </p>
            <p className="legal-texto">
              <strong>*Estrena con cuota inicial desde $12.000.000</strong>{" "}
              corresponde al plan financiero ofrecido por el banco Santander y
              aplica únicamente para vehículos nuevos de Hyundai Tucson Select
              Mecánica año modelo 2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Periodo de Gracia 12 meses
              </span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en la referencia
              Tucson Select MT año modelo 2025 Los accesorios, y colores de
              todas las piezas publicitarias hacen parte de la ambientación
              fotográfica. Por esta razón pueden no corresponder con las
              referencias y modelos comercializados en Colombia. El plan de
              financiación podrá ser otorgado únicamente por entidades
              financieras aliadas a Santander Financing S.A.S. y estará sujeto a
              las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 90% del valor total del vehículo en
                  cuotas mensuales;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 10% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>
                  El plazo máximo de financiación será de hasta 72 meses,
                  incluyendo 12 meses iniciales de periodo de gracias a capital,
                  en los cuales el solicitante solo pagará intereses;
                </li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 26 de junio de 2024 al 14 de julio de 2024, que
              se desembolsen antes del 30 de Julio de 2024. No será acumulable
              con otros descuentos o promociones vigentes. Ni Santander
              Financing S.A.S., ni sus entidades financieras aliadas, adquieren
              algún tipo de compromiso de recompra respecto del vehículo
              automotor a ser adquirido por el solicitante. "Santander Consumer
              es la marca usada por el Grupo Santander para los productos de
              retail. En virtud de ello, Santander Financing S.A.S., como
              entidad del Grupo Santander está autorizada para hacer uso de la
              misma".
            </p>
            <p className="legal-texto">
              <strong>** Matrícula Completa:</strong> el valor de la matrícula
              completa incluye Soat, impuestos, semaforización y derechos de
              tránsito para vehículos nuevos de Hyundai Tucson Select Mecánica
              año modelo 2025 que sean adquiridos en la red de concesionarios
              Hyundai a nivel nacional. La matrícula completa será otorgada por
              el concesionario Hyundai autorizado en el que realice la compra
              del vehículo. La matrícula no incluye los tramites de prendas ni
              gestor.
            </p>
            <p className="legal-texto">
              <strong>**Exención de Pico y Placa:</strong> Aplica únicamente
              para vehículos nuevos Hyundai Tucson Select Mecánica año modelo
              2025 que sean requieran la exención de pico y placa para movilidad
              en las ciudades de Cali y Bogotá.
            </p>
            <p className="legal-texto">
              <strong>**Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson Select tasa de 0,99%, exención de pico y placa,
                Matrícula Completa y Bono de Retoma
              </span>
            </p>
            <p className="legal-texto">
              <strong>*Tasa de 0,99%</strong> corresponde al plan financiero
              ofrecido por el banco Santander y aplica únicamente para vehículos
              nuevos de Hyundai Tucson Select Mecanica año modelo 2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold">PLAN: TASA 0,99% 12 MESES</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI Tucson Select
              Mecánica año modelo 2025.
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo;
                  donde pagará capital, intereses y seguro. Cuota inicial desde
                  el 30% sobre el valor total del vehículo, amortización por
                  tramos. El primer tramo podrá tener una duración máxima de 12
                  meses, y el segundo tramo podrá tener una duración máxima de
                  60 meses, para un total de hasta 72 meses.
                </li>
                <li>
                  La tasa inicial, para los primeros 12 meses del crédito será
                  del 0.99% mensual de acuerdo con perfil crediticio. Para los
                  meses restantes del crédito aplicará la tasa determinada de
                  acuerdo con el perfil crediticio y a las políticas de crédito.
                  En ambos casos la tasa de interés será fija una vez asignada a
                  cada solicitante, y la tasa aplicable a cada solicitante podrá
                  variar de acuerdo con el perfil crediticio del solicitante y
                  las políticas de crédito de Santander Financing S.A.S.
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses;</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 26 de junio de 2024 al 14 de julio de 2024, que
              se desembolsen antes del 30 de Julio de 2024. No será acumulable
              con otros descuentos o promociones vigentes. Ni Santander
              Financing S.A.S., ni sus entidades financieras aliadas, adquieren
              algún tipo de compromiso de recompra respecto del vehículo
              automotor a ser adquirido por el solicitante. "Santander Consumer
              es la marca usada por el Grupo Santander para los productos de
              retail. En virtud de ello, Santander Financing S.A.S., como
              entidad del Grupo Santander está autorizada para hacer uso de la
              misma"
            </p>
            <p className="legal-texto">
              <strong>** Matrícula Completa:</strong> el de la matrícula
              completa incluye Soat, impuestos, semaforización y derechos de
              tránsito para vehículos nuevos de Hyundai Tucson Select Mecánica
              año modelo 2025 que sean adquiridos en la red de concesionarios
              Hyundai a nivel nacional. La matrícula completa será otorgada por
              el concesionario Hyundai autorizado en el que realice la compra
              del vehículo. La matrícula no incluye los tramites de prendas ni
              gestor.
            </p>
            <p className="legal-texto">
              <strong>**Exención de Pico y Placa:</strong> Aplica únicamente
              para vehículos nuevos Hyundai Tucson Select Mecánica año modelo
              2025 que sean requieran la exención de pico y placa para movilidad
              en las ciudades de Cali y Bogotá.
            </p>
            <p className="legal-texto">
              <strong>**Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Creta &amp; Samsung
              </span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 26
              de junio al 14 de julio de 2024 *El precio de venta publicado
              $107.990.000 incluye IVA y sólo aplica para los vehículos nuevos
              Hyundai Creta premium año modelo 2025. Los precios en otras
              referencias podrán ser consultados directamente con el asesor en
              el concesionario.
            </p>
            <p className="legal-texto">
              <strong>** Incluye TV de 50”:</strong> Por la compra de una
              Hyundai Creta Edición Samsung el cliente recibirá un televisor
              Samsung referencia UN50DU8200KXXL, el total de unidades
              disponibles a nivel nacional es de 100. La Ref. UN50DU8200KXXL es
              un producto Importado por Samsung Electronics Colombia S.A. Los
              productos publicados tienen 1 año de garantía directamente con el
              fabricante
            </p>
            <p className="legal-texto">
              <strong>***Exención de Pico y Placa ó Bono de Matrícula:</strong>{" "}
              Aplica únicamente para vehículos nuevos Hyundai Creta Premium año
              modelo 2025 que requieran la exención de pico y placa para
              movilidad en las ciudades de Cali y Bogotá. El bono de matrícula
              deberá ser consultado directamente en el concesionario, los dos
              beneficios NO son acumulables.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 26
              de Junio al 14 de julio de 2024.
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $97.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai Venue limited AT modelo
              2025 que sean financiados con el Banco Santander. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario. Santander Consumer es la marca usada
              por el Grupo Santander para los productos de retail. En virtud de
              ello, Santander Financing S.A.S., como entidad del Grupo Santander
              está autorizada para hacer uso de la misma.
            </p>
            <p className="legal-texto">
              <strong>**Matrícula Incluida:</strong> el valor de la matrícula
              incluye impuestos, semaforización y derechos de transito NO
              incluye el valor del SOAT
            </p>
            <p className="legal-texto">
              <strong>***Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="23">
          <Accordion.Header>24. Acciones comerciales Julio</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 14 al 31 de Julio de 2024
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Kona Gasolina</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 14
              al 31 de julio de 2024 *El precio de venta publicado $113.990.000
              incluye IVA y sólo aplica para los vehículos nuevos Hyundai KONA
              motor a gasolina versión Premium año modelo 2025. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario.
            </p>
            <p className="legal-texto">
              **Tasa fija 1.25% corresponde al plan financiero ofrecido por el
              banco Santander y aplica únicamente para vehículos nuevos de
              Hyundai KONA motor a gasolina versión premium año modelo 2025:
            </p>
            <p className="legal-texto">Plan: Tradicional</p>
            <p className="legal-texto">
              El plan de financiación anunciado aplica únicamente para vehículos
              nuevos de la marca HYUNDAI en la referencia KONA Gasolina Los
              accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo en
                  cuotas mensuales
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el cliente deberá pagar una cuota
                  inicial mínima del 30%
                </li>
                <li>
                  Se podrá incluir hasta un 10% en accesorios para el vehiculo
                  automotor, esto siempre y cuando estos sean facturados por
                  Automotor.Co y sean accesorios asegurables
                </li>
                <li>
                  La tasa de interés aplicable será fija del 1,25% para los 30
                  negocios desembolsados en las fechas de dicha acción
                  comercial. La tasa aplicable a cada solicitante podrá variar
                  de acuerdo con el perfil crediticio del solicitante
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores. En caso de que el solicitante financie la
                  contratación de alguna o todas las pólizas de seguros
                  mencionadas en el numeral (v) inmediatamente anterior a través
                  de Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, el valor de las cuotas mensual podrá
                  variar. Las condiciones de esta financiación serán informadas
                  de forma previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              matrículas de la referencia KONA GASOLINA del 14 al 31 de julio de
              2024 y desembolsados antes del 15 de agosto 2024. No será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante. "Santander
              Consumer es la marca usada por el Grupo Santander para los
              productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai HB20 cuota desde $885.000
              </span>
            </p>
            <p className="legal-texto">
              *Estrena con cuota desde $885.000 corresponde al plan financiero
              ofrecido por el banco Santander Plan 14 cuotas a 72 meses:
            </p>
            <p className="legal-texto">
              El plan de financiación anunciado aplica únicamente para vehículos
              nuevos de HYUNDAI.
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo en
                  cuotas mensuales y 2 cuotas extras en junio y diciembre; o
                  Julio y Enero durante toda su vigencia;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 30% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 14 al 31 de julio de 2024, que se desembolsen
              antes del 15 de Agosto de 2024. No será acumulable con otros
              descuentos o promociones vigentes. Ni Santander Financing S.A.S.,
              ni sus entidades financieras aliadas, adquieren algún tipo de
              compromiso de recompra respecto del vehículo automotor a ser
              adquirido por el solicitante. "Santander Consumer es la marca
              usada por el Grupo Santander para los productos de retail. En
              virtud de ello, Santander Financing S.A.S., como entidad del Grupo
              Santander está autorizada para hacer uso de la misma".
            </p>
            <p className="legal-texto">
              ** Precio desde: El precio de venta publicado $67.990.000 incluye
              IVA y sólo solo aplica para los vehículos nuevos Hyundai HB20
              Hacthback y HB20S Sedán versión mecánicos año modelo 2024 que sean
              financiados con Banco Santander. Los precios en otras referencias
              podrán ser consultados directamente con el asesor en el
              concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson precio desde $119.990.000, empieza a pagar en
                2025, exención de pico y placa, Matrícula Completa y Bono de
                Retoma.
              </span>
            </p>
            <p className="legal-texto">
              *Precio desde: El precio de venta publicado desde $119.990.000
              incluye IVA y sólo solo aplica para los vehículos nuevos Hyundai
              Tucson Select Mecánica año modelo 2025 que sean financiados con
              Banco Santander. Los precios en otras referencias podrán ser
              consultados directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>**Empieza a pagar en 2025</strong> corresponde al plan
              financiero ofrecido por el banco Santander y aplica únicamente
              para vehículos nuevos de Hyundai Tucson Select Mecánica año modelo
              2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Periodo de Gracia 12 meses
              </span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en la referencia
              Tucson Select MT año modelo 2025 Los accesorios, y colores de
              todas las piezas publicitarias hacen parte de la ambientación
              fotográfica. Por esta razón pueden no corresponder con las
              referencias y modelos comercializados en Colombia. El plan de
              financiación podrá ser otorgado únicamente por entidades
              financieras aliadas a Santander Financing S.A.S. y estará sujeto a
              las siguientes. condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 90% del valor total del vehículo en
                  cuotas mensuales;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 10% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>
                  El plazo máximo de financiación será de hasta 72 meses,
                  incluyendo 12 meses iniciales de periodo de gracias a capital,
                  en los cuales el solicitante solo pagará intereses;
                </li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 14 al 31 de julio de 2024, que se desembolsen
              antes del 31 de Agosto de 2024. No será acumulable con otros
              descuentos o promociones vigentes. Ni Santander Financing S.A.S.,
              ni sus entidades financieras aliadas, adquieren algún tipo de
              compromiso de recompra respecto del vehículo automotor a ser
              adquirido por el solicitante. "Santander Consumer es la marca
              usada por el Grupo Santander para los productos de retail. En
              virtud de ello, Santander Financing S.A.S., como entidad del Grupo
              Santander está autorizada para hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <strong>*** Matrícula Completa:</strong> el valor de la matrícula
              completa incluye Soat, impuestos, semaforización y derechos de
              tránsito para vehículos nuevos de Hyundai Tucson Select Mecánica
              año modelo 2025 que sean adquiridos en la red de concesionarios
              Hyundai a nivel nacional. La matrícula completa será otorgada por
              el concesionario Hyundai autorizado en el que realice la compra
              del vehículo. La matrícula no incluye los tramites de prendas ni
              gestor.
            </p>
            <p className="legal-texto">
              <strong>***Exención de Pico y Placa:</strong> Aplica únicamente
              para vehículos nuevos Hyundai Tucson Select Mecánica año modelo
              2025 que sean requieran la exención de pico y placa para movilidad
              en las ciudades de Cali y Bogotá.
            </p>
            <p className="legal-texto">
              <strong>***Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Creta &amp; Samsung
              </span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 14
              al 31 de julio de 2024.
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $107.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai Creta premium año modelo
              2025. Los precios en otras referencias podrán ser consultados
              directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">** Incluye TV de 50”:</span>
            </p>
            <p className="legal-texto">
              Por la compra de una Hyundai Creta Edición Samsung el cliente
              recibirá un televisor Samsung referencia UN50DU8200KXXL, el total
              de unidades disponibles a nivel nacional es de 100. La Ref.
              UN50DU8200KXXL es un producto Importado por Samsung Electronics
              Colombia S.A. Los productos publicados tienen 1 año de garantía
              directamente con el fabricante.
            </p>
            <p className="legal-texto">
              <strong>***Exención de Pico y Placa ó Bono de Matrícula:</strong>{" "}
              Aplica únicamente para vehículos nuevos Hyundai Creta Premium año
              modelo 2025 que requieran la exención de pico y placa para
              movilidad en las ciudades de Cali y Bogotá. El bono de matrícula
              deberá ser consultado directamente en el concesionario, los dos
              beneficios NO son acumulables.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 14
              al de 31 de julio de 2024.
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $97.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai Venue limited AT modelo
              2025 que sean financiados con el Banco Santander. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario. Santander Consumer es la marca usada
              por el Grupo Santander para los productos de retail. En virtud de
              ello, Santander Financing S.A.S., como entidad del Grupo Santander
              está autorizada para hacer uso de la misma.
            </p>
            <p className="legal-texto">
              <strong>**Matrícula Incluida:</strong> el valor de la matrícula
              incluye impuestos, semaforización y derechos de transito NO
              incluye el valor del SOAT.
            </p>
            <p className="legal-texto">
              <strong>***Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="24">
          <Accordion.Header>25. Acciones comerciales Agosto</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 5 al 31 de Agosto de 2024
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Kona Gasolina</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 5 al
              31 de agosto de 2024
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $113.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai KONA motor a gasolina
              versión Premium año modelo 2025. Los precios en otras referencias
              podrán ser consultados directamente con el asesor en el
              concesionario.
            </p>
            <p className="legal-texto">
              **Tasa fija 1.25% corresponde al plan financiero ofrecido por el
              banco Santander y aplica únicamente para vehículos nuevos de
              Hyundai KONA motor a gasolina versión premium año modelo 2025:
            </p>
            <p className="legal-texto">Plan: Tradicional</p>
            <p className="legal-texto">
              El plan de financiación anunciado aplica únicamente para vehículos
              nuevos de la marca HYUNDAI en la referencia KONA Gasolina version
              Premium año modelo 2025 Los accesorios, y colores de todas las
              piezas publicitarias hacen parte de la ambientación fotográfica.
              Por esta razón pueden no corresponder con las referencias y
              modelos comercializados en Colombia.
            </p>
            <p className="legal-texto">
              El plan de financiación podrá ser otorgado únicamente por
              entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo en
                  cuotas mensuales
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el cliente deberá pagar una cuota
                  inicial mínima del 30%
                </li>
                <li>
                  Se podrá incluir hasta un 10% en accesorios para el vehiculo
                  automotor, esto siempre y cuando estos sean facturados por
                  Automotor.Co y sean accesorios asegurables
                </li>
                <li>
                  La tasa de interés aplicable será fija del 1,25% para los 30
                  negocios desembolsados en las fechas de dicha acción
                  comercial. La tasa aplicable a cada solicitante podrá variar
                  de acuerdo con el perfil crediticio del solicitante
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores. En caso de que el solicitante financie la
                  contratación de alguna o todas las pólizas de seguros
                  mencionadas en el numeral (v) inmediatamente anterior a través
                  de Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, el valor de las cuotas mensual podrá
                  variar. Las condiciones de esta financiación serán informadas
                  de forma previa al solicitante
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              matrículas de la referencia KONA ICE del 1 de Agosto de 2024 al 31
              de Agosto de 2024 y desembolsados antes del 15 de septiembre
              2024.No será acumulable con otros descuentos o promociones
              vigentes. Ni Santander Financing S.A.S., ni sus entidades
              financieras aliadas, adquieren algún tipo de compromiso de
              recompra respecto del vehículo automotor a ser adquirido por el
              solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai HB20 desde $66.990.000 si financias con Banco Santander
              </span>
            </p>
            <p className="legal-texto">
              *Precio desde: El precio de venta publicado $66.990.000 incluye
              IVA y sólo solo aplica para los vehículos nuevos Hyundai HB20
              Hacthback mecánico año modelo 2024 que sean financiados con Banco
              Santander. Los precios en otras referencias podrán ser consultados
              directamente con el asesor en el concesionario "Santander Consumer
              es la marca usada por el Grupo Santander para los productos de
              retail. En virtud de ello, Santander Financing S.A.S., como
              entidad del Grupo Santander está autorizada para hacer uso de la
              misma".
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson precio desde $118.990.000, empieza a pagar en
                2025, exención de pico y placa, Matrícula Completa y Bono de
                Retoma
              </span>
            </p>
            <p className="legal-texto">
              *Precio desde: El precio de venta publicado desde $118.990.000
              incluye IVA y sólo solo aplica para los vehículos nuevos Hyundai
              Tucson Select Mecánica año modelo 2025 que sean financiados con
              Banco Santander. Los precios en otras referencias podrán ser
              consultados directamente con el asesor en el concesionario
            </p>
            <p className="legal-texto">
              <strong>**Empieza a pagar en 2025 </strong>corresponde al plan
              financiero ofrecido por el banco Santander y aplica únicamente
              para vehículos nuevos de Hyundai Tucson Select Mecánica año modelo
              2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Periodo de Gracia 12 meses
              </span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en la referencia
              Tucson Select MT año modelo 2025
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 90% del valor total del vehículo en
                  cuotas mensuales;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 10% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>
                  El plazo máximo de financiación será de hasta 72 meses,
                  incluyendo 12 meses iniciales de periodo de gracias a capital,
                  en los cuales el solicitante solo pagará intereses;
                </li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 5 al 31 de Agosto de2024, que se desembolsen
              antes del 15 de septiembre de 2024. No será acumulable con otros
              descuentos o promociones vigentes. Ni Santander Financing S.A.S.,
              ni sus entidades financieras aliadas, adquieren algún tipo de
              compromiso de recompra respecto del vehículo automotor a ser
              adquirido por el solicitante. "Santander Consumer es la marca
              usada por el Grupo Santander para los productos de retail. En
              virtud de ello, Santander Financing S.A.S., como entidad del Grupo
              Santander está autorizada para hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <strong>*** Matrícula Completa: </strong>el valor de la matrícula
              completa incluye Soat, impuestos, semaforización y derechos de
              tránsito para vehículos nuevos de Hyundai Tucson Select Mecánica
              año modelo 2025 que sean adquiridos en la red de concesionarios
              Hyundai a nivel nacional. La matrícula completa será otorgada por
              el concesionario Hyundai autorizado en el que realice la compra
              del vehículo. La matrícula no incluye los tramites de prendas ni
              gestor.
            </p>
            <p className="legal-texto">
              <strong>***Exención de Pico y Placa: </strong>Aplica únicamente
              para vehículos nuevos Hyundai Tucson Select Mecánica año modelo
              2025 que sean requieran la exención de pico y placa para movilidad
              en las ciudades de Cali y Bogotá.
            </p>
            <p className="legal-texto">
              <strong>***Bono de retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Creta precio desde
              </span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 5 al
              31 de Agosto de 2024
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $102.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai Creta premium año modelo
              2024. Los precios en otras referencias podrán ser consultados
              directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>**Bono de retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 5 al
              31 de Agosto de 2024
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $93.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai Venue limited AT modelo
              2025 que sean financiados con el Banco Santander. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario. Santander Consumer es la marca usada
              por el Grupo Santander para los productos de retail. En virtud de
              ello, Santander Financing S.A.S., como entidad del Grupo Santander
              está autorizada para hacer uso de la misma.
            </p>
            <p className="legal-texto">
              <strong>** Tasa de 0,99% </strong>corresponde al plan financiero
              ofrecido por el banco Santander y aplica únicamente para vehículos
              nuevos de Hyundai Venue limited AT modelo 2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">PLAN: TASA 0,99% 12 MESES</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en referencias
              seleccionadas.
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo;
                  donde pagará capital, intereses y seguro. Cuota inicial desde
                  el 30% sobre el valor total del vehículo, amortización por
                  tramos. El primer tramo podrá tener una duración máxima de 12
                  meses, y el segundo tramo podrá tener una duración máxima de
                  60 meses, para un total de hasta 72 meses.
                </li>
                <li>
                  La tasa inicial, para los primeros 12 meses del crédito será
                  del 0.99% mensual de acuerdo con perfil crediticio. Para los
                  meses restantes del crédito aplicará la tasa determinada de
                  acuerdo con el perfil crediticio y a las políticas de crédito.
                  En ambos casos la tasa de interés será fija una vez asignada a
                  cada solicitante, y la tasa aplicable a cada solicitante podrá
                  variar de acuerdo con el perfil crediticio del solicitante y
                  las políticas de crédito de Santander Financing S.A.S.
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses;</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 1 de Agosto de 2024 al 31 de Agosto de 2024, que
              se desembolsen antes del 15 de septiembre de 2024. No será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <strong>***Bono de retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="25">
          <Accordion.Header>
            26. TYC - Boletas Copa Libertadores
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Concurso Boletas CONMEBOL LIBERTADORES - HYUNDAI:
              </span>
            </p>
            <p className="legal-texto">
              La identidad de cada participante podrá ser corroborada y
              confirmada en cualquier etapa de la actividad. El participante
              deberá conocer a cabalidad la mecánica que se describe a
              continuación para participar. Los usuarios que participen aceptan
              sin ninguna modificación ni restricción todas las condiciones,
              términos y avisos contenidos en este reglamento para efectos del
              mismo. Si el usuario no está de acuerdo con las condiciones del
              reglamento o con la actividad como tal, no podrá, ni deberá
              participar en esta y está en pleno derecho de no hacerlo. Esta
              actividad de ninguna manera es patrocinada, aprobada o
              administrada por Instagram. La información que los participantes
              proporcionen será para el uso de la marca y no para Instagram. La
              información solo será utilizada para efectos de esta actividad de
              conformidad con los términos y condiciones del presente
              reglamento.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Mecánica de la actividad:</span>
            </p>
            <p className="legal-texto">
              El participante tendrá la oportunidad de participar por 6 boletas
              dobles de cortesía válida para ingresar al encuentro por Copa
              CONMEBOL Libertadores Junior vs Colo-Colo el 20 de Agosto de 2024
              (Bogotá - Colombia).
            </p>
            <p className="legal-texto">
              La vigencia del concurso será del viernes 16 de Agosto al Lunes 19
              de Agosto 2024 a las 11:00pm Total de boletas de cortesía que
              serán sorteadas, 6 pases dobles.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Descripción actividad:</span>
            </p>
            <p className="legal-texto">
              Para obtener el premio, las personas deberán responder
              correctamente las 3 trivias que se postearan en las historias de
              la cuenta de Instagram de Hyundai Colombia.
            </p>
            <p className="legal-texto">
              Las primeras 6 personas en responder correctamente las 3 trivias,
              serán las ganadoras para las boletas de encuentro.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">A tener en cuenta:</span>
            </p>
            <p className="legal-texto">
              <ul>
                <li>
                  Las personas que participen, deben tener sus perfiles abiertos
                  al público, para así poder corroborar su veracidad y
                  legalidad. Asimismo, seguir la cuenta @hyundai_col
                </li>
                <li>
                  Los ganadores deben residir en el territorio colombiano,
                  ciudad de Barranquilla.
                </li>
                <li>La persona que participe debe ser mayor de edad.</li>
                <li>Solo es válida un pase doble por persona.</li>
                <li>
                  La persona solo puede participar una vez durante toda la
                  dinámica.
                </li>
                <li>
                  El premio no es transferible ni redimible por dinero en
                  efectivo.
                </li>
                <li>
                  Fechas del desarrollo del concurso: viernes 16 de Agosto al
                  Lunes 19 hasta las 11:00 pm de Agosto 2024
                </li>
              </ul>
            </p>
            <p className="legal-texto">
              La entrega de las boletas se realizará el día indicado
              anteriormente. El premio se entregará digitalmente, lo cual será
              coordinado con el encargado de la marca Hyundai.
            </p>
            <p className="legal-texto">
              Todos los usuarios que participen estarán dispuestos a que la
              marca tome sus fotos y comentarios y los publique en las cuentas
              oficiales para incentivar a otros usuarios a participar.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Consideraciones:</span>
            </p>
            <p className="legal-texto">
              Los participantes deben ser personas naturales residentes en
              Colombia, no está permitida la participación de menores de edad.
            </p>
            <p className="legal-texto">
              Hyundai Colombia se reserva el derecho de publicar fotos o
              comentarios que atenten contra la integridad moral de los demás
              fans o de la marca.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Información imprecisa o falsa:
              </span>
            </p>
            <p className="legal-texto">
              En caso de identificar fraude por parte de alguno de los
              participantes, los organizadores podrán descalificarlo sin el
              deber de notificarle. De igual manera, si los organizadores
              detectan información falsa o imprecisa a su juicio, cualquier
              infracción que los confunda o que vaya en detrimento de la(s)
              marca(s), de la compañía, de sus empleados o accionistas, el
              participante será descalificado sin que exista el deber de
              notificarle.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Imagen:</span>
            </p>
            <p className="legal-texto">
              Los participantes autorizan que se utilicen las fotos y/o
              comentarios publicados en las redes sociales para que sean
              utilizadas exclusivamente en actividades de promoción, y en el
              desarrollo de la actividad. No obstante, no podrán los
              participantes seleccionados recibir ningún tipo de pago o
              indemnización adicional a los gastos establecidos para la presente
              actividad, por lo que queda claro que no habrá ningún tipo de
              reclamación posterior.
            </p>
            <p className="legal-texto">
              En caso de resultar necesario o a discreción de los organizadores,{" "}
              <strong>Hyundai Colombia </strong>se reserva el derecho de ampliar
              y/o aclarar el presente reglamento y/o cualquiera de los
              procedimientos aquí previstos.
            </p>
            <p className="legal-texto">
              No podrán participar en esta actividad los trabajadores de
              <strong>Hyundai Colombia</strong>, el personal contratado por
              servicios, el personal contratado por outsourcing de
              <strong>Hyundai Colombia</strong>, ni sus cónyuges, ni compañeros
              permanentes; ni sus padres, hijos, y/o hermanos si viven en el
              mismo inmueble; ni los empleados de las agencias que tengan
              contrato con<strong> Hyundai Colombia</strong>.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Protección de Datos personales y envío de publicidad:
              </span>
            </p>
            <p className="legal-texto">
              El cliente declara que la información suministrada por él,
              incluyendo la relacionada con sus datos personales, es cierta,
              fidedigna y actualizada.
            </p>
            <p className="legal-texto">
              El cliente presta su consentimiento libre, expreso e informado
              para que la información personal que ha ingresado sea almacenada,
              recopilada o utilizada por HC directamente o a través de terceros,
              con fines de comunicación, envío de información y/o para el
              mercadeo de sus productos e investigación de preferencias de
              compra.
            </p>
            <p className="legal-texto">
              La información suministrada será objeto de protección mediante el
              uso de tecnologías y procedimientos de seguridad, evitando el
              acceso, revelación y usos no autorizados. El cliente autoriza la
              transmisión y/o transferencia de datos personales a aliados
              nacionales e internacionales de HC.
            </p>
            <p className="legal-texto">
              En virtud de lo estipulado en la Ley 1581 de 2012, si el cliente
              desea darse de baja de la actividad, solicitar alguna
              modificación, corrección, actualización o supresión de todos los
              datos personales que nos ha proporcionado, dejar de recibir
              información comercial, así como contactarse con nosotros por
              cualquier otra causa, puede enviar un correo electrónico:
              habeasdata@skberge.co
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="26">
          <Accordion.Header>27. Campaña 96 horas Hyundai</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai HB20 y Tucson</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 21
              al 24 de agosto de 2024
            </p>
            <p className="legal-texto">
              <strong>*Plan Retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <strong>*El precio de venta publicado $66.990.000 </strong>incluye
              IVA y sólo solo aplica para los vehículos nuevos
              <strong>Hyundai HB20 </strong>Hacthback mecánico año modelo 2024
              que sean financiados con Banco Santander.
            </p>
            <p className="legal-texto">
              <strong>*El precio de venta publicado desde $118.990.000</strong>{" "}
              incluye IVA y sólo solo aplica para los vehículos nuevos Hyundai
              Tucson Select Mecánica año modelo 2025 que sean financiados con
              Banco Santander. Los precios en otras referencias podrán ser
              consultados directamente con el asesor en el concesionario.
              Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de esta.
            </p>
            <p className="legal-texto">
              <strong>*Diagnóstico Gratis y descuento en repuestos: </strong>
              Sólo aplica para vehículos marca Hyundai que ingresen al taller de
              servicio entre el 21 y el 24 de Agosto y los descuentos podrán
              redimirse hasta el 25 de Septiembre. Para mayor información
              consulta www.hyundaicolombia.co
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Venue, Creta y Kona
              </span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta válida del 21
              al 24 de agosto de 2024
            </p>
            <p className="legal-texto">
              <strong>*Plan Retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <strong>*El precio de venta publicado $113.990.000 </strong>
              incluye IVA y sólo aplica para los vehículos nuevos Hyundai KONA
              motor a gasolina versión Premium año modelo 2025. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>*El precio de venta publicado $93.990.000 </strong>incluye
              IVA y sólo aplica para los vehículos nuevos Hyundai
              <strong>Venue limited AT</strong>
              modelo 2025 que sean financiados con el Banco Santander. Los
              precios en otras referencias podrán ser consultados directamente
              con el asesor en el concesionario. Santander Consumer es la marca
              usada por el Grupo Santander para los productos de retail. En
              virtud de ello, Santander Financing S.A.S., como entidad del Grupo
              Santander está autorizada para hacer uso de la misma.
            </p>
            <p className="legal-texto">
              <strong>*El precio de venta publicado $102.990.000 </strong>
              incluye IVA y sólo aplica para los vehículos nuevos Hyundai
              <strong> Creta premium </strong>año modelo 2024. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>*Diagnóstico Gratis y descuento en repuestos: </strong>
              Sólo aplica para vehículos marca Hyundai que ingresen al taller de
              servicio entre el 21 y el 24 de Agosto y los descuentos podrán
              redimirse hasta el 25 de Septiembre. Para mayor información
              consulta www.hyundaicolombia.co
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Diagnóstico Gratis y descuento en repuestos:
              </span>
            </p>
            <p className="legal-texto">
              Sólo aplica para vehículos marca Hyundai que ingresen al taller de
              servicio entre el 21 y el 24 de Agosto y los descuentos podrán
              redimirse hasta el 25 de Septiembre. Para mayor información
              consulta www.hyundaicolombia.co
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="27">
          <Accordion.Header>
            28. Acciones comerciales Septiembre
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 5 de Agosto de 2024 al 30 de septiembre de 2024
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Kona Gasolina</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta valida del 5 de
              agosto al 30 de septiembre de 2024
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $109.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai KONA motor a gasolina
              versión Premium año modelo 2025. Los precios en otras referencias
              podrán ser consultados directamente con el asesor en el
              concesionario.
            </p>
            <p className="legal-texto">
              **Tasa fija 1.25% corresponde al plan financiero ofrecido por el
              banco Santander y aplica únicamente para vehículos nuevos de
              Hyundai KONA motor a gasolina versión premium año modelo 2025:
            </p>
            <p className="legal-texto">Plan: Tradicional</p>
            <p className="legal-texto">
              El plan de financiación anunciado aplica únicamente para vehículos
              nuevos de la marca HYUNDAI en la referencia KONA Gasolina versión
              Premium año modelo 2025 Los accesorios, y colores de todas las
              piezas publicitarias hacen parte de la ambientación fotográfica.
              Por esta razón pueden no corresponder con las referencias y
              modelos comercializados en Colombia.
            </p>
            <p className="legal-texto">
              El plan de financiación podrá ser otorgado únicamente por
              entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo en
                  cuotas mensuales
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el cliente deberá pagar una cuota
                  inicial mínima del 30%
                </li>
                <li>
                  Se podrá incluir hasta un 10% en accesorios para el vehiculo
                  automotor, esto siempre y cuando estos sean facturados por
                  Automotor.Co y sean accesorios asegurables
                </li>
                <li>
                  La tasa de interés aplicable será fija del 1,25% para los 30
                  negocios desembolsados en las fechas de dicha acción
                  comercial. La tasa aplicable a cada solicitante podrá variar
                  de acuerdo con el perfil crediticio del solicitante
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores. En caso de que el solicitante financie la
                  contratación de alguna o todas las pólizas de seguros
                  mencionadas en el numeral (v) inmediatamente anterior a través
                  de Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, el valor de las cuotas mensual podrá
                  variar. Las condiciones de esta financiación serán informadas
                  de forma previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              matrículas de la referencia KONA ICE del 5 de agosto de 2024 al 30
              de septiembre de 2024 y desembolsados antes del 15 de octubre
              2024. No será acumulable con otros descuentos o promociones
              vigentes. Ni Santander Financing S.A.S., ni sus entidades
              financieras aliadas, adquieren algún tipo de compromiso de
              recompra respecto del vehículo automotor a ser adquirido por el
              solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai HB20 desde $66.990.000 si financias con Banco Santander
              </span>
            </p>
            <p className="legal-texto">
              *Precio desde: El precio de venta publicado $66.990.000 incluye
              IVA y sólo solo aplica para los vehículos nuevos Hyundai HB20
              Hacthback mecánico año modelo 2024 que sean financiados con Banco
              Santander. Los precios en otras referencias podrán ser consultados
              directamente con el asesor en el concesionario "Santander Consumer
              es la marca usada por el Grupo Santander para los productos de
              retail. En virtud de ello, Santander Financing S.A.S., como
              entidad del Grupo Santander está autorizada para hacer uso de la
              misma".
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Tucson precio desde $118.990.000, empieza a pagar en
                2025, exención de pico y placa, Matrícula Completa y Bono de
                Retoma
              </span>
            </p>
            <p className="legal-texto">
              *Precio desde: El precio de venta publicado desde $118.990.000
              incluye IVA y sólo solo aplica para los vehículos nuevos Hyundai
              Tucson Select Mecánica año modelo 2025 que sean financiados con
              Banco Santander. Los precios en otras referencias podrán ser
              consultados directamente con el asesor en el concesionario
            </p>
            <p className="legal-texto">
              <strong>**Empieza a pagar en 2025 </strong>corresponde al plan
              financiero ofrecido por el banco Santander y aplica únicamente
              para vehículos nuevos de Hyundai Tucson Select Mecánica año modelo
              2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Periodo de Gracia 12 meses
              </span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en la referencia
              Tucson Select MT año modelo 2025
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 90% del valor total del vehículo en
                  cuotas mensuales;
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 10% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>
                  El plazo máximo de financiación será de hasta 72 meses,
                  incluyendo 12 meses iniciales de periodo de gracias a capital,
                  en los cuales el solicitante solo pagará intereses;
                </li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 5 de Agosto de 2024 al 30 de septiembre, que se
              desembolsen antes del 15 de octubre de 2024. No será acumulable
              con otros descuentos o promociones vigentes. Ni Santander
              Financing S.A.S., ni sus entidades financieras aliadas, adquieren
              algún tipo de compromiso de recompra respecto del vehículo
              automotor a ser adquirido por el solicitante. "Santander Consumer
              es la marca usada por el Grupo Santander para los productos de
              retail. En virtud de ello, Santander Financing S.A.S., como
              entidad del Grupo Santander está autorizada para hacer uso de la
              misma".
            </p>
            <p className="legal-texto">
              <strong>*** Matrícula Completa: </strong>el valor de la matrícula
              completa incluye Soat, impuestos, semaforización y derechos de
              tránsito para vehículos nuevos de Hyundai Tucson Select Mecánica
              año modelo 2025 que sean adquiridos en la red de concesionarios
              Hyundai a nivel nacional. La matrícula completa será otorgada por
              el concesionario Hyundai autorizado en el que realice la compra
              del vehículo. La matrícula no incluye los tramites de prendas ni
              gestor.
            </p>
            <p className="legal-texto">
              <strong>***Exención de Pico y Placa: </strong>Aplica únicamente
              para vehículos nuevos Hyundai Tucson Select Mecánica año modelo
              2025 que sean requieran la exención de pico y placa para movilidad
              en las ciudades de Cali y Bogotá.
            </p>
            <p className="legal-texto">
              <strong>***Bono de retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Hyundai Creta precio desde
              </span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta valida del 5 de
              Agosto de 2024 al 30 de septiembre de 2024
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $102.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai Creta premium año modelo
              2024. Los precios en otras referencias podrán ser consultados
              directamente con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>**Bono de retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta valida del 5 de
              Agosto de 2024 al 30 de septiembre de 2024
            </p>
            <p className="legal-texto">
              *El precio de venta publicado $93.990.000 incluye IVA y sólo
              aplica para los vehículos nuevos Hyundai Venue limited AT modelo
              2025 que sean financiados con el Banco Santander. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario. Santander Consumer es la marca usada
              por el Grupo Santander para los productos de retail. En virtud de
              ello, Santander Financing S.A.S., como entidad del Grupo Santander
              está autorizada para hacer uso de la misma.
            </p>
            <p className="legal-texto">
              <strong>** Tasa de 0,99% </strong>corresponde al plan financiero
              ofrecido por el banco Santander y aplica únicamente para vehículos
              nuevos de Hyundai Venue limited AT modelo 2025:
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">PLAN: TASA 0,99% 12 MESES</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en referencias
              seleccionadas.
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo;
                  donde pagará capital, intereses y seguro. Cuota inicial desde
                  el 30% sobre el valor total del vehículo, amortización por
                  tramos. El primer tramo podrá tener una duración máxima de 12
                  meses, y el segundo tramo podrá tener una duración máxima de
                  60 meses, para un total de hasta 72 meses.
                </li>
                <li>
                  La tasa inicial, para los primeros 12 meses del crédito será
                  del 0.99% mensual de acuerdo con perfil crediticio. Para los
                  meses restantes del crédito aplicará la tasa determinada de
                  acuerdo con el perfil crediticio y a las políticas de crédito.
                  En ambos casos la tasa de interés será fija una vez asignada a
                  cada solicitante, y la tasa aplicable a cada solicitante podrá
                  variar de acuerdo con el perfil crediticio del solicitante y
                  las políticas de crédito de Santander Financing S.A.S.
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses;</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del del 5 de agosto de 2024 al 30 de septiembre de
              2024, que se desembolsen antes del 15 de octubre de 2024. No será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <strong>***Bono de retoma: </strong>Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="28">
          <Accordion.Header>29. Acciones comerciales Octubre</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 1 al 31 de Octubre de 2024
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Kona</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta valida del 1 al
              31 de octubre de 2024
            </p>
            <p className="legal-texto">
              El precio de venta publicado <strong>$109.990.000</strong> incluye
              IVA y sólo aplica para los vehículos nuevos Hyundai{" "}
              <strong>KONA Premium gasolina año modelo 2025</strong>. Los
              precios en otras referencias podrán ser consultados directamente
              con el asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Venue</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta valida del del
              1 al 31 de octubre de 2024
            </p>
            <p className="legal-texto">
              *El precio de venta publicado <strong>$89.990.000</strong> incluye
              IVA y sólo aplica para los vehículos nuevos Hyundai{" "}
              <strong>
                Venue limited AT modelo 2025 que sean financiados con el Banco
                Santander.
              </strong>
              Los precios en otras referencias podrán ser consultados
              directamente con el asesor en el concesionario. Santander Consumer
              es la marca usada por el Grupo Santander para los productos de
              retail. En virtud de ello, Santander Financing S.A.S., como
              entidad del Grupo Santander está autorizada para hacer uso de la
              misma.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Tucson</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta valida del 1 al
              31 de octubre de 2024
            </p>
            <p className="legal-texto">
              El precio de venta publicado desde <strong>$125.990.000</strong>{" "}
              incluye IVA y sólo solo aplica para los vehículos nuevos Hyundai{" "}
              <strong>TUCSON Attraction año modelo 2025</strong>. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Hyundai Creta</span>
            </p>
            <p className="legal-texto">
              Imágenes de referencia. Diseños, rines y acabados o accesorios
              pueden variar y/o tener un costo adicional. Oferta valida del del
              1 al 31 de octubre de 2024
            </p>
            <p className="legal-texto">
              El precio de venta publicado <strong>$102.990.000</strong> incluye
              IVA y sólo aplica para los vehículos nuevos Hyundai{" "}
              <strong>CRETA premium año modelo 2024</strong>. Los precios en
              otras referencias podrán ser consultados directamente con el
              asesor en el concesionario.
            </p>
            <p className="legal-texto">
              <strong>Bono de retoma:</strong> Se otorgará a discreción de la
              inspección realizada al vehículo usado directamente por el asesor
              del concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Planes con Banco Santander
              </span>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Plan: Tasa 0,99% 6 meses</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en referencias
              seleccionadas: <strong>TUCSON Select MT año modelo 2025</strong>
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo;
                  donde pagará capital, intereses y seguro. Cuota inicial desde
                  el 30% sobre el valor total del vehículo, amortización por
                  tramos. El primer tramo podrá tener una duración máxima de 6
                  meses, y el segundo tramo podrá tener una duración máxima de
                  66 meses, para un total de hasta 72 meses.
                </li>
                <li>
                  La tasa inicial, para los primeros 6 meses del crédito será
                  del 0.99% mensual de acuerdo con perfil crediticio. Para los
                  meses restantes del crédito aplicará la tasa determinada de
                  acuerdo con el perfil crediticio y a las políticas de crédito.
                  En ambos casos la tasa de interés será fija una vez asignada a
                  cada solicitante, y la tasa aplicable a cada solicitante podrá
                  variar de acuerdo con el perfil crediticio del solicitante y
                  las políticas de crédito de Santander Financing S.A.S.
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses;</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 1 de Octubre de 2024 al 31 de Octubre de 2024,
              que se desembolsen antes del 15 de Noviembre de 2024. No será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">Plan: Tasa 0,99% 12 meses</span>
            </p>
            <p className="legal-texto">
              El Plan de financiación anunciado aplica únicamente para los
              siguientes vehículos nuevos de la marca HYUNDAI en referencias
              seleccionadas:{" "}
              <strong>
                HB20S Advance MT año modelo 2024, TUCSON Select MT año modelo
                2025
              </strong>
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo;
                  donde pagará capital, intereses y seguro. Cuota inicial desde
                  el 30% sobre el valor total del vehículo, amortización por
                  tramos. El primer tramo podrá tener una duración máxima de 12
                  meses, y el segundo tramo podrá tener una duración máxima de
                  60 meses, para un total de hasta 72 meses.
                </li>
                <li>
                  La tasa inicial, para los primeros 12 meses del crédito será
                  del 0.99% mensual de acuerdo con perfil crediticio. Para los
                  meses restantes del crédito aplicará la tasa determinada de
                  acuerdo con el perfil crediticio y a las políticas de crédito.
                  En ambos casos la tasa de interés será fija una vez asignada a
                  cada solicitante, y la tasa aplicable a cada solicitante podrá
                  variar de acuerdo con el perfil crediticio del solicitante y
                  las políticas de crédito de Santander Financing S.A.S.
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses;</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 1 de Octubre de 2024 al 31 de Octubre de 2024,
              que se desembolsen antes del 15 de Noviembre de 2024. No será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante,
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Plan: 14 cuotas a 72 Meses
              </span>
            </p>
            <p className="legal-texto">
              El plan de financiación anunciado aplica únicamente para vehículos
              nuevos de HYUNDAI
            </p>
            <p className="legal-texto">
              Los accesorios, y colores de todas las piezas publicitarias hacen
              parte de la ambientación fotográfica. Por esta razón pueden no
              corresponder con las referencias y modelos comercializados en
              Colombia. El plan de financiación podrá ser otorgado únicamente
              por entidades financieras aliadas a Santander Financing S.A.S. y
              estará sujeto a las siguientes condiciones:
            </p>
            <p className="legal-texto">
              <ol type="i">
                <li>
                  Se financiará máximo el 70% del valor total del vehículo en
                  cuotas mensuales y 2 cuotas extras en junio y diciembre; o
                  Julio y Enero durante toda su vigencia.
                </li>
                <li>
                  Adicional a las cuotas establecidas en el numeral (i)
                  inmediatamente anterior, el solicitante deberá pagar una cuota
                  inicial por un valor mínimo equivalente al 30% del valor total
                  del vehículo;
                </li>
                <li>
                  La tasa de interés aplicable será fija una vez acordada, y la
                  tasa aplicable a cada solicitante podrá variar de acuerdo con
                  el perfil crediticio del solicitante;
                </li>
                <li>El plazo máximo de financiación será de hasta 72 meses</li>
                <li>
                  El solicitante deberá contratar, de forma previa al primer
                  desembolso, las siguientes pólizas de seguro: a) Póliza de
                  seguro todo riesgo que cubra el vehículo financiado; b) póliza
                  de seguro de vida del solicitante; y c) póliza de seguro de
                  protección financiera. El solicitante podrá, a su entera
                  discreción, escoger los seguros necesarios a través de
                  Santander Financing S.A.S., con alguna de las entidades
                  financieras aliadas, o con cualquier otra entidad financiera a
                  su entera discreción. Los costos asociados a la contratación
                  de las pólizas de seguro detalladas en el presente numeral, no
                  se incluyen en las cuotas establecidas en el numeral (i) y
                  (ii) anteriores.
                </li>
                <li>
                  En caso de que el solicitante financie la contratación de
                  alguna o todas las pólizas de seguros mencionadas en el
                  numeral (v) inmediatamente anterior a través de Santander
                  Financing S.A.S., con alguna de las entidades financieras
                  aliadas, el valor de las cuotas mensual podrá variar. Las
                  condiciones de esta financiación serán informadas de forma
                  previa al solicitante.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              El otorgamiento del plan de financiación está sujeto al
              cumplimiento de las políticas de crédito de las entidades
              financieras aliadas de Santander Financing S.A.S., y el perfil
              crediticio del solicitante. Esta campaña será válida para
              radicaciones del 1 de Octubre de 2024 al 31 de Octubre de 2024,
              que se desembolsen antes del 15 de Noviembre de 2024. No será
              acumulable con otros descuentos o promociones vigentes. Ni
              Santander Financing S.A.S., ni sus entidades financieras aliadas,
              adquieren algún tipo de compromiso de recompra respecto del
              vehículo automotor a ser adquirido por el solicitante.
            </p>
            <p className="legal-texto">
              "Santander Consumer es la marca usada por el Grupo Santander para
              los productos de retail. En virtud de ello, Santander Financing
              S.A.S., como entidad del Grupo Santander está autorizada para
              hacer uso de la misma".
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="29">
          <Accordion.Header>
            30. Temporada Blue Centro Comercial Fontanar
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                Vigencia del 3 al 13 de Octubre de 2024
              </span>
            </p>
            <p className="legal-texto">
              A continuación se describen las condiciones de la actividad a
              realizar en la Temporada Blue de Hyundai en el Centro Comercial
              Fontanar:
            </p>
            <p className="legal-texto">
              <strong>
                *Diagnóstico 360 ° gratuito y descuentos en Servicio:{" "}
              </strong>
              El diagnóstico sin costo consiste en una revisión multipunto del
              vehículo y escaneo con la herramienta de marca, para conocer
              códigos de falla y recomendar ajustes o reparaciones en el
              vehículo Hyundai; sobre los trabajos que se requiera ejecutar en
              éstos vehículos el cliente tiene derecho a un bono de descuento
              por hasta el <strong>25%</strong> en los repuestos que se
              requieran para la reparación, siempre y cuando esta se ejecute en
              nuestra red de concesionarios oficiales y se puede redimir hasta
              <strong>el 31 de diciembre</strong> de 2024.
            </p>
            <p className="legal-texto">
              <strong>*Purificación de la cabina sin costo: </strong> Se ofrece
              servicio de purificación de ductos de aire acondicionado, en el
              momento de la inspección.
            </p>
            <p className="legal-texto">
              <strong>*Precios imperdibles para tu nuevo Hyundai: </strong>Los
              precios y disponibilidad de los vehículos nuevos Hyundai exhibidos
              en la actividad podrán ser consultados directamente con los
              asesores comerciales de la marca en el Centro Comercial Fontanar.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="30">
          <Accordion.Header>
            31. Campaña Deducibles Aseguradoras
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Promoción válida a nivel nacional para vehículos de la marca
              Hyundai distribuida por Hyundai Colombia S.A.S. En caso de que la
              póliza de seguros del propietario sea afectada por la ocurrencia
              de un siniestro, Hyundai Colombia S.A.S. entregará al propietario
              un bono del 30% (antes de IVA) sobre el valor del deducible pagado
              por el propietario por la póliza de seguro afectada, para que sea
              utilizado en futuros servicios y/o accesorios de posventa en la
              red de concesionarios autorizados por Hyundai Colombia S.A.S. a
              nivel nacional (esta podrá ser consultada en la siguiente página
              web
              <a href="https://www.hyundaicolombia.co/concesionario">
                https://www.hyundaicolombia.co/concesionario
              </a>
              , siempre que las reparaciones correspondientes se hayan realizado
              en la red de talleres autorizados por Hyundai Colombia S.A.S.
              (estos podrán ser consultados en la siguiente página web
              <a href="https://www.hyundaicolombia.co/concesionario">
                https://www.hyundaicolombia.co/concesionario
              </a>
            </p>
            <p className="legal-texto">
              El bono podrá ser hasta de COP $400.000 por [afectación a la
              póliza de seguro / por vehículo y por una única vez]. Esta oferta
              es válida a partir del 1 de octubre de 2024 hasta el 31 de
              diciembre de 2024. Cupos limitados. Vigencia del bono: una vez sea
              recibido el bono, el cliente tendrá 6 meses para redimirlo.
              Recuerde exigir a su aseguradora que su vehículo sea reparado en
              la red de talleres autorizados por Hyundai Colombia S.A.S.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AccordionComponent;
