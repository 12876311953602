import React, { useState } from "react";
import MenuLayouts from "../layouts/menu.layout";
import BtnFloatingLayout from "../layouts/btn_flotante.layout";
import FooterLayouts from "../layouts/footer.layout";
import BannerComponent from "../components/politicas_calidad/banner.component";
import TitleComponent from "../components/politicas_calidad/title.component";
import DescriptionComponent from "../components/politicas_calidad/description.component";

const PoliticasCalidadPage: React.FC = () => {
  const [visibleHeight, setVisibleHeight] = useState(0);

  return (
    <>
      <MenuLayouts className="home-page" active=""  setVisibleHeight={setVisibleHeight} visibleHeight={visibleHeight} />
      <div className="home-page">
        <div className="home-page-mascara"></div>
        <BannerComponent />
        <TitleComponent />
        <DescriptionComponent />
      </div>
      <BtnFloatingLayout />
      <FooterLayouts />
    </>
  );
};

export default PoliticasCalidadPage;
