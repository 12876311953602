/*eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import MenuLayouts from "../layouts/menu.layout";
import FooterLayouts from "../layouts/footer.layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BtnFloatingLayout from "../layouts/btn_flotante.layout";
import MenuPosventaComponent from "../components/posventa/menu-posventa.component";

import BannerGarantiaComponent from "../components/posventa/garantia/banner.component";
import GarantiaComponent from "../components/posventa/garantia/garantia.component";
import ManualesComponent from "../components/posventa/garantia/manuales.component";

import BannerComponent from "../components/posventa/servicios/banner.component";
import PromesaComponent from "../components/posventa/servicios/promesa.component";
import ServiciosComponent from "../components/posventa/servicios/servicios.component";

import BannerCampaniaComponent from "../components/posventa/campania/banner.component";
import LicenseCardComponent from "../components/posventa/campania/license.component";
import CampaniasComponent from "../components/posventa/campania/campania.component";
import BannerAsistenciaComponent from "../components/posventa/asistencia/banner.component";
import AsistenciaComponent from "../components/posventa/asistencia/asistencia.component";
import ClausulasComponent from "../components/posventa/asistencia/clausulas.component";
import ComuniqueseComponent from "../components/posventa/asistencia/comuniquese.component";
import BannerMantenimientoComponent from "../components/posventa/mantenimiento/banner.component";
import MaintenanceTable from "../components/posventa/mantenimiento/tabla.component";
import CategoriasComponent from "../components/posventa/mantenimiento/categorias.component";
import BannerRepuestosComponent from "../components/posventa/repuestos/banner.component";
import CatalogoComponent from "../components/posventa/repuestos/catalogo.component";
import GaleriaComponent from "../components/posventa/repuestos/galeria.component";
import FileLoader from "../components/posventa/campania/fileLoader.component";
import * as XLSX from "xlsx";
import ModalComponent from "../components/posventa/campania/modal.component";
import { PosventaAll } from "../components/conexion/posventa.conexion";
import BannerGeneralComponent from "../components/posventa/banner.component";

type RowData = (string | number)[]; // Tipo para las filas del archivo

const PosventaPage: React.FC = () => {
  const navigate = useNavigate();
  const { servicio } = useParams<{ servicio?: string }>();

  const [data, setData] = useState<RowData[]>([]); // Almacenar los datos del archivo
  const [selectedMenu, setSelectedMenu] = useState("diseño");
  const [loading, setLoading] = useState(false); //true // Nuevo estado de carga
  const [visibleHeight, setVisibleHeight] = useState(0);
  const [searchString, setSearchString] = useState<string>(""); // Valor de búsqueda
  const [results, setResults] = useState<string[]>([]); // Lista de resultados
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataPosventa, setDataPosventa] = useState([]);
  const [services, setServices] = useState([]);
  const [warranty, setWarranty] = useState([]);
  const [warranty_files, setWarranty_files] = useState([]);
  const [active_campaigns, setActive_campaigns] = useState([]);
  const [assistances, setAssistances] = useState([]);
  const [assistancesContact, setAssistancesContact] = useState([]);
  const [clauses, setClauses] = useState([]);
  const [maintanance, setMaintanance] = useState([]);
  const [genuine_parts, setGenuine_parts] = useState([]);

  useEffect(() => {
    PosventaAll().then(function (res: any) {
      setDataPosventa(res?.data?.data[0])
      setServices(res?.data?.data[0]?.services)
      setWarranty(res?.data?.data[0]?.warranty)
      setWarranty_files(res?.data?.data[0]?.warranty_files)
      setActive_campaigns(res?.data?.data[0]?.active_campaigns)
      setAssistances(res?.data?.data[0]?.assistances)
      setAssistancesContact(res?.data?.data[0]?.contact)
      setClauses(res?.data?.data[0]?.clauses)
      setMaintanance(res?.data?.data[0]?.maintanance)
      setGenuine_parts(res?.data?.data[0]?.genuine_parts)
    })
  }, [])

  // Función para manejar la búsqueda
  const handleSearch = (searchString: string) => {
    setSearchString(searchString);

    // Buscar en los datos cargados
    const foundValues = findMatchingValues(data, searchString);

    setResults(foundValues);
    handleOpenModal();
  };

  const findMatchingValues = (
    data: RowData[],
    searchString: string
  ): string[] => {
    const matches: string[] = [];

    // Recorrer todas las filas y verificar si la primera columna coincide
    for (let row of data) {
      if (row[0] === searchString) {
        matches.push(
          row[2]
            ? row[2].toString()
            : "Valor no encontrado en la tercera columna"
        );
      }
    }

    // Si no se encuentran coincidencias, devolver un mensaje de no encontrado
    if (matches.length === 0) {
      return ["No se encontró el valor en la primera columna"];
    }

    return matches;
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  // Renderizar condicionalmente basado en el estado de carga
  if (loading) {
    return <div></div>; // Puedes usar un spinner o un mensaje más estilizado
  }

  return (
    <>
      <MenuLayouts
        className="home-page"
        active="posventa"
        setVisibleHeight={setVisibleHeight}
        visibleHeight={visibleHeight}
      />
      <div className="home-page">
        <div className="home-page-mascara"></div>
        <MenuPosventaComponent
          selectedMenu={selectedMenu}
          navigate={navigate}
          setSelectedMenu={setSelectedMenu}
          visibleHeight={visibleHeight}
          menus={{
            servicios_exclusivos: true,
            garantia: true,
            campanias: true,
            asistencia: true,
            mantenimiento: true,
            repuestos_genuinos: true,
          }}
        />
        {servicio === "servicios-exclusivos" || servicio === undefined ? (
          <>
            <BannerGeneralComponent title={"Servicios exclusivos"} banner={""} />
            <PromesaComponent />
            <ServiciosComponent services={services} />{" "}
          </>
        ) : servicio === "garantia" ? (
          <>
            <BannerGeneralComponent title={"Garantía"} banner={""} />
            <GarantiaComponent warranty={warranty} />
            <ManualesComponent warranty_files={warranty_files} />
          </>
        ) : servicio === "campana" ? (
          <>
            <BannerGeneralComponent title={"Campañas de seguridad en vehículos"} banner={""} />
            <LicenseCardComponent onSearch={handleSearch} />
            <CampaniasComponent active_campaigns={active_campaigns} />
            <FileLoader
              url="https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/strapi/CAMPANAS/campanas.xlsx" // URL del archivo
              onDataLoaded={setData} // Pasar los datos cargados al estado
            />
            <ModalComponent isOpen={isModalOpen} onClose={handleCloseModal}>
              <div className="dolty-modal-container">
                <div className="img-dolty-modal-container">
                  {results[0] ===
                  "No se encontró el valor en la primera columna"
                    ? "No hay campañas disponibles"
                    : "¡Campaña disponible!"}
                </div>
                {results[0] ===
                "No se encontró el valor en la primera columna" ? (
                  <div className="txt-dolty-modal-container">
                    <div className="subtitle-txt-campana-modal-container">
                      El número ingresado no está asociado a campañas en este
                      momento.
                    </div>{" "}
                  </div>
                ) : (
                  <div className="txt-dolty-modal-container">
                    <div className="subtitle-txt-campana-modal-container">
                      El número ingresado forma parte de la campaña:
                    </div>
                    <ul>
                      {results?.map((item: any, index: number) => (
                        <li
                          key={index}
                          className="title-txt-campana-modal-container"
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                    <div className="description-txt-campana-modal-container">
                      Por favor acérquese a su concesionario de confianza más
                      cercano.
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button onClick={handleCloseModal} className="close-button">
                  Cerrar
                </button>
                {results[0] ===
                "No se encontró el valor en la primera columna" ? null : (
                  <button onClick={handleCloseModal} className="agendar-button">
                    Agendar Cita taller
                  </button>
                )}
              </div>
            </ModalComponent>
          </>
        ) : servicio === "asistencia" ? (
          <>
            <BannerGeneralComponent title={"Asistencia"} banner={""} />
            <AsistenciaComponent assistances={assistances} />
            <ComuniqueseComponent assistancesContact={assistancesContact} />
            <ClausulasComponent clauses={clauses} />
          </>
        ) : servicio === "mantenimiento" ? (
          <>
            <BannerGeneralComponent title={"Mantenimiento"} banner={""} />
            <MaintenanceTable />
            <CategoriasComponent maintanance={maintanance} />
          </>
        ) : servicio === "repuestos-genuinos" ? (
          <>
            <BannerGeneralComponent title={"Repuestos genuinos"} banner={""} />
            <CatalogoComponent />
            <GaleriaComponent genuine_parts={genuine_parts} />
          </>
        ) : null}
      </div>
      <BtnFloatingLayout />
      <FooterLayouts />
    </>
  );
};

export default PosventaPage;
