/*eslint-disable */
import React from "react";


declare const window: any;
const CatalogoComponent: React.FC = () => {
  return (
    <div className="catalogo-posventa-repuestos-container">
      <div className="title-catalogo-posventa-repuestos-container">Busca y adquiere repuestos genuinos para tu Hyundai</div>
      <div className="subtitle-catalogo-posventa-repuestos-container">A través de nuestro distribuidor autorizado</div>
      <div className="btn-catalogo-posventa-repuestos-container">Ver catálogo</div>
      
    </div>
  );
};

export default CatalogoComponent;
