import React from "react";

/*eslint-disable */

declare const window: any;
const DescriptionComponent: React.FC = () => {
  return (
    <div className="container w-80">
      <div className="row">
        <div className="col-md-12">
          <p className="legal-texto">
            Cuando accedes a nuestro sitio web podemos recopilar datos
            personales tales como tu dirección IP, ubicación geográfica y
            comportamiento de navegación. Esta recopilación se realiza con el
            fin de mejorar la experiencia de nuestros usuarios, personalizar tu
            contenido y brindar servicios más relevantes a tu búsqueda.
          </p>
          <p className="legal-texto">
            Cuando accedes a nuestro sitio web podemos recopilar datos
            personales tales como tu dirección IP, ubicación geográfica y
            comportamiento de navegación. Esta recopilación se realiza con el
            fin de mejorar la experiencia de nuestros usuarios, personalizar tu
            contenido y brindar servicios más relevantes a tu búsqueda.
          </p>
          <p className="legal-texto">
            Cuando entras en este sitio web, has sido notificado a través de un
            aviso o alerta acerca del uso de cookies. Si decides continuar
            navegando en él, estás aceptando la instalación de estas cookies en
            tu dispositivo u ordenador.
          </p>
          <p className="legal-texto">
            Nosotros como Astara actuamos siempre cumpliendo con las
            disposiciones de la Ley 1581 de 2012, Ley Estatutaria de Protección
            de datos personales en Colombia, bajo los principios de legalidad,
            finalidad, transparencia y confidencialidad.
          </p>
          <p className="legal-texto">
            <span className="legal-bold">¿Qué son las cookies? </span>
          </p>
          <p className="legal-texto">
            Las cookies son unidades de información que se descargan y almacenan
            temporalmente en el ordenador o dispositivo del usuario al momento
            de interactuar con una página. Las cookies son instaladas durante la
            navegación, bien sea por los sitios que visita el usuario con los
            que se relaciona el sitio web. Estas permiten recuperar información
            sobre sus hábitos de navegación o, en algunos casos, reconocer al
            usuario o equipo desde el cual se está accediendo al sitio web.
            Algunas de sus finalidades son:
          </p>
          <p className="legal-texto">
            <ol>
              <li>
                Facilitar el inicio de sesión y recordar las preferencias del
                usuario.
              </li>
              <li>Mejorar la funcionalidad y el rendimiento del sitio web.</li>
              <li>
                Recopilar estadísticas y métricas para mejorar nuestros
                servicios.
              </li>
              <li>
                Brindar publicidad personalizada basada en las preferencias del
                usuario.
              </li>
            </ol>
          </p>
          <p className="legal-texto">
            Si decides eliminar y bloquear las cookies de este sitio, debe tener
            en cuenta que algunas partes del sitio web pueden dejar de funcionar
            correctamente, o la calidad general de la página y su contenido
            pueden verse afectados.
          </p>
          <p className="legal-texto">
            La mayoría de navegadores aceptan esta tecnología automáticamente.
            El usuario puede acceder y cambiar las configuraciones en las
            opciones del navegador instalado en su ordenador, para permitir,
            bloquear o eliminar definitivamente las cookies instaladas en su
            equipo.
          </p>
          <p className="legal-texto">
            <span className="legal-bold">¿Qué son las cookies?</span>
          </p>
          <p className="legal-texto">
            <u>Cookies de análisis:</u> Nos permiten cuantificar el número de
            usuarios y realizar análisis estadísticos sobre cómo utilizan
            nuestros usuarios el servicio que ofrecemos. Al analizar su
            navegación en nuestro sitio web, buscamos mejorar la oferta de
            productos o servicios que brindamos.
          </p>
          <p className="legal-texto">
            <u>Cookies publicitarias:</u> Estas cookies, tratadas por nosotros,
            nos permiten gestionar de manera eficaz la oferta de espacios
            publicitarios en nuestro sitio web, adecuando el contenido de los
            anuncios al contenido del servicio que estás buscando. Para ello,
            analizamos tus hábitos de navegación en internet y te mostramos
            publicidad relacionada con tu perfil de navegación.
          </p>
          <p className="legal-texto">
            <u>Cookies de publicidad comportamental:</u> Estas cookies permiten
            una gestión más efectiva de los espacios publicitarios que pueden
            estar presentes en una página web, aplicación o plataforma desde la
            cual se ofrece el servicio. Este tipo de cookies almacenan
            información sobre el comportamiento de los visitantes, obtenida
            mediante la observación continua de sus hábitos de navegación. Esto,
            nos permite desarrollar un perfil específico para mostrar anuncios
            publicitarios acorde a dicho comportamiento.
          </p>
          <p className="legal-texto">
            <span className="legal-bold">Autorización </span>
          </p>
          <p className="legal-texto">
            Al ingresar y permanecer en nuestro portal, tu como usuario
            autorizas a Astara y a las compañías de su grupo y las que hacen
            parte de su cadena de producción, operación y comercialización, para
            que sean utilizados entre otras cosas para la perfilación de
            clientes y almacenados tus datos de navegación mediante el uso de
            Cookies y otras tecnologías en el computador o dispositivo móvil
            dispuesto por ti.
          </p>
          <p className="legal-texto">
            Utilizamos estas tecnologías para recordar las preferencias de
            nuestros usuarios para el uso del Sitio Web, para presentar ofertas
            y publicidad que pueda ser de tu interés y para facilitar cualquier
            tipo de interacción con la página en el futuro.
          </p>
          <p className="legal-texto">
            Nuestros portales web podrán compartir la información obtenida con
            terceros autorizados y controlados, con los fines previamente
            mencionados. Estos terceros únicamente darán el uso establecido en
            esta política y en caso de requerir algún otro actuar, este será
            informado a todos nuestros usuarios.
          </p>
          <p className="legal-texto">
            Es posible que en el futuro modifiquemos la información contenida en
            la presente Política e uso de Cookies. En caso de que lo hagamos, te
            lo notificaremos por distintas vías, por ejemplo, a través de un
            banner, un pop-up o una notificación push, entre otras. Esto, con el
            fin de que puedas revisar los cambios, valorarlos, y en caso de que
            así lo desees, oponerte modificando las configuraciones de tu
            navegador.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DescriptionComponent;
