import React from "react";
import banner from "../../assets/01_Home/HYUNDAIKONA_17.webp";

/*eslint-disable */

declare const window: any;
const BannerComponent: React.FC = () => {
  return (
    <div
      className="banner-politicas-component"
      style={{ backgroundImage: `url(${banner})` }}
    >
      <div className="mascara-banner-politicas-component">Legales</div>
    </div>
  );
};

export default BannerComponent;
