/*eslint-disable */
import React, { useState } from "react";
import icon from "../../../assets/03_Producto/Webp/Group.webp";
interface AsistenciaComponentProps {
  assistances: any;
}
const AsistenciaComponent: React.FC<AsistenciaComponentProps> = ({
  assistances,
}) => {
  return (
    <>
      <div className="title-posventa-asistecia-component">Asistencia</div>
      <div className="servicios-posventa-asistecia-container">
        {assistances?.map((item: any) => (
          <div
            className={`contenido-servicios-posventa-asistecia-container ${item?.format === "left" ? "anormal" : ""}`}
          >
            <div className="img-contenido-servicios-posventa-asistecia-container">
              <img src={item?.media?.url} alt="" />
            </div>
            <div className="text-contenido-servicios-posventa-asistecia-container">
              <div className="title">{item?.title}</div>
              <div className="description"  dangerouslySetInnerHTML={{ __html: item?.description }}>
              </div>
              {/* <div className="btn">Ver más</div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default AsistenciaComponent;
