/*eslint-disable */
import React, { useState } from "react";
import licencia_wb from "../../../assets/10_POSVENTA/03_Campañas/posventa-chasis-numero-licencia.webp";

interface SearchInputProps {
  onSearch: (searchString: string) => void; // Callback para pasar el valor de búsqueda
}

const LicenseCard: React.FC<SearchInputProps> = ({ onSearch }) => {
  const [licenseNumber, setLicenseNumber] = useState("");

  const handleChange = (e: any) => {
    setLicenseNumber(e.target.value);
  };

  const handleSearch = () => {
    console.log("aqui")
    onSearch(licenseNumber); // Llamar al callback con el valor de búsqueda
  };

  return (
    <div className="license-container">
      <div className="license-left">
        <img src={licencia_wb} className="license-card" />
      </div>

      {/* Input para ingresar el número de licencia */}
      <div className="input-section">
        <div className="title">Campañas de seguridad</div>
        <div className="description">
          Para saber si tu vehículo requiere una campaña de servicio activa,
          ingresa el número de VIN/Chasis (17 caracteres alfanuméricos) en el
          espacio a continuación.
        </div>
        <div className="description">
          Si tu vehículo está incluido en alguna de nuestras campañas, acude a
          tu concesionario Hyundai autorizado más cercano o agenda una cita para
          el servicio sin costo.
        </div>
        <div className="subtitle">
          Ingresa el número de VIN/Chasis de la tarjeta de propiedad.
        </div>
        <div className="input">
          <input
            type="text"
            value={licenseNumber}
            onChange={handleChange}
            placeholder="ABC12345678912345"
            maxLength={17}
          />
          <div onClick={() => handleSearch()}>Consultar</div>
        </div>
        <div className="legal">
          En cumplimiento con la circular única de la Superintendencia de
          Industria y Comercio, informamos sobre las campañas de seguridad
          preventivas vigentes en Colombia.
        </div>
      </div>
    </div>
  );
}

export default LicenseCard;
