import React from "react";

/*eslint-disable */

declare const window: any;
const DescriptionComponent: React.FC = () => {
  return (
    <div className="description-politicas-component">
      <p className="legal-texto">
        El presente instrumento tiene por objeto establecer las condiciones bajo
        las cuales los concesionarios autorizados de
        <strong> Hyundai Colombia</strong>
        S.A.S., identificada con NIT N° 901.489.734 - 5 (en adelante,
        <strong>Hyundai Colombia</strong>) que se identifican en el Anexo 1 (en
        adelante, “Concesionarios”) se obligan a administrar y gestionar la
        reserva de vehículos motorizados que la persona interesada en
        reservarlos (en adelante, el <strong>"Cliente"</strong>) realice a
        través de su sitio web
        <a href="https://www.hyundaicolombia.co/">
          https://www.hyundaicolombia.co/
        </a>
        y/o
        <a href="https://www.reservas.hyundaicolombia.co">
          https://www.reservas.hyundaicolombia.co
        </a>
        (en adelante, el<strong> "Sitio Web"</strong>). Lo anterior, de
        conformidad a los siguientes términos y condiciones de aplicación
        general (los<strong>"Términos y Condiciones"</strong>).
      </p>
      <p className="legal-texto">
        Cliente que realice una transacción en el Sitio Web, declara y reconoce,
        por el hecho de efectuar la reserva, que conoce y acepta todos y cada
        uno de los Términos y Condiciones descritos a continuación. Se
        entenderán conocidos y aceptados los Términos y Condiciones por el solo
        hecho de realizar una reserva de productos a través de este Sitio Web.
      </p>
      <p className="legal-texto">
        Hyundai Colombia se reserva el derecho de actualizar y/o modificar los
        Términos y Condiciones, sin previo aviso. Por esta razón recomendamos
        revisar los Términos y Condiciones cada vez que utilice el Sitio Web.
        Los Clientes quedarán sujetos a los Términos y Condiciones del Sitio
        Web, en el momento en el que envíen su solicitud de reserva.
      </p>
    </div>
  );
};

export default DescriptionComponent;
