/*eslint-disable */
import React from "react";
//import "./MaintenanceTable.css";

const MaintenanceTable = () => {
  return (
    <div className="table-container">
      <table className="maintenance-table">
        <thead>
          <tr>
            <th>Revisión</th>
            <th>Gasolina</th>
            <th>Híbridos</th>
            <th>Eléctricos</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cambio de aceite motor, filtro de aceite y cambio de filtro aire motor.</td>
            <td>✔️</td>
            <td>✔️</td>
            <td></td>
          </tr>
          <tr className="alt-row">
            <td>Revisión de sistema frenos, líquido frenos, sistema de embrague y líquido refrigerante cambiar de ser requerido.</td>
            <td>✔️</td>
            <td>✔️</td>
            <td></td>
          </tr>
          <tr>
            <td>Inspección de sistema de combustible y filtro de combustible cambiar de ser requerido.</td>
            <td>✔️</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr className="alt-row">
            <td>Inspección de correa HSG, cambiar de ser requerido.</td>
            <td></td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Diagnóstico con el GDS (análisis de escáner).</td>
            <td></td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr className="alt-row">
            <td>Revisión sistema de carga y estado de batería (equipo diagnóstico).</td>
            <td></td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Revisión caja de dirección y guardapolvos (fugas).</td>
            <td>✔️</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr className="alt-row">
            <td>Revisión suspensión delantera y trasera, semiejes y guardapolvos.</td>
            <td>✔️</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr>
            <td>Alineación y balanceo, según estado inspección.</td>
            <td>✔️</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
          <tr className="alt-row">
            <td>
              Revisión e inspección tren de potencia (AT/MT) / Para las transmisiones automáticas se revisa y verifica el estado del
              fluido y solo en caso de ser necesario cambiarlo.
            </td>
            <td>✔️</td>
            <td>✔️</td>
            <td>✔️</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MaintenanceTable;
