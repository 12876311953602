import React from "react";
/*eslint-disable */
import icon from "../../../assets/03_Producto/Webp/Group.webp";


interface ServiciosComponentProps {
  services: any;
}

declare const window: any;
const ServiciosComponent: React.FC<ServiciosComponentProps> = ({
  services,
}) => {
  console.log(services);
  
  return (
    <div className="servicios-posventa-servicios-container">
      {services?.map((item: any) => (
        <div
          className={`contenido-servicios-posventa-servicios-container ${item.format === "left" ? "anormal" : ""}`}
        >
          <div className="img-contenido-servicios-posventa-servicios-container">
            <img src={item?.media?.url} alt="" />
          </div>
          <div className="text-contenido-servicios-posventa-servicios-container">
            <div className="title">{item?.title}</div>
            <div className="description" dangerouslySetInnerHTML={{ __html: item?.description }}>
              
            </div>
            <a href={item?.button_url} className="btn">Ver más</a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiciosComponent;
