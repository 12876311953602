import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo_hyundai from "../assets/01_Home/Icon_Hyundai.svg";
import logo_searh from "../assets/01_Home/Icon_Search.svg";
import icon_toggle from "../assets/01_Home/Burger.svg";
import icon_subir from "../assets/01_Home/Icon_subir.svg";
import icon_close from "../assets/01_Home/Close.svg";
//VEHICULOS
import kona from "../assets/webp/hyunda-hover-KONA.webp";
import tucson from "../assets/webp/hyunda-hover-TUCSON.webp";
import ioniq from "../assets/webp/hyunda-hover-IONIQ.webp";
import palisade from "../assets/webp/hyunda-hover-PALISADE.webp";
import veneu from "../assets/webp/hyunda-hover-VENUE.webp";
import creta from "../assets/webp/hyunda-hover-Creta.webp";
import hb20s from "../assets/webp/hyunda-hover-HB20S.webp";
import hb20 from "../assets/webp/hyunda-hover-HB20.webp";
import staria from "../assets/webp/hyunda-hover-STARIA.webp";
import comerciales from "../assets/webp/hyundai-hover-GRAND-METRO-TAXI.webp";
import santafe from "../assets/webp/banner-hover-santafe-hev.webp";
//POSVENTA
import garantia from "../assets/02_Hover Nav bar/Posventa/Garantia.webp";
import campania from "../assets/02_Hover Nav bar/Posventa/Campañas.webp";
import mantenimiento from "../assets/02_Hover Nav bar/Posventa/Mantenimiento.webp";
import repuestos from "../assets/02_Hover Nav bar/Posventa/Repuestos.webp";
//WHO WE
import hyundai from "../assets/02_Hover Nav bar/Quienes somos/Hyundai.webp";
import blog from "../assets/02_Hover Nav bar/Quienes somos/Blog.webp";
import contacto from "../assets/02_Hover Nav bar/Quienes somos/PQR.webp";
import astara from "../assets/02_Hover Nav bar/Quienes somos/Astara.webp";
import down from "../assets/01_Home/Hide.svg";
/*eslint-disable */

declare const window: any;

interface MenuLaoutsProps {
  className?: string; // className es opcional
  active?: string;
  setVisibleHeight?: any;
  visibleHeight?: any;
}

const MenuLayouts: React.FC<MenuLaoutsProps> = ({
  className,
  active,
  setVisibleHeight,
  visibleHeight,
}) => {
  const [menuHover, setMenuHover] = useState("auto");
  const [menuHeight, setMenuHeight] = useState("auto");
  const [isHovered, setIsHovered] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleRefresh = (e: any) => {
    if (location.pathname === "/vehiculos") {
      e.preventDefault();
      navigate(0);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Calcula la cantidad de píxeles visibles
        const visible =
          Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
        setVisibleHeight(Math.max(0, visible)); // Evita valores negativos
      }
    };

    // Escucha de eventos scroll y resize
    window.addEventListener("scroll", handleVisibilityChange);
    window.addEventListener("resize", handleVisibilityChange);

    // Inicializa la visibilidad
    handleVisibilityChange();

    return () => {
      // Limpia los event listeners
      window.removeEventListener("scroll", handleVisibilityChange);
      window.removeEventListener("resize", handleVisibilityChange);
    };
  }, [setVisibleHeight]);

  const handleMouseEnter = (item: any) => {
    setMenuHover(item);
    if (!isHovered) {
      setMenuHeight("521px");
      setIsHovered(true);
      document.body.classList.add("active");
      const element = document.querySelector(`.${className}`);
      if (element) {
        element.classList.add("active");
      }
    }
  };

  const handleMouseLeave = () => {
    if (isHovered) {
      setMenuHeight("auto");
      document.body.classList.remove("active");
      setTimeout(() => {
        setIsHovered(false);
        const element = document.querySelector(`.${className}`);
        if (element) {
          element.classList.remove("active");
        }
      }, 100); // Duración de la transición
    }
  };
  

  return (
    <div
      className={`menu-layout ${isHovered ? "expanded" : ""} ${active}`}
      onMouseLeave={handleMouseLeave}
      ref={divRef}
    >
      <div className="content-menu-layout">
        <Link className="logo-menu-layout" to="/">
          <img src={logo_hyundai} alt="Hyundai Logo" />
        </Link>
        <div
          className={`toggle-menu-layout ${isHovered ? "active" : ""}`}
          onClick={() => {
            setIsHovered(!isHovered), setMenuHover("auto");
          }}
        >
          <img src={isHovered ? icon_close : icon_toggle} alt="" />
        </div>
        <div className="items-menu-layout">
          <Link
            className={`item-menu-layout ${
              menuHover === "vehiculo" || active === "vehiculo" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("vehiculo")}
            to="/vehiculos"
          >
            Vehículos
          </Link>
          <Link
            className={`item-menu-layout ${
              menuHover === "posventa" || active === "posventa" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("posventa")}
            to="/posventa"
          >
            Posventa
          </Link>
          <Link
            className={`item-menu-layout ${
              menuHover === "concesionario" || active === "concesionario" ? "active" : ""
            }`}
            //onMouseEnter={() => handleMouseEnter("concesionario")}
            onMouseEnter={handleMouseLeave}
            to="/concesionarios"
          >
            Concesionarios
          </Link>
          <div
            className={`item-menu-layout ${
              menuHover === "somos" ? "active" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("somos")}
          >
            Quiénes somos
          </div>
          <div
            className={`item-menu-layout ${
              menuHover === "reserva" ? "active" : ""
            }`}
            //onMouseEnter={() => handleMouseEnter("reserva")}
            onMouseEnter={handleMouseLeave}
          >
            Reserva ya
          </div>
        </div>
        <div className="searh-menu-layout">
          <img src={logo_searh} alt="Search Logo" />
        </div>
      </div>
      <div
        className={`vehiculos-menu-layout ${
          menuHover === "vehiculo" ? "active" : ""
        }`}
      >
        <div className="vehiculos-content-menu-layout">
          <Link
            to={"/vehiculos/santa-fe-hibrida"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${santafe})` }}
          >
            <h2>SANTA FE</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/kona-gasolina"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${kona})` }}
          >
            <h2>KONA</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/tucson"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${tucson})` }}
          >
            <h2>TUCSON</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/ioniq-5"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${ioniq})` }}
          >
            <h2>IONIQ 5</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/palisade"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${palisade})` }}
          >
            <h2>PALISADE</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/venue"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${veneu})` }}
          >
            <h2>VENUE</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/creta"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${creta})` }}
          >
            <h2>CRETA</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/hb20-sedan"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${hb20s})` }}
          >
            <h2>HB20 SEDÁN</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/hb20-hatchback"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${hb20})` }}
          >
            <h2>HB20 HATCHBACK</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/staria"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${staria})` }}
          >
            <h2>STARIA</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
          <Link
            to={"/vehiculos/grand-metro-taxi"}
            className="vehiculo-content-menu-layout"
            style={{ backgroundImage: `URL(${comerciales})` }}
          >
            <h2>GRAND METRO TAXI</h2>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </Link>
        </div>
        <div className="categories-content-menu-layout">
          <Link to="/vehiculos" className="category-content-menu-layout" onClick={handleRefresh}>
            Todos los vehículos
          </Link>
          {/* <div className="category-content-menu-layout">Fichas técnicas</div> */}
          <div className="category-content-menu-layout">
            Agenda tu Cita Taller
          </div>
          <div className="category-content-menu-layout">Comparador</div>
          <div className="category-content-menu-layout">
            <p>Explora la experiencia</p>
            Experiencia interactiva 360
          </div>
        </div>

        <div className="down">
            <img src={down} alt="" />
          </div>
      </div>
      <div
        className={`posventas-menu-layout ${
          menuHover === "posventa" ? "active" : ""
        }`}
      >
        <div className="posventas-content-menu-layout">
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${garantia})` }}
          >
            <span>Garantía</span>
            <div className="hover-posventa-content-menu-layout"></div>
          </div>
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${campania})` }}
          >
            <span>Campañas de seguridad</span>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${mantenimiento})` }}
          >
            <span>Mantenimiento</span>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
          <div
            className="posventa-content-menu-layout"
            style={{ backgroundImage: `URL(${repuestos})` }}
          >
            <span>Repuestos genuinos</span>
            <div className="hover-vehiculo-content-menu-layout"></div>
          </div>
        </div>
        <div className="categories-content-menu-layout">
          <div className="category-content-menu-layout">
            Manuales de garantía
          </div>
          <div className="category-content-menu-layout">
            Campañas disponibles
          </div>
          <div className="category-content-menu-layout">Hojas de rescate</div>
          <div className="category-content-menu-layout">
            Agendar Cita Taller
          </div>
        </div>
      </div>
      <div
        className={`who-we-menu-layout ${
          menuHover === "somos" ? "active" : ""
        }`}
      >
        <div className="who-we-are-content-menu-layout">
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${hyundai})` }}
          >
            <span>Sobre Hyundai</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${blog})` }}
          >
            <span>Blog y novedades</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${contacto})` }}
          >
            <span>Escríbenos</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
          <div
            className="who-we-content-menu-layout"
            style={{ backgroundImage: `URL(${astara})` }}
          >
            <span>Escríbenos</span>
            <div className="hover-who-we-content-menu-layout"></div>
          </div>
        </div>
      </div>
      <div className="content-movil-menu-layout">
        <div className="content-movil-items-menu-layout">
          <div className="content-movil-item-menu-layout">
            <div
              className={`content-movil-item-label-menu-layout ${
                menuHover === "Vehículos" ? "active" : ""
              }`}
              onClick={() =>
                setMenuHover(menuHover !== "Vehículos" ? "Vehículos" : "auto")
              }
            >
              Vehículos <img src={icon_subir} alt="" />
            </div>
            <div
              className={`content-movil-item-vehiculos-menu-layout ${
                menuHover === "Vehículos" ? "active" : ""
              }`}
            >
              <Link
                to={"/vehiculos/santa-fe-hibrida"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${santafe})` }}
              >
                <h2>SANTA FE</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/kona-gasolina"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${kona})` }}
              >
                <h2>KONA</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/tucson"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${tucson})` }}
              >
                <h2>TUCSON</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/ioniq-5"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${ioniq})` }}
              >
                <h2>IONIQ 5</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/palisade"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${palisade})` }}
              >
                <h2>PALISADE</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/venue"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${veneu})` }}
              >
                <h2>VENUE</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/creta"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${creta})` }}
              >
                <h2>CRETA</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/hb20-sedan"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${hb20s})` }}
              >
                <h2>HB20 SEDÁN</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/hb20-hatchback"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${hb20})` }}
              >
                <h2>HB20 HATCHBACK</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/staria"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${staria})` }}
              >
                <h2>STARIA</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              <Link
                to={"/vehiculos/grand-metro-taxi"}
                className="content-movil-item-vehiculo-menu-layout"
                style={{ backgroundImage: `URL(${comerciales})` }}
              >
                <h2>GRAND METRO TAXI</h2>
                <div className="hover-content-movil-item-vehiculo-menu-layout"></div>
              </Link>
              {/* <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
              >
                Fichas técnicas
              </Link> */}
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
              >
                Todos los vehículos
              </Link>
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
              >
                Agendar Cita Taller
              </Link>
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
              >
                Comparador
              </Link>
              <Link
                className="list-content-movil-item-vehiculo-menu-layout"
                to={"/vehiculos"}
              >
                <p>Explora la experiencia</p>
                Comparador
              </Link>
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Posventa")}
            >
              Posventa <img src={icon_subir} alt="" />
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Concesionarios")}
            >
              Concesionarios <img src={icon_subir} alt="" />
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Quiénes somos")}
            >
              Quiénes somos
              <img src={icon_subir} alt="" />
            </div>
          </div>
          <div className="content-movil-item-menu-layout">
            <div
              className="content-movil-item-label-menu-layout"
              onClick={() => setMenuHover("Reserva ya")}
            >
              Reserva ya <img src={icon_subir} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuLayouts;
