import React, { useState, useEffect } from "react";
/*eslint-disable */
interface GaleriaComponentProps {
  genuine_parts: any;
}
const caracteristicas: any = {
  modelo: "",
  tecnologia: [
    {
      id: 1165,
      title: "Motor a otro nivel",
      description:
        "Potencia combinada de 232 Hp, con un motor turbo de 1.6 litros y 177.5 Hp y un motor eléctrico de 44.2 kW (59.3 Hp). Disfruta una conducción emocionante con lo mejor de ambos mundos.",
      media_type: "img",
      media: {
        id: 305,
        documentId: "lh5grv6pb4xmgntfx020o2l5",
        name: "tecnologia-carrusel-motor.webp",
        alternativeText: null,
        caption: null,
        width: 1844,
        height: 920,
        formats: {
          large: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/large_tecnologia_carrusel_motor_96bf38ec6e.webp",
            hash: "large_tecnologia_carrusel_motor_96bf38ec6e",
            mime: "image/webp",
            name: "large_tecnologia-carrusel-motor.webp",
            path: null,
            size: 49.96,
            width: 1000,
            height: 499,
            sizeInBytes: 49958,
          },
          small: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/small_tecnologia_carrusel_motor_96bf38ec6e.webp",
            hash: "small_tecnologia_carrusel_motor_96bf38ec6e",
            mime: "image/webp",
            name: "small_tecnologia-carrusel-motor.webp",
            path: null,
            size: 16.36,
            width: 500,
            height: 249,
            sizeInBytes: 16358,
          },
          medium: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/medium_tecnologia_carrusel_motor_96bf38ec6e.webp",
            hash: "medium_tecnologia_carrusel_motor_96bf38ec6e",
            mime: "image/webp",
            name: "medium_tecnologia-carrusel-motor.webp",
            path: null,
            size: 31.74,
            width: 750,
            height: 374,
            sizeInBytes: 31740,
          },
          thumbnail: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/thumbnail_tecnologia_carrusel_motor_96bf38ec6e.webp",
            hash: "thumbnail_tecnologia_carrusel_motor_96bf38ec6e",
            mime: "image/webp",
            name: "thumbnail_tecnologia-carrusel-motor.webp",
            path: null,
            size: 5.24,
            width: 245,
            height: 122,
            sizeInBytes: 5240,
          },
        },
        hash: "tecnologia_carrusel_motor_96bf38ec6e",
        ext: ".webp",
        mime: "image/webp",
        size: 159.74,
        url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/tecnologia_carrusel_motor_96bf38ec6e.webp",
        previewUrl: null,
        provider: "@strapi/provider-upload-aws-s3",
        provider_metadata: null,
        createdAt: "2024-10-01T16:09:24.264Z",
        updatedAt: "2024-10-01T16:09:24.264Z",
        publishedAt: "2024-10-01T16:09:24.264Z",
        locale: null,
      },
    },
    {
      id: 1166,
      title: "Regeneración inteligente",
      description:
        "Optimiza la eficiencia y comodidad con el ajuste automático del frenado regenerativo, aprovechando el tráfico y la navegación en modo deslizamiento. En modos ECO o SMART, ajusta el frenado en 3 niveles usando las palancas de cambio.",
      media_type: "img",
      media: {
        id: 306,
        documentId: "iwgfz09csei46ypvsa28z9do",
        name: "tecnologia-carrusel-regeneracion-inteligente.webp",
        alternativeText: null,
        caption: null,
        width: 1844,
        height: 920,
        formats: {
          large: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/large_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d.webp",
            hash: "large_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d",
            mime: "image/webp",
            name: "large_tecnologia-carrusel-regeneracion-inteligente.webp",
            path: null,
            size: 41.91,
            width: 1000,
            height: 499,
            sizeInBytes: 41908,
          },
          small: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/small_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d.webp",
            hash: "small_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d",
            mime: "image/webp",
            name: "small_tecnologia-carrusel-regeneracion-inteligente.webp",
            path: null,
            size: 15.07,
            width: 500,
            height: 249,
            sizeInBytes: 15072,
          },
          medium: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/medium_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d.webp",
            hash: "medium_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d",
            mime: "image/webp",
            name: "medium_tecnologia-carrusel-regeneracion-inteligente.webp",
            path: null,
            size: 27.47,
            width: 750,
            height: 374,
            sizeInBytes: 27468,
          },
          thumbnail: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/thumbnail_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d.webp",
            hash: "thumbnail_tecnologia_carrusel_regeneracion_inteligente_df2a290c4d",
            mime: "image/webp",
            name: "thumbnail_tecnologia-carrusel-regeneracion-inteligente.webp",
            path: null,
            size: 5.44,
            width: 245,
            height: 122,
            sizeInBytes: 5436,
          },
        },
        hash: "tecnologia_carrusel_regeneracion_inteligente_df2a290c4d",
        ext: ".webp",
        mime: "image/webp",
        size: 117.68,
        url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/tecnologia_carrusel_regeneracion_inteligente_df2a290c4d.webp",
        previewUrl: null,
        provider: "@strapi/provider-upload-aws-s3",
        provider_metadata: null,
        createdAt: "2024-10-01T16:09:36.447Z",
        updatedAt: "2024-10-01T16:09:36.447Z",
        publishedAt: "2024-10-01T16:09:36.448Z",
        locale: null,
      },
    },
    {
      id: 1167,
      title: "Paleta frontal automática de aire",
      description:
        "En Hyundai priorizamos las mejoras en el rendimiento aerodinámico de la refrigeración, incorporando aletas de aire activas internas y externas integradas en el parachoques delantero que se abren y cierran dependiendo de las condiciones de manejo.",
      media_type: "img",
      media: {
        id: 307,
        documentId: "slkwgy0fe1qem9xib4dx7wnr",
        name: "tecnologia-carrusel-paleta-frontal-aire-automatica.webp",
        alternativeText: null,
        caption: null,
        width: 1844,
        height: 920,
        formats: {
          large: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/large_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289.webp",
            hash: "large_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289",
            mime: "image/webp",
            name: "large_tecnologia-carrusel-paleta-frontal-aire-automatica.webp",
            path: null,
            size: 31.09,
            width: 1000,
            height: 499,
            sizeInBytes: 31094,
          },
          small: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/small_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289.webp",
            hash: "small_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289",
            mime: "image/webp",
            name: "small_tecnologia-carrusel-paleta-frontal-aire-automatica.webp",
            path: null,
            size: 9.18,
            width: 500,
            height: 249,
            sizeInBytes: 9182,
          },
          medium: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/medium_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289.webp",
            hash: "medium_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289",
            mime: "image/webp",
            name: "medium_tecnologia-carrusel-paleta-frontal-aire-automatica.webp",
            path: null,
            size: 18.76,
            width: 750,
            height: 374,
            sizeInBytes: 18764,
          },
          thumbnail: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/thumbnail_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289.webp",
            hash: "thumbnail_tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289",
            mime: "image/webp",
            name: "thumbnail_tecnologia-carrusel-paleta-frontal-aire-automatica.webp",
            path: null,
            size: 2.79,
            width: 245,
            height: 122,
            sizeInBytes: 2794,
          },
        },
        hash: "tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289",
        ext: ".webp",
        mime: "image/webp",
        size: 105.6,
        url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/tecnologia_carrusel_paleta_frontal_aire_automatica_498abce289.webp",
        previewUrl: null,
        provider: "@strapi/provider-upload-aws-s3",
        provider_metadata: null,
        createdAt: "2024-10-01T16:09:49.190Z",
        updatedAt: "2024-10-01T16:09:49.190Z",
        publishedAt: "2024-10-01T16:09:49.190Z",
        locale: null,
      },
    },
    {
      id: 1168,
      title: "Sonido BOSE",
      description:
        "La nueva Hyundai Santa Fe cuenta con tecnología de sonido premium BOSE que se combina con 12 altavoces de alto rendimiento optimizados y subwoofer para proporcionar un sonido altamente envolvente (versión Limited).",
      media_type: "img",
      media: {
        id: 308,
        documentId: "ojcuu12kl10ivgjyt7vf64ub",
        name: "tecnologia-carrusel-sonido-bose.webp",
        alternativeText: null,
        caption: null,
        width: 1844,
        height: 920,
        formats: {
          large: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/large_tecnologia_carrusel_sonido_bose_31d9dfb8db.webp",
            hash: "large_tecnologia_carrusel_sonido_bose_31d9dfb8db",
            mime: "image/webp",
            name: "large_tecnologia-carrusel-sonido-bose.webp",
            path: null,
            size: 25.54,
            width: 1000,
            height: 499,
            sizeInBytes: 25536,
          },
          small: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/small_tecnologia_carrusel_sonido_bose_31d9dfb8db.webp",
            hash: "small_tecnologia_carrusel_sonido_bose_31d9dfb8db",
            mime: "image/webp",
            name: "small_tecnologia-carrusel-sonido-bose.webp",
            path: null,
            size: 9.28,
            width: 500,
            height: 249,
            sizeInBytes: 9282,
          },
          medium: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/medium_tecnologia_carrusel_sonido_bose_31d9dfb8db.webp",
            hash: "medium_tecnologia_carrusel_sonido_bose_31d9dfb8db",
            mime: "image/webp",
            name: "medium_tecnologia-carrusel-sonido-bose.webp",
            path: null,
            size: 16.98,
            width: 750,
            height: 374,
            sizeInBytes: 16976,
          },
          thumbnail: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/thumbnail_tecnologia_carrusel_sonido_bose_31d9dfb8db.webp",
            hash: "thumbnail_tecnologia_carrusel_sonido_bose_31d9dfb8db",
            mime: "image/webp",
            name: "thumbnail_tecnologia-carrusel-sonido-bose.webp",
            path: null,
            size: 3.05,
            width: 245,
            height: 122,
            sizeInBytes: 3048,
          },
        },
        hash: "tecnologia_carrusel_sonido_bose_31d9dfb8db",
        ext: ".webp",
        mime: "image/webp",
        size: 62.63,
        url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/tecnologia_carrusel_sonido_bose_31d9dfb8db.webp",
        previewUrl: null,
        provider: "@strapi/provider-upload-aws-s3",
        provider_metadata: null,
        createdAt: "2024-10-01T16:10:01.794Z",
        updatedAt: "2024-10-01T16:10:01.794Z",
        publishedAt: "2024-10-01T16:10:01.795Z",
        locale: null,
      },
    },
    {
      id: 1169,
      title: "Aire acondicionado automático",
      description:
        "Podrás controlar varias funciones para tener el mejor ambiente en tu nueva Santa Fe, como el volumen de aire del asiento trasero, utilizando una gran pantalla táctil que te permitirá graduar la temperatura a tu gusto.",
      media_type: "img",
      media: {
        id: 309,
        documentId: "vnftp9xrsfew0nqtpzck6a96",
        name: "tecnologia-carrusel-aire-acondicionado-automatico.webp",
        alternativeText: null,
        caption: null,
        width: 1844,
        height: 920,
        formats: {
          large: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/large_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd.webp",
            hash: "large_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd",
            mime: "image/webp",
            name: "large_tecnologia-carrusel-aire-acondicionado-automatico.webp",
            path: null,
            size: 55.45,
            width: 1000,
            height: 499,
            sizeInBytes: 55450,
          },
          small: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/small_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd.webp",
            hash: "small_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd",
            mime: "image/webp",
            name: "small_tecnologia-carrusel-aire-acondicionado-automatico.webp",
            path: null,
            size: 16.24,
            width: 500,
            height: 249,
            sizeInBytes: 16242,
          },
          medium: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/medium_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd.webp",
            hash: "medium_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd",
            mime: "image/webp",
            name: "medium_tecnologia-carrusel-aire-acondicionado-automatico.webp",
            path: null,
            size: 32.4,
            width: 750,
            height: 374,
            sizeInBytes: 32398,
          },
          thumbnail: {
            ext: ".webp",
            url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/thumbnail_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd.webp",
            hash: "thumbnail_tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd",
            mime: "image/webp",
            name: "thumbnail_tecnologia-carrusel-aire-acondicionado-automatico.webp",
            path: null,
            size: 5.53,
            width: 245,
            height: 122,
            sizeInBytes: 5528,
          },
        },
        hash: "tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd",
        ext: ".webp",
        mime: "image/webp",
        size: 198.03,
        url: "https://s3-umbra-storage-cloud-v2.s3.us-east-1.amazonaws.com/hyundai-colombia-web/strapi/tecnologia_carrusel_aire_acondicionado_automatico_9775acfddd.webp",
        previewUrl: null,
        provider: "@strapi/provider-upload-aws-s3",
        provider_metadata: null,
        createdAt: "2024-10-01T16:10:15.081Z",
        updatedAt: "2024-10-01T16:10:15.081Z",
        publishedAt: "2024-10-01T16:10:15.081Z",
        locale: null,
      },
    },
  ],
};
declare const window: any;
const GaleriaComponent: React.FC<GaleriaComponentProps> = ({genuine_parts}) => {
  const [currentIndex, setCurrentIndex] = useState(1); // Inicialmente se muestra la imagen en el centro
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Almacena el ancho de la pantalla

  // Actualiza el ancho de la pantalla cada vez que se cambia el tamaño de la ventana
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Agrega el event listener para escuchar cambios en el tamaño de la ventana
    window.addEventListener("resize", handleResize);

    // Limpia el event listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : caracteristicas?.tecnologia?.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < caracteristicas?.tecnologia?.length - 1 ? prevIndex + 1 : 0
    );
  };
  return (
    <div className="caracteristica-vehiculos-detail-component">
      <div
        className="btn-carrousel-caracteristica-vehiculos-detail-component prev"
        onClick={handlePrev}
      >
        <img src="../assets/01_Home/Icon_Left.svg" alt="" />
      </div>
      <div
        className="carrousel-caracteristica-vehiculos-detail-component"
        style={{
          transform:
            currentIndex === 0
              ? `translateX(${windowWidth < 768 ? "10%" : "25%"})`
              : `translateX(-${
                  windowWidth < 768
                    ? currentIndex * 70 === 70
                      ? currentIndex * 70
                      : /* : currentIndex * 70 === 140
                    ? currentIndex * 70 + 10 */
                        currentIndex * 80 - 10
                    : currentIndex * 25 === 25
                    ? currentIndex * 25
                    : currentIndex * 25 === 50
                    ? currentIndex * 25 + 25
                    : currentIndex * 50 + 25 - 50
                }%)`,
        }}
      >
        {caracteristicas?.tecnologia?.map((item: any, index: any) =>
          item?.media !== null ? (
            <div
              className={`item-caracteristica-vehiculos-detail-component`}
              key={index}
            >
              <img src={item?.media?.url} alt="" />
            </div>
          ) : null
        )}
      </div>
      <div
        className="btn-carrousel-caracteristica-vehiculos-detail-component next"
        onClick={handleNext}
      >
        <img src="../assets/01_Home/Icon_Right.svg" alt="" />
      </div>
      <div className="info-caracteristica-vehiculos-detail-component">
        {/* <div className="title-caracteristica-vehiculos-detail-component">
          {caracteristicas?.tecnologia[currentIndex]?.title}
        </div> */}
        {/* <div className="descripcion-caracteristica-vehiculos-detail-component">
          {caracteristicas?.tecnologia[currentIndex]?.description}
        </div> */}
        <div className="index-caracteristica-vehiculos-detail-component">
          {caracteristicas?.tecnologia?.map((item: any, index: number) => (
            <div
              className={`${index === currentIndex ? "active" : ""}`}
              onClick={() => setCurrentIndex(index)}
              key={index}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GaleriaComponent;
