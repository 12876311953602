import React, { useEffect } from "react";

interface ModalComponentProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  useEffect(() => {
    if (isOpen) {
      // Bloquear scroll al abrir el modal
      document.body.style.overflow = "hidden";
    } else {
      // Desbloquear scroll al cerrar el modal
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null; // No renderizar si el modal está cerrado

  return (
    <div className="modal-overlay">
      <div className="modal-content-ug">
        {children}
        
      </div>
    </div>
  );
};

export default ModalComponent;
