/*eslint-disable */
import React from "react";
import banne from "../../assets/10_POSVENTA/1.webp";


interface BannerGeneralComponentProps {
  title: any;
  banner: any
}

declare const window: any;
const BannerGeneralComponent: React.FC<BannerGeneralComponentProps> = ({title, banner}) => {
  return (
    <div
      className="banner-posventa-component"
      style={{ backgroundImage: `url(${banne})` }}
    >
      <div className="text1-banner-posventa-component">{title}</div>
    </div>
  );
};

export default BannerGeneralComponent;
