import React from "react";
import Accordion from "react-bootstrap/Accordion";

/*eslint-disable */

declare const window: any;
const AccordionComponent: React.FC = () => {
  return (
    <div className="accordion-politicas-component">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>1. RESERVA ONLINE DE VEHÍCULOS</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              La reserva online de vehículos consiste en la transacción
              realizada por el Cliente mediante la especificación habilitada en
              el Sitio Web especialmente destinada a dicho efecto, acción
              mediante la cual la(s) unidad(es) quedará(n) reservada(s) al
              Cliente que abonó el monto que se indique para cada vehículo.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                1.1. Procedimiento de reserva online de vehículos
              </span>
            </p>
            <p className="legal-texto">
              Para realizar una reserva de vehículo conforme a lo anterior (la
              "Reserva"), el Cliente deberá:
            </p>
            <p className="legal-texto">
              <ol type="a">
                <li>Elegir el vehículo de su interés.</li>
                <li>
                  Registrarse, para lo cual deberá ser mayor de edad y contar
                  con Cédula de Ciudadanía, Cédula de Extranjería o Número de
                  Identificación Tributaria, y completar el formulario que
                  aparece en el Sitio Web, donde ingresará los datos necesarios
                  para el registro. Para finalizar el registro, el Cliente
                  deberá aceptar previamente los Términos y Condiciones y la
                  Política de Privacidad de Datos Personales de Hyundai
                  Colombia.
                </li>
                <li>
                  Cuando el Cliente ya cuente con un login de acceso, el Sitio
                  Web le sugerirá al Cliente el local del Concesionario más
                  cercano al domicilio registrado; sin embargo, el Cliente podrá
                  elegir un Concesionario distinto, según su cercanía y
                  comodidad.
                </li>
                <li>
                  Posteriormente, el Cliente deberá elegir la opción “Reserva en
                  línea” por el monto fijo de 1.000.000 COP (Un millón de pesos
                  colombianos). En caso el vehículo materia de Reserva cuente
                  con un bono de descuento, el mencionado bono será descontado
                  sobre el precio final del vehículo al momento de que el
                  Cliente concrete la compra en el Concesionario elegido.
                </li>
                <li>
                  La cancelación del monto de Reserva se realizará a través de
                  la pasarela de pagos de la empresa SMART PAYMENTS S.A.S, con
                  NIT N° NIT 900.566.072-4 (en adelante, "Efipay"). Dicho pago
                  podrá realizarse mediante tarjeta crédito (no aplica para
                  tarjetas de crédito extranjeras), débito o con pago efectivo.
                  El Cliente sólo podrá usar un único medio de pago. El pago con
                  tarjeta de crédito y débito se rige por los términos y
                  condiciones establecidos por la entidad bancaria emisora de
                  las referidas tarjetas. El Cliente acepta y reconoce que
                  debido a las particularidades en la operatividad del servicio
                  de la pasarela de pagos que utiliza el Sitio Web, los pagos se
                  realizarán únicamente en moneda nacional (Pesos Colombianos);
                  sin embargo, el comprobante de pago será emitido en Dólares.
                </li>
                <li>
                  En caso el Cliente use pago efectivo, deberá realizar el pago
                  del código CIP antes de su fecha de vencimiento (6 horas),
                  caso contrario se cancelará la solicitud de Reserva.
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                1.2. Procedimiento de compra del vehículo objeto de la Reserva
              </span>
            </p>
            <p className="legal-texto">
              Una vez finalizado el procedimiento de Reserva:
            </p>
            <p className="legal-texto">
              <ol type="a">
                <li>
                  En caso el medio de pago utilizado por el Cliente sea válido y
                  aceptado o el Cliente hubiese realizado el pago dentro del
                  plazo indicado por PagoEfectivo (6 horas), el Cliente recibirá
                  un correo electrónico con la confirmación del pago.
                </li>
                <li>
                  Luego de la confirmación del pago, el Cliente será contactado
                  por un ejecutivo del Concesionario para confirmarle si la
                  Reserva se realizó satisfactoriamente. Por motivos ajenos al
                  Concesionario y atribuibles al funcionamiento del Sitio Web,
                  podría darse el caso que la Reserva no se pueda llegar a
                  concretar porque el vehículo elegido por el Cliente ya no se
                  encuentra en stock. En dicho supuesto, el Concesionario
                  coordinará con el Cliente la devolución de su dinero.
                </li>
                <li>
                  En caso la Reserva sea confirmada, un asesor de ventas del
                  Concesionario elegido se contactará con el Cliente a fin de
                  asistirlo en el proceso de compra del vehículo reservado, así
                  como coordinar una visita al local del Concesionario, en caso
                  el Cliente lo solicitase.
                </li>
                <li>
                  La compra del vehículo deberá concretarse en coordinación
                  directa entre el Cliente y el Concesionario fuera del Sitio
                  Web, así como cualquier requerimiento adicional que sea de
                  interés del Cliente y que no forme parte de lo ofrecido a
                  través de dicha herramienta. Los precios de los vehículos
                  pueden sufrir modificaciones por causas ajenas al
                  Concesionario, como variaciones de tipo de cambio, derechos de
                  importación, tributos u otros. Los gastos de internamiento,
                  aranceles e impuestos vigentes a la fecha están considerados
                  en el Sitio Web; cualquier variación posterior decretada que
                  afecte la cuantía de estos conceptos, será asumida por cuenta
                  y cargo del Cliente.
                </li>
                <li>
                  El Cliente deberá cancelar el saldo restante del precio del
                  vehículo aplicando el tipo de cambio del día fijado por el
                  Concesionario.
                </li>
                <li>
                  La entrega del vehículo adquirido se realizará en un plazo no
                  mayor a treinta (30) días hábiles desde la firma de la
                  documentación requerida para la inmatriculación del vehículo.
                  Asimismo, el Cliente deberá coordinar con el Concesionario
                  elegido el lugar de entrega del vehículo
                </li>
              </ol>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                1.3. Caducidad de la Reserva
              </span>
            </p>
            <p className="legal-texto">
              Una vez confirmada la Reserva se bloquearán la(s) unidad(es) para
              el resto del público. El plazo de vigencia de la Reserva será de
              siete (7) días hábiles, período durante el cual el Cliente, para
              acceder a la oferta, deberá cancelar la totalidad del vehículo
              reservado con la finalidad de concretar la compra con el
              Concesionario elegido.
            </p>
            <p className="legal-texto">
              Vencido el plazo antes indicado, sin que el Cliente haya
              materializado la compra del vehículo, éste perderá
              irrevocablemente su derecho a reserva, revirtiéndose el vehículo
              automáticamente al stock disponible.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                1.4. Anulación de la Reserva
              </span>
            </p>
            <p className="legal-texto">
              La Reserva quedará anulada por el cumplimiento del plazo de
              caducidad señalado en el numeral anterior o bien por solicitud
              expresa del Cliente.
            </p>
            <p className="legal-texto">
              <ol type="a">
                <li>
                  Anulación por cumplimiento del plazo de caducidad{" "}
                  <p>
                    En el caso que se cumpla el plazo de caducidad de la Reserva
                    sin que se concrete la compra, se levantará automáticamente
                    el bloqueo de la unidad, quedando desde dicho momento
                    disponible para que otros Clientes puedan reservar el
                    vehículo en cuestión.
                  </p>
                </li>
                <li>
                  Anulación Reserva por solicitud del Cliente{" "}
                  <p>
                    El Cliente podrá anular su Reserva antes del cumplimiento
                    del plazo de caducidad (7 días hábiles). Para este efecto,
                    deberá coordinar directamente con el Concesionario elegido
                    solicitándole la anulación de la Reserva.
                  </p>
                  <p>
                    Tanto para los literales a) y b) anteriores, el reembolso
                    del dinero es por el importe total cancelado por el Cliente
                    y aplica para pagos con tarjetas de crédito o débito (no
                    cubre intereses ni comisiones asociadas a la operación) y se
                    realizará bajo el mismo mecanismo de pago por el cual fue
                    efectuado y dependerá de los plazos aplicables que maneja
                    cada entidad bancaria. Para los casos donde el Cliente
                    hubiese pagado a través de pago efectivo, el Concesionario
                    se comunicará con el Cliente a través del correo electrónico
                    o teléfono proporcionado por éste al momento del registro, a
                    fin de que le brinde una cuenta de ahorros donde pueda
                    realizar la devolución mediante la transferencia del total
                    del monto cancelado por el Cliente (no cubre intereses ni
                    comisiones asociadas a la operación), sin este dato el
                    Concesionario no podrá proceder con la devolución. El
                    trámite de devolución y los plazos deberán ser previamente
                    coordinados con el asesor de ventas del Concesionario
                    elegido.
                  </p>
                </li>
              </ol>
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            2. CONDICIONES PARA LA RESERVA DE VEHÍCULOS
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                2.1. Disposiciones generales
              </span>
            </p>
            <p className="legal-texto">
              Los precios publicados en el Sitio Web sólo tendrán vigencia y
              aplicación en éste y no serán aplicables a otros canales de venta
              utilizados por los Concesionarios y/o Hyundai Colombia. Asimismo,
              dichos precios no incluyen costos asociados a fletes a provincias,
              trámite de placas, tarjeta de propiedad y/o equipamiento
              adicional. Dichos precios serán proporcionados fuera del Sitio Web
              directamente por el Concesionario.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                2.2. Resolución de la compraventa
              </span>
            </p>
            <p className="legal-texto">
              El Cliente no podrá ponerle término unilateralmente a la
              compraventa del vehículo, salvo la restitución del monto
              correspondiente a la Reserva, lo cual aplicará siempre y cuando no
              se haya concretado la compraventa del vehículo, conforme a lo
              indicado en el numeral 1.4 precedente.
            </p>
            <p className="legal-texto">
              Lo anterior, sin perjuicio de los mecanismos de cambio y
              devolución de productos cuando procediere, de conformidad a la
              ley.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                2.3. Relación Hyundai Colombia S.A.S.-Concesionarios
              </span>
            </p>
            <p className="legal-texto">
              Se deja constancia que las relaciones entre Hyundai Colombia y los
              Concesionarios son de naturaleza estrictamente comercial, por lo
              tanto, no representa, ni representará, en caso alguno, la
              existencia de una relación laboral, ni de vínculo de subordinación
              o dependencia de ninguna especie que pudiere derivar en una
              relación laboral entre Hyundai Colombia y los funcionarios de los
              Concesionarios. De tal manera, le serán absolutamente inoponibles
              a Hyundai Colombia cualquier obligación o compromiso legal o
              convencional que los Concesionarios acuerden con los Clientes
              fuera del Sitio Web. El pleno respeto a las normas vigentes
              relativas a la libre competencia es un requisito esencial para la
              relación de Hyundai Colombia con su red de Concesionarios. En este
              sentido, Hyundai Colombia rige sus operaciones de conformidad a su
              propio criterio comercial y de manera unilateral. Del mismo modo
              los Concesionarios ejecutan su actuación comercial de conformidad
              a sus propias políticas comerciales, evitando incurrir en
              cualquier tipo de práctica anticompetitiva.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">2.4. Datos personales</span>
            </p>
            <p className="legal-texto">
              Los datos personales del Cliente serán tratados conforme a lo
              indicado en la “Política de Tratamiento de Datos Personales -
              Hyundai Colombia S.A.S.” que se encuentra en el siguiente link:
              <a href="https://www.hyundaicolombia.co/politica-proteccion-datos/">
                https://www.hyundaicolombia.co/politica-proteccion-datos/
              </a>
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">
                2.5. Responsabilidad por el funcionamiento del Sitio Web
              </span>
            </p>
            <p className="legal-texto">
              Hyundai Colombia y los Concesionarios no son responsables por
              comunicaciones electrónicas fallidas, incorrectas, incompletas,
              inexactas, confusas o retrasadas, sean causadas por el Cliente o
              por cualquiera de los equipos o programas asociados con o
              utilizados en el Sitio Web, o por cualquier error humano que
              pudiera ocurrir en el procesamiento de Reserva, o por alguna
              responsabilidad de daño a cualquier equipo informático o
              dispositivo que resulte de participar, acceder o descargar
              información relacionada con el Sitio Web, registros tardíos,
              extraviados, incompletos, dañados, o con datos erróneos;
              conexiones de red, servidores u otras pérdidas, interrumpidas o no
              disponibles; o por problemas de comunicaciones o mal
              funcionamiento de sistemas y software, problemas de transmisión
              telefónica, fallas técnicas, transmisiones confusas, daño a
              equipos de sistemas (software o hardware), u otros errores o
              fallas de cualquier clase, sean humanas, mecánicas, electrónicas o
              de otro modo, caso fortuito o fuerza mayor.
            </p>
            <p className="legal-texto">
              <span className="legal-bold mb-1">2.6 Clave secreta</span>
            </p>
            <p className="legal-texto">
              El Cliente dispondrá, una vez registrado, de un usuario y clave
              individualizada que le permitirá el acceso personalizado,
              confidencial y seguro al Sitio Web. El Cliente tendrá la
              posibilidad de cambiar la clave, para lo cual deberá sujetarse al
              procedimiento establecido en el Sitio Web. El Cliente asume
              totalmente la responsabilidad por el mantenimiento de la
              confidencialidad de su clave registrada en el Sitio Web, la cual
              le permite efectuar Reservas y consultas sobre el estado de ésta.
              Dicha clave es de uso personal y su entrega a terceros, no
              involucra responsabilidad de Hyundai Colombia.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>3. DERECHOS DE AUTOR</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Toda la información de este Sitio Web es propiedad exclusiva de
              Hyundai Colombia. Ninguna parte de este Sitio Web puede ser
              copiada y / o reproducida de ninguna forma. Queda prohibida la
              cesión, distribución, transmisión, almacenamiento, o comunicación
              total o parcial del contenido de las páginas del Sitio Web, salvo
              autorización expresa y escrita de Hyundai Colombia.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>4. LEGISLACIÓN APLICABLE</Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Los términos y condiciones que rigen este Sitio Web y todas las
              relaciones que pudieran derivarse se encuentran salvaguardados por
              la Ley de la República de Colombia.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            5. VIGENCIA DE LOS TÉRMINOS Y CONDICIONES
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              Este documento rige en todas las Reservas y Compras realizadas en
              el Sitio Web y podrá ser modificado o sustituido por ésta las
              veces que sea necesario a discreción de esta, siendo necesario
              publicar su última versión en dicho Sitio.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            6. ATENCIÓN DE PETICIONES, QUEJAS Y RECLAMOS
          </Accordion.Header>
          <Accordion.Body>
            <p className="legal-texto">
              El titular de los datos o quien ejerza su representación podrá
              enviar de forma física y/o electrónica sus peticiones, quejas o
              reclamos a la dirección Avenida Carrera 45 - 108 - 27 Torre 3 Piso
              12, Bogotá D.C., Colombia, correo electrónico{" "}
              <a href="mailto:scliente.hyundai.co@astara.com">
                scliente.hyundai.co@astara.com.
              </a>{" "}
              Línea Servicio al Cliente: 01 8000 931 030. Chat Bot: +57 1 519 00
              55 Hyundai - opción 3
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AccordionComponent;
