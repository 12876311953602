import React from "react";
import Accordion from "react-bootstrap/Accordion";
/*eslint-disable */

interface CampaniasComponentProps {
  active_campaigns: any;
}
declare const window: any;
const CampaniasComponent: React.FC<CampaniasComponentProps> = ({
  active_campaigns,
}) => {
  return (
    <div className="accordion-posventa-campania-component">
      <div className="title-posventa-campania-component">Campañas activas</div>
      <Accordion defaultActiveKey="0">
        {active_campaigns?.map((item: any, index: number) => (
          <Accordion.Item eventKey={`${index}`}>
            <Accordion.Header>{item?.title}</Accordion.Header>
            <Accordion.Body>
              <div
                dangerouslySetInnerHTML={{ __html: item?.description }}
              ></div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default CampaniasComponent;
