import React from "react";
/*eslint-disable */
import v1 from "../../../assets/webp/hyunda-hover-TUCSON.webp";
import v2 from "../../../assets/webp/hyunda-hover-VENUE.webp";
import v3 from "../../../assets/webp/hyundai-hover-GRAND-METRO-TAXI.webp";
import sello from "../../../assets/webp/garantia_hyundai_automoviles_gasolina_hibridos_15235f6024.png";


interface GarantiaComponentProps {
  warranty: any;
}
declare const window: any;
const GarantiaComponent: React.FC<GarantiaComponentProps> = ({ warranty }) => {
  return (
    <div className="garantia-posventa-garantia-container">
      <div className="title-garantia-posventa-garantia-container">Garantía</div>
      <div className="cars-garantia-posventa-garantia-container">
        {warranty?.map((item: any) => (
          <div className="car">
            <img className="vehicle" src={item?.media?.url} alt="" />
            <div className="title">{item?.title}</div>
            <img className="sello" src={item?.icon?.url} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GarantiaComponent;
