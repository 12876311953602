import axios from "axios";

const URL = "https://hyundaicloud.umbraint.com/api/";

export const PosventaAll = async () => {
  return await axios.get(
    URL +
      "post-sales?populate[hero_banner][populate]=*&populate[services][populate]=*&populate[opportunity1][populate]=*&populate[opportunity2][populate]=*&populate[opportunity3][populate]=*&populate[warranty][populate]=*&populate[warranty_files][populate]=*&populate[active_campaigns][populate]=*&populate[assistances][populate]=*&populate[contact][populate]=*&populate[clauses][populate]=*&populate[maintanance][populate]=*&populate[genuine_parts][populate][carousel][populate]=media"
  );
};
