import React from "react";

/*eslint-disable */

declare const window: any;
const DescriptionComponent: React.FC = () => {
  return (
    <div className="container w-80">
      <div className="row">
        <div className="col-md-12">
          <p className="legal-texto">
            Declaro que he sido informado por Hyundai Colombia SAS actuando en
            representación de las fábricas, filiales y casa matriz relacionadas
            con sus marcas y productos (en adelante las Compañías) que será el
            Responsable del Tratamiento de mis Datos Personales, por lo cual
            autorizo de manera previa, libre, voluntaria, informada inequívoca y
            expresa a Hyundai Colombia SAS o quien represente sus derechos,
            también a los concesionarios de su red en Colombia a tratar,
            recolectar, almacenar, usar, circular, suprimir, procesar,
            transferir y/o trasmitir mis datos personales conforme a la política
            de tratamiento de datos Personales descritas en el siguiente link:
            <a
              href="https://www.hyundaicolombia.co/legales/politicas-privacidad-de-datos.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hyundaicolombia.co/legales/politicas-privacidad-de-datos.pdf
            </a>
          </p>
          <p className="legal-texto mt-3">
            Con esta aceptación, autorizo contactarme por cualquier medio para
            el envío de publicidad y de información relacionada con cualquiera
            de las marcas del sector automotriz, invitación a eventos, envío de
            información de productos, servicios, postventa, descuentos,
            relacionados con vehículos automotores y en general con el sector
            automotriz, así como para todo lo relacionado con comunidades
            creadas alrededor de las marcas , para todo lo anterior podrán
            contactarme por cualquier medio (entre otros SMS, WhatsApp, mail).
            Mis datos personales podrán ser transferidos o transmitidos a
            terceros tanto a nivel nacional como internacional.
          </p>
          <p className="legal-texto mt-3">
            Igualmente, queda autorizada la grabación de imágenes o cualquier
            otro registro que sirvan de soporte y evidencia de los eventos
            realizados.
          </p>
          <p className="legal-texto mt-3">
            Como titular de la información soy consciente que tengo el derecho a
            conocer, actualizar y rectificar mis datos personales, ser informado
            sobre el uso que se ha dado a los mismos, presentar quejas ante la
            SIC por infracción a la ley, solicitar prueba de la autorización
            otorgada, revocar la autorización y/o solicitar la supresión de mis
            datos cuando sea procedente y acceder en forma gratuita a los mismos
            por medio de una solicitud dirigida a:
            <a
              href="mailto:habeasdata.co@astara.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              habeasdata.co@astara.com
            </a>
          </p>
          <p className="legal-texto">
            Hyundai Colombia S.A.S., sociedad encargada del comercio de
            automotores, partes, piezas y accesorios para vehículos automotores;
            en desarrollo de su actividad comercial, recoge, almacena y utiliza
            información personal de sus clientes, proveedores, empleados,
            visitantes y personas naturales que podrían llegar a tener una
            relación contractual o legal con la entidad.
          </p>
          <p className="legal-texto">
            Hyundai Colombia S.A.S es consciente de las implicaciones del
            Tratamiento de Datos Personales, por ello efectúa dicha actividad
            conforme los principios y directrices contenidas en el Estatuto para
            la de Protección de Datos Personales - Ley 1581 de 2012, los
            Decretos que lo reglamentan y la guía para la implementación del
            principio de responsabilidad demostrada de la Superintendencia de
            Industria y Comercio.
          </p>
          <p className="legal-texto">
            Con miras a proteger y asegurar a los titulares de datos personales
            un debido tratamiento de su información, Hyundai Colombia S.A.S
            expide la presente Política de Tratamiento de Datos Personales en
            cumplimiento de la normativa pertinente y los principios de
            legalidad, finalidad, libertad, veracidad o calidad, acceso y
            circulación restringida, seguridad y confidencialidad de que trata
            la Ley Estatutaria 1581 de 2012.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">I. OBJETO.</h3>
          <p className="legal-texto">
            La política de Tratamiento de Datos Personales de Hyundai Colombia
            S.A.S busca garantizar el derecho al Hábeas Data de las personas
            naturales que entregaron sus datos personales a la entidad para el
            debido tratamiento recolectar, almacenar, usar, circular, registrar,
            administrar, procesar, evaluar, confirmar, suprimir y actualizar la
            información) conforme a la finalidad informada en la autorización
            dada.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            II. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO DE DATOS
            PERSONALES
          </h3>
          <p className="legal-texto">
            Hyundai Colombia S.A.S como responsable del tratamiento de datos
            personales, es una sociedad que se constituyó por Documento Privado
            del 7 de mayo de 2021 de Accionista Único, inscrito en esta Cámara
            de Comercio el 1 de junio de 2021, con el No. 02711263 del Libro IX,
            entidad identificada con Nit. 901.489.734-5 y Matrícula Mercantil
            03383459 del 1 de junio del año 2021.
          </p>
          <p className="legal-texto">
            Hyundai Colombia S.A.S asignará dentro de su estructura un Oficial
            de Protección de Datos Personales según disposición de la Guía para
            la Implementación del principio de Responsabilidad Demostrada de la
            Superintendencia de Industria y Comercio. El Oficial de Protección
            de Datos Personales, asume la función de proteger los datos
            personales dentro de la compañía, en cumplimiento de la normativa
            que rige la materia, así como de las políticas establecidas para tal
            fin dentro de Hyundai Colombia S.A.S
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            III. ALCANCE DE LA POLÍTICA DE TRATAMIENTO DE INFORMACIÓN
          </h3>
          <p className="legal-texto">
            De acuerdo con la Ley 1581 de 2012 se entiende por titulares de
            datos personales, la “Persona natural cuyos datos personales sean
            objeto de Tratamiento”, por lo anterior la política de Hyundai
            Colombia S.A.S se centra a datos personales provenientes de personas
            naturales.
          </p>
          <p className="legal-texto">
            La cobertura de estas políticas cobija a todos los empleados,
            proveedores de Hyundai Colombia S.A.S, así como a los potenciales
            vinculados con quienes la entidad mantiene comunicación.
          </p>
          <p className="legal-texto">
            Dentro de la compañía NO hay tratamiento (recolección,
            almacenamiento, uso, circulación o supresión) de datos sensibles
            (entendiendo datos sensibles como aquellos que afectan la intimidad
            del Titular o cuyo uso indebido puede generar su discriminación
            tales como; el origen racial o étnico, la orientación política, las
            convicciones religiosas, entre otros) de los titulares de los datos
            personales.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            IV. DATOS PERSONALES SUSCEPTIBLES DE TRATAMIENTO Y FINALIDAD DE LOS
            DATOS.
          </h3>
          <p className="legal-texto">
            Hyundai Colombia S.A.S trata los datos personales señalados a
            continuación según la base de datos y según las finalidades
            señaladas a continuación:
          </p>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th></th>
                <th>DATOS PERSONALES</th>
                <th>FINALIDAD</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>CLIENTES</strong>
                </td>
                <td>
                  <ul>
                    <li>Nombres y apellidos.</li>
                    <li>Tipo y número de identificación.</li>
                    <li>Nacionalidad y país de residencia.</li>
                    <li>Fecha de nacimiento y género.</li>
                    <li>Estado civil.</li>
                    <li>Información familiar general.</li>
                    <li>
                      Teléfonos fijos y celulares de contacto (personales y/o
                      laborales).
                    </li>
                    <li>
                      Direcciones postales y electrónicas (personales y/o
                      laborales).
                    </li>
                    <li>IP del cliente, a través de cookies.</li>
                    <li>Profesión u oficio.</li>
                    <li>Compañía en la que labora y cargo.</li>
                    <li>Vehículo de interés y/o comprado</li>
                  </ul>
                </td>
                <td>
                  <ol type="a">
                    <li>
                      Anunciar programas o campañas de fidelización con
                      beneficios.
                    </li>
                    <li>
                      Comunicar en caso de campañas técnicas de servicio,
                      seguridad o re-call que sean establecidas por los
                      fabricantes de las marcas representadas por nosotros y que
                      cobijen algún producto adquirido.
                    </li>
                    <li>
                      Contactar con miras a establecer futuras relaciones
                      comerciales y envío de publicidad.
                    </li>
                    <li>
                      Contactar para campañas de productos y servicios de las
                      siguientes marcas Hyundai.
                    </li>
                    <li>
                      Cumplir con la normatividad vigente en Colombia para las
                      empresas comercializadoras de vehículos sin limitarse a
                      cualquier requerimiento de las instituciones o entidades
                      gubernamentales.
                    </li>
                    <li>
                      Enviar información sobreproductos y el nivel de
                      satisfacción como clientes.
                    </li>
                    <li>
                      Envío de información sobre novedades en productos de la
                      compañía y/o de la industria.
                    </li>
                    <li>Envío de invitaciones a eventos de marca</li>
                    <li>
                      Evaluar la calidad de nuestros productos y el nivel de
                      satisfacción como clientes, así como la actualización de
                      datos de manera periódica.
                    </li>
                    <li>Evaluar el estatus del proceso de compra.</li>
                    <li>
                      Informar sobre campañas publicitarias, informativas o
                      promocionales.
                    </li>
                    <li>
                      Notificar al cliente estatus de servicios solicitados en
                      venta y postventa.
                    </li>
                    <li>
                      Llevar a cabo directamente o a través de su casa matriz,
                      filiales o subsidiarias, empresas terceras, aliados de
                      negocios, actividades de promoción, mercadeo o publicidad.
                    </li>
                    <li>
                      Mantener en contacto a los clientes con el área de
                      servicio técnico y repuestos de la compañía para facilitar
                      el acceso a los productos y servicios ofrecidos por
                      Hyundai Colombia SAS y sus filiales.
                    </li>
                    <li>
                      Realizar cotizaciones y su envío sobre productos de
                      interés.
                    </li>
                    <li>
                      Realizar estudios de mercado en tendencias y hábitos de
                      consumo
                    </li>
                    <li>
                      Todas aquellas actividades que resulten acordes con el
                      objeto de la sociedad y que sean idóneas para ejecutar
                      actividades de mercadeo y publicidad.
                    </li>
                    <li>
                      Transmitir y/o transferir los datos personales a su casa
                      matriz, filiales o subsidiarias o terceros aliados
                      vinculados mediante relaciones comerciales con la compañía
                      a nivel nacional o internacional.
                    </li>
                    <li>
                      Consultar los datos de los titulares contenidos en listas
                      restrictivas y demás bases de datos públicas, para
                      prevenir, detectar, monitorear, controlar y administrar el
                      riesgo de lavado de activos, financiación del terrorismo y
                      de la proliferación de armas de destrucción masiva.
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>PROVEEDORES</strong>
                </td>
                <td>
                  <ul>
                    <li>Nombres y apellidos.</li>
                    <li>Tipo y número de identificación.</li>
                    <li>Nacionalidad y país de residencia.</li>
                    <li>
                      Teléfonos fijos y celulares de contacto (personales y/o
                      laborales).
                    </li>
                    <li>
                      Direcciones postales y electrónicas (personales y/o
                      laborales).
                    </li>
                    <li>Huellas, Firmas, firmas electrónicas.</li>
                    <li>Datos económicos.</li>
                    <li>Compañía en la que labora y cargo.</li>
                  </ul>
                </td>
                <td>
                  <ol type="a">
                    <li>
                      Consultar y reportar ante las centrales de riesgo
                      información correspondiente a antecedentes de tipo legal y
                      financiero.
                    </li>
                    <li>
                      Gestionar y utilizar el registro de proveedores y
                      cualquier otro que se requiera para inscribir al tercero
                      interesado como proveedor de la compañía.
                    </li>
                    <li>
                      Tramitar la generación de extractos, la realización de
                      actividades de cobranza, las órdenes de autoridades
                      judiciales o administrativas.
                    </li>
                    <li>
                      Creación y conservación de los documentos legalmente
                      exigidos por las normas contables.
                    </li>
                    <li>
                      Surtir y tramitar cualquier registro institucional como el
                      registro nacional de bases de datos administrado por la
                      Superintendencia de Industria y Comercio.
                    </li>
                    <li>
                      Enviar cartas, revistas y comunicaciones en general.
                    </li>
                    <li>Gestión de peticiones, quejas y reclamos.</li>
                    <li>
                      Consultar los datos de los titulares contenidos en listas
                      restrictivas y demás bases de datos públicas, para
                      prevenir, detectar, monitorear, controlar y administrar el
                      riesgo de lavado de activos, financiación del terrorismo y
                      de la proliferación de armas de destrucción masiva.
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>EMPLEADOS</strong>
                </td>
                <td>
                  <ul>
                    <li>Nombres y apellidos.</li>
                    <li>Tipo y número de identificación.</li>
                    <li>Nacionalidad y país de residencia.</li>
                    <li>Fecha de nacimiento y género.</li>
                    <li>Estado civil.</li>
                    <li>Información familiar general.</li>
                    <li>
                      Teléfonos fijos y celulares de contacto (personales y/o
                      laborales).
                    </li>
                    <li>
                      Direcciones postales y electrónicas (personales y/o
                      laborales).
                    </li>
                    <li>Profesión u oficio.</li>
                    <li>Huellas, Firmas, firmas electrónicas.</li>
                    <li>Datos económicos.</li>
                    <li>
                      Datos generales relacionados con afiliación y aportes al
                      sistema integral de seguridad social
                    </li>
                    <li>Fotografías y Videos</li>
                    <li>Compañía en la que labora y cargo.</li>
                  </ul>
                </td>
                <td>
                  <ol type="a">
                    <li>
                      Tramitar la generación de extractos, la realización de
                      actividades de cobranza, las órdenes de autoridades
                      judiciales o administrativas.
                    </li>
                    <li>
                      Informar sobre cursos, programas y capacitaciones de la
                      compañía relacionados con gestión y/o de la industria.
                    </li>
                    <li>
                      Contactarlo, enviar publicidad y contenido relacionado
                      actividades de mercadeo.
                    </li>
                    <li>
                      Mejorar nuestro conocimiento del mercado en el uso de
                      aplicaciones tecnológicas.
                    </li>
                    <li>
                      Enviar cartas, revistas y comunicaciones en general de la
                      compañía.
                    </li>
                    <li>
                      Utilizar fotos e imágenes en páginas web, redes sociales,
                      prensa y digital.
                    </li>
                    <li>
                      Manejo de la información referente a procedimientos que
                      requiera el área de Recursos Humanos.
                    </li>
                    <li>
                      Actualización de datos de todo el personal de planta del
                      grupo SKBergé como requerimiento de OEA.
                    </li>
                    <li>
                      Información general como beneficios para los empleados:
                      afiliaciones seguro de vida, exequias y Emermédica.
                    </li>
                    <li>
                      Consultar los datos de los titulares contenidos en listas
                      restrictivas y demás bases de datos públicas, para
                      prevenir, detectar, monitorear, controlar y administrar el
                      riesgo de lavado de activos, financiación del terrorismo y
                      de la proliferación de armas de destrucción masiva.
                    </li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>VISITANTES</strong>
                </td>
                <td>
                  <ul>
                    <li>Nombres y apellidos.</li>
                    <li>Tipo y número de identificación.</li>
                    <li>
                      Teléfonos fijos y celulares de contacto (personales y/o
                      laborales y/o terceros allegados).
                    </li>
                    <li>Firma</li>
                    <li>
                      Datos generales relacionados con afiliación al sistema
                      integral de seguridad social
                    </li>
                  </ul>
                </td>
                <td>
                  <ol type="a">
                    <li>
                      Contactar con terceros allegados a usted en caso de
                      accidentes, enfermedades o evento desastroso.
                    </li>
                    <li>
                      Contactarlo en caso de pérdida de documento de visitante
                      (tarjeta de proximidad brindada por el edificio) para la
                      respectiva devolución.
                    </li>
                    <li>
                      Dar cumplimiento a los estándares de seguridad internos de
                      la compañía en caso de hurto, robo, atentado.
                    </li>
                    <li>
                      Consultar los datos de los titulares contenidos en listas
                      restrictivas y demás bases de datos públicas, para
                      prevenir, detectar, monitorear, controlar y administrar el
                      riesgo de lavado de activos, financiación del terrorismo y
                      de la proliferación de armas de destrucción masiva.
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            V. DERECHOS DE LOS TITULARES DE DATOS PERSONALES
          </h3>
          <p className="legal-texto">
            Hyundai Colombia S.A.S, les informa a los titulares del tratamiento
            de datos personales que los derechos que pueden ejercer de acuerdo
            con la Ley 1581 de 2012, son los siguientes:
          </p>
          <p className="legal-texto">
            <ol>
              <li>
                Conocer, actualizar y rectificar sus datos personales frente a
                los responsables del Tratamiento o Encargados del Tratamiento.
                Este derecho se podrá ejercer, frente a datos parciales,
                inexactos, incompletos, fraccionados, que induzcan a error, o
                aquellos cuyo Tratamiento esté expresamente prohibido o no haya
                sido autorizado.
              </li>
              <li>
                Solicitar prueba de la autorización otorgada al responsable del
                Tratamiento salvo cuando expresamente se exceptúe como requisito
                para el Tratamiento, de conformidad con lo previsto en el
                artículo 10 de la Ley 1581 de 2012.
              </li>
              <li>
                Ser informado por el responsable del Tratamiento o el Encargado
                del Tratamiento, previa solicitud, respecto del uso que le ha
                dado a los datos personales del Titular.
              </li>
              <li>
                Presentar ante la Superintendencia de Industria y Comercio
                quejas por infracciones a lo dispuesto en la presente ley y las
                demás normas que la modifiquen, adicionen o complementen.
              </li>
              <li>
                Revocar la autorización y/o solicitar la supresión del dato
                cuando en el Tratamiento no se respeten los principios, derechos
                y garantías constitucionales y legales. <br />
                La revocatoria y/o supresión procederá cuando la
                Superintendencia de Industria y Comercio haya determinado que en
                el Tratamiento el responsable o Encargado han incurrido en
                conductas contrarias a esta ley y a la Constitución.
              </li>
              <li>
                Acceder en forma gratuita a sus datos personales que hayan sido
                objeto de Tratamiento.
              </li>
            </ol>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            VI. ÁREA RESPONSABLE DE LA ATENCIÓN DE CONSULTAS Y RECLAMOS
          </h3>
          <p className="legal-texto">
            En Hyundai Colombia S.A.S el área responsable de la atención de
            peticiones, consultas y reclamos será el Oficial de Protección de
            Datos por intermedio de los siguientes canales de atención al
            titular del dato personal:
          </p>
          <p className="legal-texto">
            <h4 className="legal-bold">
              <u>
                <strong>1. ATENCIÓN VÍA CORREO ELECTRÓNICO</strong>
              </u>
            </h4>
            La atención por página web será atendida mediante la siguiente
            dirección scliente.hyundai.co@astara.com según los parámetros
            establecidos en el capítulo siguiente.
          </p>
          <p className="legal-texto">
            <h4 className="legal-bold">
              <u>
                <strong>2. ATENCIÓN VÍA CHATBOT</strong>
              </u>
            </h4>
            La atención es por via Whatsapp en la línea telefónica +57 1 519 00
            55 Hyundai - opción 3
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            VII. PROCEDIMIENTO DE CONSULTA
          </h3>
          <p className="legal-texto">
            Los titulares de datos personales o sus causahabientes podrán
            solicitar la consulta de sus datos personales a los canales
            descritos en el capítulo anterior.
          </p>
          <p className="legal-texto">
            Las consultas por correo electrónico deberán contener como mínimo:
            i) la identificación completa del titular, ii) los datos personales
            que quieren ser consultados, ii) dirección, iii) correo electrónico,
            y; iv) en caso de ser causahabientes anexar el respectivo documento
            que lo demuestre. La consulta será atendida por Hyundai Colombia
            S.A.S, en un término máximo de diez (10) días hábiles contados a
            partir de la fecha de recibo del correo electrónico o del documento
            físico. Cuando no fuere posible atender la consulta dentro de dicho
            término Hyundai Colombia S.A.S Informará al interesado expresando
            los motivos de la demora y señalará la fecha en que se atenderá su
            solicitud en un tiempo máximo de 5 días hábiles siguientes al
            vencimiento del primer término.
          </p>
          <p className="legal-texto">
            Cuando la consulta no sea clara, no se entienda, o no cumpla los
            requisitos necesarios para desarrollar una respuesta por parte de
            Hyundai Colombia S.A.S, ésta le informará al titular o al
            causahabiente para que nuevamente presente la consulta a más tardar
            dentro de los cinco (5) días hábiles siguientes al requerimiento por
            parte de la entidad. Transcurrido dos (2) meses desde la fecha del
            requerimiento, sin que el solicitante presente la información
            requerida, Hyundai Colombia S.A.S entenderá, que el titular o
            causahabiente ha desistido de la consulta.
          </p>
          <p className="legal-texto">
            En caso de que quien reciba el reclamo no sea competente para
            resolverlo, dará traslado a quien corresponda en un término máximo
            de 2 días hábiles e informará de la situación al interesado.
          </p>
          <p className="legal-texto">
            Así entonces el término máximo para atender la consulta será de 15
            días hábiles, siempre y cuando dentro de los 10 días hábiles
            siguientes a la recepción de la consulta por cualquier medio
            dispuesto para ello se le informe al titular el motivo de la demora,
            de lo contrario el término máximo será de 10 días hábiles.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            VIII. PROCEDIMIENTO DE RECLAMO
          </h3>
          <p className="legal-texto">
            Cuando el titular o causahabiente considere que la información
            contenida en nuestra base de datos deba ser objeto de corrección,
            autorización, supresión o revocación de autorización, podrá
            presentar reclamo a los canales de atención dispuesto para tal fin
            en el capítulo anterior.
          </p>
          <p className="legal-texto">
            Este reclamo deberá contener como mínimo: i) la identificación
            completa del titular, ii) descripción de los hechos que dan lugar al
            reclamo, iii) dirección del domicilio, iv) correo electrónico, y; v)
            acompañamiento de los documentos que el titular o causahabiente
            quiere hacer valer.
          </p>
          <p className="legal-texto">
            Si el reclamo resulta incompleto Hyundai Colombia S.A.S requerirá al
            interesado dentro de los cinco (5) días hábiles siguientes a la
            recepción del reclamo para que subsane las fallas. Transcurrido dos
            (2) meses desde la fecha del requerimiento, sin que el solicitante
            presente la información requerida, Hyundai Colombia S.A.S entenderá,
            que el titular o causahabiente han desistido del reclamo.
          </p>
          <p className="legal-texto">
            Hyundai Colombia S.A.S, dará respuesta al reclamo en un término
            máximo de quince (15) días hábiles contados a partir del día
            siguiente a la fecha de su recibo. Cuando no fuere posible atender
            el reclamo dentro de dicho término, se informará al interesado los
            motivos de la demora y la fecha en que se atenderá su reclamo sin
            que sobrepase 8 días hábiles siguientes al vencimiento del primer
            término.
          </p>
          <p className="legal-texto">
            <strong>
              SE LE INFORMA A LOS TITULARES DE DATOS PERSONALES QUE LA SUPRESIÓN
              O REVOCATORIA DE AUTORIZACIÓN DE TRATAMIENTO DE DATOS NO
              PROCEDERÁN CUANDO EL TITULAR TENGA UN DEBER LEGAL O CONTRACTUAL DE
              PERMANECER EN LA BASE DE DATOS DE HYUNDAI COLOMBIA S.A.S.
            </strong>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            IX. LIMITACIONES TEMPORALES AL TRATAMIENTO DE LOS DATOS PERSONALES
          </h3>
          <p className="legal-texto">
            De acuerdo al Decreto 1377 de 2013, Hyundai Colombia S.A.S, sólo
            podrá recolectar, almacenar, usar o circular los datos personales de
            una persona, durante el tiempo que sea razonable y necesario, de
            acuerdo con las finalidades que justificaron el tratamiento,
            atendiendo a las disposiciones aplicables a la materia de que se
            trate y a los aspectos administrativos, contables, fiscales,
            jurídicos e históricos de la información. Una vez cumplida la o las
            finalidades del Tratamiento y sin perjuicio de normas legales que
            dispongan lo contrario, Hyundai Colombia S.A.S deberá proceder a la
            supresión de los datos personales en su posesión.
          </p>
          <p className="legal-texto">
            Como excepción a lo anterior Hyundai Colombia S.A.S podrá conservar
            los datos personales cuando así se requiera para el cumplimiento de
            una obligación legal o contractual.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            X. VIGENCIA DE LA POLÍTICA DE TRATAMIENTO DE INFORMACIÓN.
          </h3>
          <p className="legal-texto">
            Esta política de tratamiento desarrollada por la entidad Hyundai
            Colombia S.A.S Inicia su vigencia desde la fecha de su publicación.
          </p>
        </div>
      </div>
      <div className="row"><div className="col-md-12"><a href="https://www.hyundaicolombia.co/legales/politicas-privacidad-de-datos.pdf" target="_blank" rel="noopener noreferrer">Ver documento</a></div></div>
    </div>
  );
};

export default DescriptionComponent;
