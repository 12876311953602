import React from "react";

/*eslint-disable */
interface ComuniqueseComponentProps {
  assistancesContact: any;
}

declare const window: any;
const ComuniqueseComponent: React.FC<ComuniqueseComponentProps> = ({assistancesContact}) => {
  return (
    <div className="comuniquese-posventa-asistencia-component">
      <div className="text1-banner-posventa-asistencia-component">{assistancesContact?.title}</div>
      <div className="text2-banner-posventa-asistencia-component">Comunícate:</div>
      <div className="text3-banner-posventa-asistencia-component">{assistancesContact?.phone1}</div>
      <div className="text4-banner-posventa-asistencia-component">{assistancesContact?.phone2}</div>
    </div>
  );
};

export default ComuniqueseComponent;
