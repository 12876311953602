import { useEffect, useState } from "react";
import icon_whatsapp from "../assets/01_Home/Icon_Whatsapp.svg";
import icon_subir from "../assets/01_Home/Icon_subir.svg";
import icon_close from "../assets/01_Home/Close.svg";

/*eslint-disable */

declare const window: any;

const BtnFloatingLayout = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showMovilBtn, setShowMovilBtn] = useState(false);

  // Mostrar el botón cuando se hace scroll hacia abajo
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const scrollUp = () => {
    console.log("aqui");

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className={`btn-floating-layout ${showMovilBtn ? "active" : ""}`}>
        <div className="reserva-btn-floating-layout">Reserva en línea</div>
        <a
          href="https://api.whatsapp.com/send?phone=573013658534&text=Hola%2C%20quiero%20mas%20información"
          target="_blank"
          className="whatsapp-btn-floating-layout"
        >
          <img src={icon_whatsapp} alt="" />
        </a>
        <div className="up-btn-floating-layout" onClick={() => scrollUp()}>
          <img src={icon_subir} alt="" />
        </div>
      </div>
      <div className={`plus-btn-floating-layout ${showMovilBtn ? "" : "active"}`} onClick={() => {showMovilBtn ? setShowMovilBtn(false) : setShowMovilBtn(true)}}>
        <img src={icon_close} alt="" />
      </div>
    </>
  );
};

export default BtnFloatingLayout;
