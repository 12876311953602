import React from "react";
import icon_seguridad from "../../../assets/10_POSVENTA/01_Servicios exclusivos/Seguridad.svg";
import icon_confianza from "../../../assets/10_POSVENTA/01_Servicios exclusivos/Confianza.svg";
import icon_eficiencia from "../../../assets/10_POSVENTA/01_Servicios exclusivos/Eficiencia.svg";
import icon_comodidad from "../../../assets/10_POSVENTA/01_Servicios exclusivos/Comodidad.svg";
import icon_conveniencia from "../../../assets/10_POSVENTA/01_Servicios exclusivos/Conveniencia.svg";

/*eslint-disable */

declare const window: any;
const PromesaComponent: React.FC = () => {
  return (
    <div
      className="promesa-posventa-servicios-container">
      <div className="title-promesa-posventa-servicios-container">Posventa</div>
      <div className="text-promesa-posventa-servicios-container">Esperamos lo mejor para los propietarios de un Hyundai, es por ello que nuestra red de servicios de posventa se distingue por su excelencia y dedicación, brindándote confianza y tranquilidad.</div>
      <div className="text-promesa-posventa-servicios-container">Elegir nuestros servicios significa contar con técnicos altamente capacitados y piezas genuinas diseñadas específicamente para tu Hyundai. Nos enfocamos en proporcionar una experiencia sin igual, donde cada elección no solo asegura el óptimo desempeño de tu vehículo, sino también tu total satisfacción.</div>
      <div className="opciones-promesa-posventa-servicios-container">
        <div className="master-opcion-promesa-posventa-servicios-container">
          Nuestra promesa
        </div>
        <div className="opcion-promesa-posventa-servicios-container">
          <img src={icon_seguridad} className="icon-opcion-promesa-posventa-servicios-container" />
          <div className="title-opcion-promesa-posventa-servicios-container">Seguridad</div>
          <div className="text-opcion-promesa-posventa-servicios-container">Inspección con técnicos y herramientas especializadas.</div>
        </div>
        <div className="opcion-promesa-posventa-servicios-container">
          <img src={icon_confianza} className="icon-opcion-promesa-posventa-servicios-container" />
          <div className="title-opcion-promesa-posventa-servicios-container">Confianza</div>
          <div className="text-opcion-promesa-posventa-servicios-container">Repuestos Genuinos.</div>
        </div>
        <div className="opcion-promesa-posventa-servicios-container">
          <img src={icon_eficiencia} className="icon-opcion-promesa-posventa-servicios-container" />
          <div className="title-opcion-promesa-posventa-servicios-container">Eficiencia</div>
          <div className="text-opcion-promesa-posventa-servicios-container">Puntualidad y certidumbre de trabajos cotizados.</div>
        </div>
        <div className="opcion-promesa-posventa-servicios-container">
          <img src={icon_comodidad} className="icon-opcion-promesa-posventa-servicios-container" />
          <div className="title-opcion-promesa-posventa-servicios-container">Comodidad</div>
          <div className="text-opcion-promesa-posventa-servicios-container">Recogida y entrega en casa, carro taller.</div>
        </div>
        <div className="opcion-promesa-posventa-servicios-container">
          <img src={icon_conveniencia} className="icon-opcion-promesa-posventa-servicios-container" />
          <div className="title-opcion-promesa-posventa-servicios-container">Conveniencia</div>
          <div className="text-opcion-promesa-posventa-servicios-container">Mantenimiento sin costo.</div>
        </div>
      </div>
    </div>
  );
};

export default PromesaComponent;
