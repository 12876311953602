/*eslint-disable */
import React from 'react';
import * as XLSX from 'xlsx';

type RowData = (string | number)[]; // Tipo para las filas del archivo

interface FileLoaderProps {
  url: string;
  onDataLoaded: (data: RowData[]) => void; // Callback para pasar los datos cargados
}

const FileLoader: React.FC<FileLoaderProps> = ({ url, onDataLoaded }) => {
  // Función para cargar el archivo desde la URL
  const loadFile = async () => {
    try {
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });

      // Acceder a la segunda hoja (index 1)
      const sheet = workbook.Sheets[workbook.SheetNames[1]];
      const data: RowData[] = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      console.log(data)

      onDataLoaded(data); // Llamar al callback con los datos cargados
    } catch (error) {
      console.error('Error al cargar el archivo:', error);
    }
  };

  // Cargar el archivo cuando se monte el componente
  React.useEffect(() => {
    loadFile();
  }, []);

  return null; // Este componente no necesita renderizar nada
};

export default FileLoader;
