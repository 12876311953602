import React from "react";

/*eslint-disable */

declare const window: any;
const DescriptionComponent: React.FC = () => {
  return (
    <div className="container w-80">
      <p className="legal-texto">
        Hyundai Colombia S.A.S., en cumplimiento de la Ley 1581 de 2012 y demás
        normas concordantes, es responsable del tratamiento de sus datos
        personales.
      </p>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo">
            1. Tratamiento y finalidad
          </h3>
          <p className="legal-texto">
            El tratamiento que realizará Hyundai Colombia S.A.S., con la
            información personal será la siguiente:
          </p>
          <p className="legal-texto">
            Recolección, almacenamiento, uso, circulación, supresión,
            intercambio, actualización procesamiento, reproducción, compilación,
            sistematización y organización, todos ellos de forma parcial o total
            en cumplimiento de las finalidades aquí establecidas. La información
            podrá ser entregada, transmitida o transferida a entes de control,
            socios comerciales, contratistas, afiliados, subsidiarias,
            únicamente con el fin de cumplir con las finalidades de los datos e
            información correspondiente. En todo caso, la entrega, transmisión o
            transferencia se hará previa suscripción de los compromisos que sean
            necesarios para salvaguardar la confidencialidad de la información.
          </p>
          <p className="legal-texto">
            La información recolectada por Hyundai Colombia, tiene como
            finalidad permitir el adecuado desarrollo de su objeto social como
            empresa privada. Además, Hyundai Colombia guarda la información
            necesaria para dar cumplimiento a deberes legales, principalmente en
            materia contable, jurídica y laboral. La información sobre clientes,
            proveedores, socios y empleados, actuales o pasados, se guarda con
            el fin de facilitar, promover, permitir o mantener relaciones de
            carácter laboral, civil y comercial.
          </p>
          <p className="legal-texto">
            La información sobre clientes se utiliza con el fin de dar
            cumplimiento a las actividades propias de su objeto social,
            particularmente las relacionadas con contratos, convenios,
            programas, políticas, planes, eventos, lanzamientos de nuevos
            productos, desarrollo e imagen corporativa etc., necesarios para
            promover la comercialización principalmente de vehículos, repuestos
            y accesorios marca Hyundai en Colombia.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            2. Derechos del titular
          </h3>
          <p className="legal-texto">
            El titular de los datos personales tiene derecho a:
          </p>
          <div className="legal-texto">
            <ol type="a">
              <li>
                Acceder en forma gratuita a los datos proporcionados, que hayan
                sido objeto de tratamiento.
              </li>
              <li>
                Conocer, actualizar y rectificar su información frente a datos
                parciales, inexactos, incompletos, fraccionados, que induzcan a
                error, o aquellos cuyo tratamiento este prohibido o no haya sido
                autorizado.
              </li>
              <li>Solicitar prueba de la autorización otorgada.</li>
              <li>
                Presentar ante la Superintendencia de Industria y Comercio (sic)
                quejas por infracciones a lo dispuesto en la normatividad
                vigente.
              </li>
              <li>
                Revocar la autorización y/o solicitar la supresión del dato,
                siempre que no exista un deber legal o contractual que impida
                eliminarlos.
              </li>
              <li>
                Abstenerse de responder las preguntas sobre datos sensibles.
              </li>
            </ol>
          </div>
          <p className="legal-texto">
            Tendrá carácter facultativo las respuestas que versen sobre datos
            sensibles o sobre datos de las niñas, niños y adolescentes.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            3. Mecanismos para conocer la política de tratamiento
          </h3>
          <p className="legal-texto">
            El titular puede acceder a nuestra política de tratamiento de
            información, la cual se encuentra publicada en
            www.hyundaicolombia.co
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            4. Atención de peticiones, quejas y reclamos
          </h3>
          <p className="legal-texto">
            El titular de los datos o quien ejerza su representación podrá
            enviar de forma física y/o electrónica sus peticiones, quejas o
            reclamos a la dirección Avenida Carrera 45 - 108 - 27 Torre 3 Piso
            12, Bogotá D.C., Colombia, correo electrónico
            scliente.hyundai.co@astara.com. Línea Servicio al Cliente: 01 8000
            931 030. Chat Bot: +57 1 519 00 55 Hyundai - opción 3
          </p>
        </div>
      </div>
    </div>
  );
};

export default DescriptionComponent;
