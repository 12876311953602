/*eslint-disable */
import React from "react";
import v1 from "../../../assets/webp/hyunda-hover-TUCSON.webp";
import v3 from "../../../assets/webp/hyundai-hover-GRAND-METRO-TAXI.webp";

declare const window: any;
interface CategoriasComponentProps {
  maintanance: any;
}
const CategoriasComponent: React.FC<CategoriasComponentProps> = ({
  maintanance,
}) => {
  return (
    <div className="categorias-posventa-mantenimiento-container">
      <div className="cars-categorias-posventa-mantenimiento-container">
        {maintanance?.map((item: any) => (
          <div className="car">
            <img className="vehicle" src={item?.media?.url} alt="" />
            <div className="title">{item?.title}</div>
            <div className="subtitle">
              {item?.text1}
            </div>
            <div className="description">{item?.text2}</div>
            <a href={item?.url_button} className="btnn">{item?.button_text}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriasComponent;
