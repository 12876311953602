import React from "react";
import { Link } from "react-router-dom";

/*eslint-disable */

declare const window: any;

interface MenuPosventaComponentProps {
  selectedMenu: any
  navigate: any
  setSelectedMenu: any
  visibleHeight: any
  menus: any
}

const MenuPosventaComponent: React.FC<
  MenuPosventaComponentProps
> = ({ selectedMenu, navigate, setSelectedMenu, visibleHeight, menus }) => {
  return (
    <div className="menu-posventa-detail-component" style={{ top: `${visibleHeight}px` }}>
      <div className="content-menu-posventa-detail-component">
        {menus?.servicios_exclusivos ? <Link to="/posventa/servicios-exclusivos" className={`individual-content-menu-posventa-detail-component ${selectedMenu === "diseño" ? "active" : ""}`} onClick={() => { navigate("#diseno"), setSelectedMenu("diseño") }}>
          Servicios exclusivos
        </Link> : null}
        {menus?.garantia ? <Link to="/posventa/garantia" className={`individual-content-menu-posventa-detail-component ${selectedMenu === "versiones" ? "active" : ""}`} onClick={() => { navigate("#versiones"), setSelectedMenu("versiones") }}>
          Garantía
        </Link> : null}
        {menus?.campanias ? <Link to="/posventa/campana" className={`individual-content-menu-posventa-detail-component ${selectedMenu === "campania" ? "active" : ""}`} onClick={() => { navigate("#campania"), setSelectedMenu("campania") }}>
          Campaña de seguridad
        </Link> : null}
        {menus?.asistencia ? <Link to="/posventa/asistencia" className={`individual-content-menu-posventa-detail-component ${selectedMenu === "asistencia" ? "active" : ""}`} onClick={() => { navigate("#asistencia"), setSelectedMenu("asistencia") }}>
          Asistencia
        </Link> : null}
        {menus?.mantenimiento ? <Link to="/posventa/mantenimiento" className={`individual-content-menu-posventa-detail-component ${selectedMenu === "mantenimiento" ? "active" : ""}`} onClick={() => { navigate("#mantenimiento"), setSelectedMenu("mantenimiento") }}>
          Mantenimiento
        </Link> : null}
        {menus?.repuestos_genuinos ? <Link to="/posventa/repuestos-genuinos" className={`individual-content-menu-posventa-detail-component ${selectedMenu === "repuestos" ? "active" : ""}`} onClick={() => { navigate("#repuestos"), setSelectedMenu("repuestos") }}>
          Repuestos genuinos
        </Link> : null}
        <Link to={`/posventa/#cotizacion`} className="cotiza-opciones-menu-posventa-detail-component">
          Agenda Cita Taller
        </Link>
      </div>
    </div>
  );
};

export default MenuPosventaComponent;
