import React from "react";

/*eslint-disable */

declare const window: any;
const TitleComponent: React.FC = () => {
  return (
    <div className="title-politicas-component">Aviso de privacidad</div>
  );
};

export default TitleComponent;
