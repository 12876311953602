import React from "react";

/*eslint-disable */

declare const window: any;
const DescriptionComponent: React.FC = () => {
  return (
    <div className="container w-80">
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo">
            1. Propiedad intelectual
          </h3>
          <p className="legal-texto">
            El sitio pertenece y es operado por Hyundai Colombia SAS (en
            adelante la “Sociedad”), perteneciente a la representación en
            Colombia de la marca Hyundai. La disposición y cada uno de los
            componentes, incluyendo marcas registradas, logotipos y nombres de
            dominio, que aparecen en el sitio de la Sociedad
            www.hyundaicolombia.co (el “Sitio”), están protegidos por la
            legislación vigente en materia de propiedad intelectual, y
            pertenecen a la casa matriz HYUNDAI o sus subsidiarias, o su uso ha
            sido objeto de una autorización por parte de sus titulares. Ningún
            componente del Sitio puede ser copiado, reproducido, modificado,
            editado, descargado, desnaturalizados, transmitido o distribuido en
            forma alguna, por cualquier medio, en todo o en parte, sin el
            consentimiento previo por escrito de la Sociedad. Sólo copiar para
            uso privado está autorizado siempre que su finalidad no sea
            comercial y se realice en su computador personal. La siguiente
            declaración debe aparecer en cualquier copia autorizada de la
            totalidad o parte de los contenidos del Sitio: “COPYRIGHT 2016 -
            Hyundai - Hyundai Colombia SAS - TODOS LOS DERECHOS RESERVADOS. ”
            Cualquier uso autorizado de los elementos que componen o se muestran
            en el Sitio no debe ser desnaturalizado, cambiado o alterado de
            ninguna manera. HYUNDAI o sus filiales se reservan el derecho de
            emprender acciones legales contra cualquier violación de sus
            derechos de propiedad intelectual.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            2. Naturaleza de la información
          </h3>
          <p className="legal-texto">
            El Sitio puede ofrecer opiniones de los expertos consultados en un
            campo en particular en relación con el contenido del Sitio o de
            extractos de artículos de prensa o científicos. Dicha información
            únicamente representa la opinión de sus autores y de ellas son
            únicamente responsables los mismos, y no corresponde necesariamente
            a la opinión de la Sociedad. Tales expertos no son empleados de la
            Sociedad y en principio no reciben ninguna retribución por parte de
            la Sociedad para utilizar sus opiniones. La Sociedad no es
            responsable de la exactitud o integridad de dicha información y
            opiniones. Las opiniones de los expertos reflejan sus propias
            opiniones personales y no deben ser interpretadas como reflejo de la
            opinión de la Sociedad por lo que estas últimas no asumen ningún
            tipo de responsabilidad frente a las mismas.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            3. Enlaces a otros sitios
          </h3>
          <p className="legal-texto">
            La responsabilidad de la Sociedad no se originará por un sitio web
            de terceros al que se pueda acceder a través del Sitio. No tenemos
            ninguna manera de controlar el contenido de dichos sitios de
            terceros que siguen siendo totalmente independientes de la Sociedad.
            Por otra parte, la existencia de un vínculo entre el Sitio y el
            sitio de un tercero no significa en ningún caso que la Sociedad
            aprueba el contenido de este sitio de ninguna manera y en particular
            el uso que pueda hacerse del mismo. Además, usted es responsable de
            tomar las precauciones necesarias para evitar cualquier virus desde
            el Sitio, en particular por uno o más virus informáticos, troyanos o
            cualquier otro “virus”. Los sitios externos pueden incluir enlaces
            de hipertexto hacia el Sitio. Tales enlaces no deben ser creados sin
            el consentimiento previo y expreso de la Sociedad. En todo caso, la
            Sociedad no se hace en ningún caso responsable de la falta de
            disponibilidad de dichos sitios y la Sociedad no investiga, chequea
            o aprueba ni es responsable por el contenido, publicidad, productos
            u otros componentes disponibles en o a través de dichos sitios.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            4. Autorización de Tratamiento de Datos Personales
          </h3>
          <p className="legal-texto">
            Declaro que he sido informado por Hyundai Colombia SAS actuando en
            representación de las fábricas, filiales y casa matriz relacionadas
            con sus marcas y productos (en adelante las Compañías) que será el
            Responsable del Tratamiento de mis Datos Personales, por lo cual
            autorizo de manera previa, libre, voluntaria, informada inequívoca y
            expresa a Hyundai Colombia SAS o quien represente sus derechos,
            también a los concesionarios de su red en Colombia a tratar,
            recolectar, almacenar, usar, circular, suprimir, procesar,
            transferir y/o trasmitir mis datos personales conforme a la política
            de tratamiento de datos Personales descritas en el siguiente link:{" "}
            <a
              href="https://www.hyundaicolombia.co/legales/politicas-privacidad-de-datos.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hyundaicolombia.co/legales/politicas-privacidad-de-datos.pdf
            </a>
          </p>
          <p className="legal-texto mt-3 ">
            Con esta aceptación, autorizo contactarme por cualquier medio para
            el envío de publicidad y de información relacionada con cualquiera
            de las marcas del sector automotriz, invitación a eventos, envío de
            información de productos, servicios, postventa, descuentos,
            relacionados con vehículos automotores y en general con el sector
            automotriz, así como para todo lo relacionado con comunidades
            creadas alrededor de las marcas , para todo lo anterior podrán
            contactarme por cualquier medio (entre otros SMS, WhatsApp, mail).
            Mis datos personales podrán ser transferidos o transmitidos a
            terceros tanto a nivel nacional como internacional.
          </p>
          <p className="legal-texto mt-3 ">
            Igualmente, queda autorizada la grabación de imágenes o cualquier
            otro registro que sirvan de soporte y evidencia de los eventos
            realizados.
          </p>
          <p className="legal-texto mt-3 ">
            Como titular de la información soy consciente que tengo el derecho a
            conocer, actualizar y rectificar mis datos personales, ser informado
            sobre el uso que se ha dado a los mismos, presentar quejas ante la
            SIC por infracción a la ley, solicitar prueba de la autorización
            otorgada, revocar la autorización y/o solicitar la supresión de mis
            datos cuando sea procedente y acceder en forma gratuita a los mismos
            por medio de una solicitud dirigida a:{" "}
            <a
              href="mailto:habeasdata.co@astara.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              habeasdata.co@astara.com
            </a>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            5. Limitaciones de responsabilidad
          </h3>
          <p className="legal-texto">
            La Sociedad se esfuerza al máximo de su capacidad para garantizar
            que la información publicada en el Sitio es correcta y está
            actualizada. Se reserva el derecho de corregir el contenido en
            cualquier momento, sin previo aviso, al igual que estos Términos y
            Condiciones. Sin embargo, la Sociedad no puede garantizar que la
            información disponible en el Sitio es exacta, correcta, actualizada
            o completa. En consecuencia, y con excepción de daños a la propiedad
            como resultado de negligencia grave o de una conducta
            malintencionada (dolo) por parte de la Sociedad, ésta última no será
            responsable por: 1 cualquier inexactitud, error u omisión en cuanto
            a la información disponible en el Sitio; 2 cualquier daño resultado
            de una intrusión fraudulenta de un tercero que resulta en una
            alteración de la información o de los elementos proporcionados en el
            Sitio; 3 de modo más general, por cualquier daño, incluso si la
            Sociedad había sido advertida de la posibilidad de tal daño o
            pérdida, si ello resultó de (i) acceso o imposibilidad de acceder al
            Sitio, (ii) el uso del Sitio, incluyendo cualquier daño o virus que
            pueda infectar su computador o cualquier otro equipo, y/o, (iii) la
            credibilidad dada a cualquier información procedente directa o
            indirectamente del Sitio. Los componentes del Sitio o de cualquier
            otro sitio se proporcionan “tal cual” sin ninguna garantía de ningún
            tipo, ya sea implícita o explícita. La Sociedad no ofrece ninguna
            garantía, implícita o explícita, en relación, entre otros, con su
            valor de mercado o idoneidad para un fin determinado. La Sociedad
            únicamente será responsable en los términos establecidos por la ley.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            6. Disponibilidad del sitio web
          </h3>
          <p className="legal-texto">
            Usted reconoce que (i) es técnicamente imposible proporcionar el
            Sitio libre de cualquier defecto y que la Sociedad no puede
            comprometerse a hacerlo, (ii) que hay fallas que pueden conducir al
            Sitio a estar temporalmente fuera de servicio, y que (iii) el
            funcionamiento del Sitio puede verse afectado por acontecimientos
            y/o asuntos que la Sociedad no controla, como por ejemplo,
            transmisión y enlaces de comunicación entre usted y la Sociedad y de
            la Sociedad con otras redes. La Sociedad y/o sus proveedores pueden,
            en cualquier momento, modificar o interrumpir, temporal o
            permanentemente, la totalidad o parte del Sitio para someterlo a
            mantenimiento y/o mejoras y/o cambios en el Sitio. La Sociedad no se
            hace responsable de cualquier modificación, suspensión o
            interrupción del Sitio.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            7. Información sobre productos y servicios
          </h3>
          <p className="legal-texto">
            La información incluida y publicado en el Sitio puede incluir
            referencias directas o indirectas a productos, programas y servicios
            de la Sociedad que no están anunciados o disponibles en algunos
            países o algunas regiones o que se pueden suministrar con un nombre
            diferente y puede estar sujeto a las regulaciones y condiciones de
            uso que se diferencian en función del país. Dichas referencias no
            implican que la Sociedad tiene la intención de vender esos
            productos, programas o servicios en Colombia. Consulte a la Sociedad
            sobre cualquier información sobre los productos, programas y
            servicios disponibles para usted.
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h3 className="legal-subtitulo mt-3 mt-md-4">
            8. Provisiones Legales
          </h3>
          <p className="legal-texto">
            El Sitio y su contenido se rigen por las leyes de la República de
            Colombia, y cualquier disputa relacionada está sujeta a la
            jurisdicción de los tribunales de la República de Colombia.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DescriptionComponent;
