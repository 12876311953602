import React from "react";
import Accordion from "react-bootstrap/Accordion";
/*eslint-disable */
interface ClausulasComponentProps {
  clauses: any;
}

declare const window: any;
const ClausulasComponent: React.FC<ClausulasComponentProps> = ({ clauses }) => {
  return (
    <div className="accordion-posventa-campania-component">
      <div className="title-posventa-campania-component">CLÁUSULAS</div>
      <Accordion defaultActiveKey="0">
        {clauses?.map((item: any, index: number) => (
          <Accordion.Item eventKey={`${index}`}>
            <Accordion.Header>{item?.title}</Accordion.Header>
            <Accordion.Body>
              <div
                dangerouslySetInnerHTML={{ __html: item?.description }}
              ></div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default ClausulasComponent;
