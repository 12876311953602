import React from "react";
/*eslint-disable */

interface ManualesComponentProps {
  warranty_files: any;
}
declare const window: any;
const ManualesComponent: React.FC<ManualesComponentProps> = ({
  warranty_files,
}) => {
  console.log(warranty_files);
  
  return (
    <div className="manual-posventa-garantia-container">
      <div className="left-manual-posventa-garantia-container">
        <div className="title">Manuales de garantía</div>
      </div>
      <div className="rigth-manual-posventa-garantia-container">
        <div className="description">
          Queremos construir una relación a largo plazo contigo. Con nuestra
          garantía, te ofrecemos toda la tranquilidad que necesitas para
          disfrutar de tu Hyundai sin preocupaciones. Te invitamos a consultar
          los manuales de garantía en formato PDF para conocer las condiciones
          de tu cobertura.
        </div>
        <div className="description">
          ¡Más allá de las distancias recorridas, lo que realmente importa son
          las experiencias vividas!
        </div>
        <div className="title">Manuales disponibles</div>
        {warranty_files?.map((item: any) => (
          <div className="documentos">
            <div className="description">
              {item?.name}
            </div>
            <a href={item?.warranty_file?.url} className="btnn">Ver documento</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManualesComponent;
