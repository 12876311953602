import React from "react";

/*eslint-disable */

declare const window: any;
const DescriptionComponent: React.FC = () => {
  return (
    <div className="container w-80">
      <p className="legal-texto">
        Mediante el firme compromiso con nuestras partes interesadas, importamos
        y comercializamos vehículos y repuestos marca Hyundai, gestionando los
        riesgos, cumpliendo con los requisitos legales y reglamentarios, con un
        equipo humano comprometido y competente para el mejoramiento continuo de
        los procesos y el cumplimiento de los objetivos.
      </p>
    </div>
  );
};

export default DescriptionComponent;
